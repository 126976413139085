import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['control']

  connect() {
    this.setBtnProceed();
  }

  click(ev) {
    if(ev.target.checked == true) {
      ev.target.checked = false;
      $(this.modal).modal("show");
    }
  }

  setBtnProceed() {
    if (!this.modal) return;

    this.modal.querySelector('.btn-ok').addEventListener('click', this.proceed.bind(this))
  }

  proceed() {
    if (this.hasControlTarget)
      this.controlTarget.checked = true;
    $(this.modal).modal("hide");
  }

  elem() {
    return this.scope.element;
  }

  get modal() {
    const elem = this.elem();
    return elem.querySelector("#" + elem.dataset.modal);
  }
}
