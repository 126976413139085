/* globals rocketcyber,jQuery */
const shouldRunDeviceDetails = () => {
  const pathSplit = window.location.pathname.split('/')
  return (pathSplit.length === 3 && pathSplit[1] === 'devices')
}

window.rocketcyber.deviceHeartbeatService.functions.onLoadDeviceDetailsPage = (function () {
  const deviceConnectivity = rocketcyber.enumerators.deviceConnectivity
  const updateDeviceStatus = (device) => {
    jQuery('span#deviceStatus div.loading').remove()
    if (!device) {
      jQuery('span#deviceStatus').append('<div class="indicator unknown"></div>')
      jQuery('span#deviceStatusText').text('Unknown')
      return
    }
    let indicator
    let indicatorText
    if (device.isolated) {
      indicator = 'isolated'
      indicatorText = 'Isolated'
    } else {
      switch (device.connectivity) {
        case deviceConnectivity.OFFLINE:
          indicator = 'offline'
          indicatorText = 'Offline'
          break
        case deviceConnectivity.ONLINE:
          indicator = 'online'
          indicatorText = 'Online'
          break
        default:
          indicator = 'unknown'
      }
    }
    jQuery('span#deviceStatus').append(`<div class="indicator ${indicator}"></div>`);
    jQuery('span#deviceStatusText').text(indicatorText);
    const lastActiveDate = new Date(device.lastConnected);
    jQuery('#lastSeen').html(`<strong>LAST SEEN:</strong> ${lastActiveDate.toLocaleString()}`);
  }

  const deviceDetails = async () => {
    const deviceId = jQuery('[data-device-status-id]').attr('data-device-status-id')
    const url = `${rocketcyber.deviceHeartbeatService.getUrl(document.location.hostname)}/api/devices/search/deviceId`
    const body = {
      'deviceIds': [deviceId]
    }
    const data = await getData(url, body)
    rocketcyber.deviceData = data[0] || null
    updateDeviceStatus(rocketcyber.deviceData)
    console.log('executed device details code')
  }

  const getData = async (url, body) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${rocketcyber.deviceHeartbeatService.getToken(document.location.hostname)}`)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
    const res = await fetch(url, requestOptions)
    return res.json()
  }
  return deviceDetails
})()

document.addEventListener('turbolinks:load', function () {
  if (shouldRunDeviceDetails()) {
    window.rocketcyber.deviceHeartbeatService.functions.onLoadDeviceDetailsPage()
  }
})
