import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["appCard", "detections"]
  static values  = {
    accountId: String,
    api: String,
    apiToken: String,
    refreshInterval: Number
  }

  connect() {
    this.refresh()
  }

  disconnect() {
    clearTimeout(this.refreshTimer)
  }

  refresh() {
    this.showLoader()
    this.getEventsCount().then(data => this.updateCards(data))
    this.refreshTimer = setTimeout(() => { this.refresh() }, this.refreshIntervalValue)
  }

  showLoader() {
    this.element.querySelectorAll(".detection-count").forEach(detectionCount => { detectionCount.classList.add("hidden") })
    this.element.querySelectorAll(".loader").forEach(loader => { loader.classList.remove("hidden") })
  }

  updateCards(data) {
    this.appCardTargets.forEach(appCard => {
      let appId = appCard.dataset.id
      let count = Number(data.events_count[appId]) || 0

      let detectionCount = appCard.querySelector(".detection-count")
      let iconWrapper    = appCard.querySelector(".icon-wrapper")
      let loader         = appCard.querySelector(".loader")

      detectionCount.innerText = count.toLocaleString()
      iconWrapper.classList.remove('no-detections', 'detections')
      if (count > 0) {
        iconWrapper.classList.add('detections')
      } else {
        iconWrapper.classList.add('no-detections')
      }

      loader.classList.add("hidden")
      detectionCount.classList.remove("hidden")
    })
  }

  async getEventsCount() {
    const url = this.apiValue + `?account_id=${this.accountIdValue}`
    const response = await fetch(url, {
      headers: {
        'Content-Type':  'application/json',
        "Authorization": `Bearer ${this.apiTokenValue}`
      }
    })
    return response.json()
  }
}
