import { Controller } from 'stimulus'
import { SearchBar } from '../../lib/webhelpers/searchBar'
import { IncidentSuppression } from '../../lib/webhelpers/incidentSuppression.js'
export default class extends Controller {
  static values = {
    baseUrl: String,
    metadata: Object
  }

  connect() {
    window.rocketcyber.deviceSearch.init(this.metadataValue)
  }

  deviceComment(e) {
    const $modal = $('#device-comment-modal')
    $modal.find('textarea').get(0).value = $(e.currentTarget.closest('tr')).find('.comments').text()
    $modal.get(0).dataset.value = e.target.dataset.id
    $modal.modal('show')
  }

 firewallComment(e) {
    const $modal = $('#firewall-comment-modal')
    $modal.find('textarea').get(0).value = $(e.currentTarget.closest('tr')).find('.comments').text()
    $modal.get(0).dataset.value = e.target.dataset.id
    $modal.modal('show')
  }

  loadDetails(e) {
    window.location.href = this.baseUrlValue + '/' + e.target.dataset.id
  }

  modalDetails(e) {
    const $modal = $('#app-results-modal')
    $modal.get(0).dataset.contentTarget = e.currentTarget.dataset.contentTarget
    $modal.modal('show')
  }
}
