import { Controller } from 'stimulus'
import '../../lib/webhelpers/incidentSuppression'

export default class extends Controller {
  static targets = ['form']

  static values = {
    accountId: String,
    details: Object,
    flag: Boolean,
    modalId: String,
    modalTargetId: String,
    payload: Object
  }

  initialize() {
    this.flagValue = this.flagValue || false
    this.modalIdValue = this.modalIdValue || 'suppressionModal'
    this.modalTargetIdValue = this.modalTargetIdValue || 'suppression_rule_modal'
  }

  connect() {
    if (this.hasPayloadValue)
      window.rocketcyber.incidentSuppression.init(this.payloadValue)
  }

  submitForm(e) {
    e.preventDefault()
    $.ajax({
      type: this.formTarget.method,
      url: this.formTarget.action,
      data: $(this.formTarget).serialize(),
      success: () => {
        window.rocketcyber.incidentSuppression.creationSuccessPopup()
      },
      error: () => {
        window.rocketcyber.incidentSuppression.creationFailedPopup()
      }
    })
  }

  showDeleteModal(e) {
    this.buildModal({ deleteFlag: true })
    this.showModal()
  }

  showViewModal(e) {
    this.buildModal()
    this.showModal()
  }

  buildModal(params = {}) {
    if (!this.hasDetailsValue) return false

    $(`#${this.modalIdValue}`).remove()
    window.rocketcyber.incidentSuppression.createViewModalWindow(
      this.detailsValue, this.flagValue, this.accountIdValue, this.modalIdValue, this.modalTargetIdValue, params.deleteFlag
    )
  }

  showModal() {
    $(`#${this.modalIdValue}`).modal('show')
  }
}
