if (!window.rocketCyber) {
  window.rocketCyber = {}
}
window.rocketcyber.incidentSuppression = {
  'payload': '',
  'htmlBody': `
<div id="incident_suppression">
        <div class="padding-20" id="content">
        <div class="row">
        <div class="col-xs-12">
        <div class="panel panel-default">
        <div class="panel-heading">
            <h2 id="page_title">Incident Notification Suppression Rule <span id="creation_update">Creation</span></h2>
            <label id="update_warning">Editing this rule will only apply changes moving forward. No existing incidents will be modified</label>
            <p>For <a href="https://help.rocketcyber.kaseya.com/help/Content/overview/incident-suppression-overview.html">configuration instructions</a>, see the RocketCyber help docs.</p>
        </div>
        <div class="panel-body">
            <hr>
            <div class="rule_name">
              <h4>Rule Name:</h4>
              <p id="update_warning">Editing this rule will only apply changes moving forward. No existing incidents will be modified</p>
              <input type="text" class="form-control" id="rule_name_input" placeholder="Please provide a name for the rule" title="No symbols or excessive white spaces" maxlength="100" minlength="3">
              <span class="help-block" id="rule_name_error" style="display: none;">Please enter a rule name</span>
            </div>
            <div id="criteria_table_title">
            <h4>List of available criteria used to create suppression rule. Any incident with all selected criteria will be suppressed</h4>
            <span class="error" id="criteria_table_error"></span>
            </div>
        </div>

        <div class="panel-body criteria_table">

            <table class="table fullwidth" data-controller="checkbox-selectable">
                <thead class="bg-transparent" id="table_header">
                  <tr>
                    <th class="criteria">Criteria</th>
                    <th class="operator">Operator</th>
                    <th class="value">Value</th>
                  </tr>
                </thead>
                <tbody class="criteria_details">
                </tbody>
            </table>
        </div>

        <div class="panel-body account_selection">
            <hr>
              <h4>Please select who to apply the suppression rule to.</h4>
              <span class="error" id="account_selection_error"></span>
              <div id="mspLevel" style="width: 100%; display: inline-flex" class="choice">
                  <div class="pl-0">
                    <label class="radio">
                    <input type="radio" value="" class="form-control" data-com.bitwarden.browser.user-edited="yes" name="ruleTarget" style="margin-left: 20px; margin-bottom: 0px;">
                    <i></i>
                    Create rule for all current and future child organizations of <strong id="msp_account"></strong>.
                    </label>
                  </div>
              </div>
              <div id="children_accounts_selection" class="choice">
                  <div id="suppressPopUp" style=" background-color: rgb(103, 106, 108); position: fixed; left: 42%; padding: 30px; color: rgb(255, 255, 255); border-radius: 5px; display: none;">
                    Selecting this option will prevent being able to edit the rule
                    <div style="position: relative; margin: 0 auto; border: 1px solid #fff; width: 40px; height: 30px; top: 15px;">
                      <a style=" color: #fff; padding-left: 7px; position: relative; top: 5px;">OK</a>
                    </div>
                  </div>

                  <div class="pl-0">
                    <label class="radio">
                    <input type="radio" class="form-control" value="" data-com.bitwarden.browser.user-edited="yes" name="ruleTarget" style="margin-left: 20px; margin-bottom: 0px;">
                    <i></i>
                      Target specific organizations
                    </label>
                  </div>

                  <div id="selection_info">
                    <div class="tooltip-wrap">
                      <i class="fa fa-info"></i>
                      <div class="tooltip-content">
                        Rule will be applied to each individually selected organization. Click on the button next to the account to remove the account.
                      </div>
                    </div>
                  </div>

                  <div id="children_accounts">
                    <div id="account_select">
                      <select name="children" multiple>
                      </select>
                      <a class="targetSubmit">Add Organization(s)</a>
                    </div>

                    <div id="selected_accounts">
                      <label>Selected Organizations</label>
                      <div class="account_container">
                      </div>
                    </div>
                  </div>
              </div>
        </div>

        <div class="panel-body" id="duration_selection" "="">
              <hr>
              <div id="update_duration">
                <p id="existing_duration"></p>
                <div id="selectAlways" style="width: 100%; display: inline-flex" class="choice">
                  <div class="pl-0" id="always_select">
                    <label class="radio">
                    <input type="radio" class="form-control" value="" data-com.bitwarden.browser.user-edited="yes" name="durationSelect" style="margin-left: 20px; margin-bottom: 0px;">
                    <i></i>
                    Suppression will <strong>always</strong> run.
                    </label>
                  </div>
              </div>
              <div id="selectDate" style="width: 100%;" class="choice">
                  <div class="pl-0 d-flex" id="date_selection" style="align-items: center">
                    <label class="radio">
                    <input type="radio" class="form-control" value="" data-com.bitwarden.browser.user-edited="yes" name="durationSelect" style="margin-left: 20px; margin-bottom: 0px;">
                    <i></i>
                    Select a new end date</label>
                    <div class="input-group" style="display: none;">
                        <input autocomplete="off" class="form-control" data-date-format="mm/dd/yyyy" maxlength="10" type="text" value=""
                                oninput="this.value = this.value.replace(/[^0-9/]/g, '');">
                          <i class="fa-regular fa-calendar"></i>
                    </div>
                    <span class="error" id="datepicker_selection_error"></span>
                  </div>
              </div>
              </div>

              <div id="create_duration">
                <p>
                Suppression rule will <span id="customText">run for </span><input id="interval_input" type="number" max="100" min="0" data-com.bitwarden.browser.user-edited="yes">
                <select class="form-control" style="border: none;"></select>
                <span id="defaultText"> run</span>
                <span class="error" id="duration_selection_error"></span>
              </p>
              </div>
        </div>

        <div class="panel-body" id="summary" style="/* display: none; */">
              <hr>
              <h4>Suppression Rule Summary</h4>
              <h4 id="rule_name"></h4>
              <label>Selected criteria:</label>
              <table class="table" data-controller="checkbox-selectable" style="width: 597px; border: 1px solid #4b5354" id="criteria_summary">
                <thead class="bg-transparent">
                  <tr>
                    <th>Criteria</th>
                    <th>Operator</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>

              <div class="target_account_summary">
                <label>Targeted organization(s):<span id="account_total"><strong></strong></span></label>
                <ul>
                </ul>
              </div>

              <div class="duration_summary">
                <h4>Duration</h4>
                <p style="margin-left: 20px">Suppression rule will always run</p>
              </div>
        </div>
        <div class="panel-footer clearfix">
              <div class="pull-left"></div>
              <div class="pull-right text-right">
                <input type="submit" id="createButton" name="commit" value="Create" class="btn btn-primary" data-disable-with="Next">
              </div>
        </div>
    </form>
            </div>
            </div>
        </div>
    </div>
</div>`,
  'cssBody': `<style>
#interval_input::-webkit-outer-spin-button,
#interval_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#interval_input[type=number] {
  -moz-appearance: textfield;
}
#page_title {
    margin-bottom: 0;
}
#update_warning {
display: none;
margin-top: 0;
text-transform: none;
}
#middle div.panel-heading {
    color: #1E252D !important;
    height: 51px;
    border: none;
}
#creation_update{
color: #000000;
}
#criteria_table_title h4{
margin-bottom: 0 !important;
}
.criteria_table {
    padding: 0 15px;
    border: solid 1px #4b5354;
    margin: 0 15px;
}
#criteria_table_error p{
    margin: 0;
}
#add_button, #remove{
    user-select: none;
}
#criteria_table_error span{
    line-height: 15px;
}
.criteria_table th{
   border-bottom: 2px solid #ddd
   width: 100%;
}
.criteria_table td {
   width: 25%;
   text-align: left;
}
.criteria_table select {
    min-width: 198px;
}
.criteria_table input[type="text"], .criteria_table input[type="number"] {
      margin: auto 0;
      width: 193px;
   }
td.eventValue {
    overflow-wrap: anywhere;
}
.criteria_table .addButton{
    width:15%;
    vertical-align: middle;
}
.criteria_table .addButton a{
    border: solid 1px #4b5354 !important;
    background-color: #4b5354 !important;
    color: #fff;
    height: 40px;
    width: auto;
    padding: 5px 10px;
    border-radius: 5px;
    vertical-align: middle;
    text-align: center;
    z-index: 2;
    margin: 30px auto auto 0;
}
tr.added_criterion {
    background: #fff;
}
.added_criterion {
   border: solid;
}
/* Account Select */
div#mspLevel .pl-0, div#children_accounts_selection .pl-0 {
    margin-left: 20px;
}
#account_select select::-webkit-scrollbar {
    background: white;
}
#account_select select::-webkit-scrollbar-track {
    background: white;
}
#account_select select::-webkit-scrollbar-thumb {
    position: absolute;
width: 8px;
height: 40px;
left: 512px;
top: 4px;

background: #757575;
mix-blend-mode: normal;
opacity: 0.8;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
z-index: 1;
}
   div#customerLevel {
       width: 100%;
       margin-top: 20px;
   }
    #msp_account {
        padding: 0 0 0 5px;
    }
    #children_accounts, #selection_info {
        display: none;
    }
   .choice {
       border: 1px solid #676a6c;
       padding: 10px;
       position: relative;
       border-radius: 5px;
   }
   #selection_info {
       float: right;
       position: relative;
       color: #fff;
       background-color: #676a6c;
       width: 25px;
       padding-left: 10px;
       padding-top: 2px;
       border-radius: 25px;
       top: -21px;
   }
   .tooltip-wrap {
     position: relative;
   }
   .tooltip-wrap .tooltip-content {
     display: none;
     position: absolute;
     bottom: 16px;
     left: -300px;
     right: 15%;
     background-color: #676a6c;
     padding: .5em;
     min-width: 10rem;
     border: 1px solid #676a6c;
     color: #fff;
     padding: 10px;
     z-index: 1000;
     width: 300px;
     border-radius: 5px;
   }
   .tooltip-wrap:hover .tooltip-content {
     display: block;
   }
   #account_select {
    width: 50%;
   }
   .account_selection h4{
    display: inline-block;
}
.account_bubble {
    border:  3px solid #ddd;
    min-height: 30px;
    border-radius: 5px;
    margin: 5px 5px;
    display: inline-flex;
    padding:  5px 0;
}
.account_container {
    margin-top: -22px;
}
.account_name {
    font-weight: bold;
    margin-right: 5px;
}
.remove_account_anchor {
    right: 0;
    align-content: normal;
    width: 25px;
    margin-left: 5px;
    color: #fff;
}
.remove_account {
    background: #4b5354;
    width: 20px;
    text-align: center;
    border-radius: 5px;
    margin-top: 2px;
    postion: relative;
}
div#targetAccounts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#selected_accounts label {
    margin-left: 10px;
    font-size: 14px;
    top: -16px;
    position: relative;
    background: #fff;
    padding: 0 10px;
    border: 1px solid #fff;
    background: #fff;
    width: 140px;
}
#selected_accounts {
    border:  #ddd 2px solid;
    border-radius: 5px;
    height: 50%;
    width: 50%;
    margin: 0px 15px 15px 15px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    min-height: 50px
}
#account_select select {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    height: 450px;
}
a.targetSubmit {
    border: solid 1px #4b5354 !important;
    background-color: #4b5354 !important;
    color: #fff;
    height: 40px;
    width: auto;
    padding: 5px 10px;
    border-radius: 5px;
    vertical-align: middle;
    text-align: center;
    z-index: 2;
    margin: 30px auto auto 0;
}
#account_select option:disabled {
    color: #CCCCCC;
}
/* Duration */
div#date_selection, div#always_select {
    margin-left: 20px;
}
div#date_selection label, div#always_select label {
    font-size: 19px;
}
#update_duration {
    font-size: 19px;
    display: none;
}
#date_selection p {
   display: inline;
   margin: 0 10px;
}
#always_select strong {
    margin: 0 5px;
}
   #duration_selection p {
       font-size:19px;
       display: flex;
       align-items: center
   }
   #date_selection input[type=date] {
    display: none;
}
   #customText{
    display: none;
    margin-left: 5px;
   }
   #duration_selection p select {
       border: none;
       padding: 0 12px 0 0;
       height: auto;
       width: auto;
       font-size: 19px;
       font-weight: bold;
       margin-left: 5px
   }
   #create_duration select option {
    font-size: 19px;
}
   #duration_selection p input {
    border: 1px solid #CCC;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 34px;
    width: 50px;
    margin: 0 44px 0 10px;
    display: inline;
   }
/* Summary */
   .target_account_summary {
      margin-top: 15px;
   }
   .target_account_summary ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
   }
   .target_account_summary li {
      margin: 0 15px;
   }
   #timeTableSummary table {
     width: 597px;
     border: 1px solid #4b535;
   }
   #timeTableSummary div ul li label {
     margin-left: 10px;
   }
   #timeTableSummary p {
     margin-left: 20px;
   }
/* General */
.error {
    color: #FF0000;
    margin:0 0 5px 15px;
    font-weight: bold;
    font-size: 16px;
   display: none;

}
h4 {
    font-weight: bold;
}
.rule_name {
  margin-bottom: 20px;

}
.rule_name input[type="text"] {
    line-height: 1.5em;
    height: 2.5em;
    margin-top: -10px;
    width: 300px;
    padding: 15px;
   }
/* Modal */
   #suppressPopUp {
       background-color: #676a6c;
       position: fixed:
       left: 42%;
       padding: 30px;
       color: #fff;
       border-radius: 5px;
       display: none;
   }
   #suppressPopUp div {
       position: relative;
       margin: 0 auto;
       border: 1px solid #fff;
       width: 40px;
       height: 30px;
       top: 15px;
   }
   #suppressPopUp a {
       color: #fff;
       padding-left: 7px;
       position: relative;
       top: 5px;
   }
   #create_duration .quantity-nav {
   display: none;
   }
   .fa-calendar {
      margin-left: -34px;
      z-index: 2;
      position: relative;
      height: 24px;
      width: 24px;
      text-align: center;
      margin-top: 7px;
   }
/* Media */
@media only screen and (max-width: 1483px){
   div#selected_accounts {
      height: auto;
   }
}
</style>`,
  'gObj': {
    returningObject: {
      targetAccountIds: [],
      ruleName: '',
      eventId: '',
      intervalCount: '',
      intervalType: '',
      stopDate: '',
      criteria: []
    },
    criteriaObj: []
  },
  'var': {
    currentQuery: ''
  },
  'init': function(payload){
    const gObj = window.rocketcyber.incidentSuppression.gObj
    if (!$ || !jQuery) {
      console.log('missing jQuery')
      return
    }
    if (!payload || payload.length === 0) {
      console.log('missing payload')
      return
    }
    if ($('#incident_suppression')){
      $('#incident_suppression').remove()
    }

    const incidentSuppression = window.rocketcyber.incidentSuppression
    incidentSuppression.payload = payload
    const htmlBody = incidentSuppression.htmlBody
    const cssBody = incidentSuppression.cssBody
    $('head').append(cssBody)
    $('#middle').append(htmlBody)
    $('#msp_account').append(payload?.accountName)
    let defaultTarget = `
      <li>${payload.accountName}</li>
    `
    gObj.returningObject.targetAccountIds = []
    gObj.returningObject.ruleName = ''
    gObj.returningObject.intervalCount = ''
    gObj.returningObject.intervalType = ''
    gObj.returningObject.stopDate = ''
    gObj.returningObject.criteria = []
    gObj.criteriaObj = []

    $('.target_account_summary ul').append(defaultTarget)
    incidentSuppression.ruleNameInput()
    incidentSuppression.generateCriteriaTable(payload)
    incidentSuppression.generateMspAccount()
    incidentSuppression.generateChildrenAccountSelection(payload)
    incidentSuppression.generateIntervalSelection()
    incidentSuppression.generateIntervalTypeSelection()
    incidentSuppression.createButton()
    if(payload.existingRuleDetails){
      incidentSuppression.updateRule()
    }
    if(payload.childrenAccounts.length === 0){
      $('#children_accounts_selection').css('display', 'none')
    }
  },
  'ruleNameInput': function(){
    const incidentSuppression = window?.rocketcyber?.incidentSuppression
    const specialCharacters = /[@!&\/\\#,+()$~%.\-_'^"\;:*?<>\[\]{}=`|]/g
    let ruleNameInput = $('#rule_name_input')
    const error = $('#rule_name_error')
    ruleNameInput.keyup(function(){
      let newName = ruleNameInput.val().replace(specialCharacters,'')
      ruleNameInput.val(newName)
      incidentSuppression.populateReturnObj()
      incidentSuppression.generateRuleNameSummary()
    })
    ruleNameInput.on('change', function(){
      if(error.css('display') !== 'none'){
        incidentSuppression.ruleNameErrorHandling()
      }
    })
  },
  'generateCriteriaName': function(criteriaName){
    const displayName = `
    <td id="criteria_name">${criteriaName}</td>`
    return displayName
  },
  'generateOperator': function(operators){
    const payload = window.rocketcyber.incidentSuppression.payload
    const existingCriteriaList = payload?.existingRuleDetails?.criteriaList
    let operatorOption = ''
    if(existingCriteriaList){
      operators.forEach((operators, index) => {
        const existingOperator = existingCriteriaList.find(id => id.attributeOperatorId === operators?.attributeOperatorId)
        operatorOption += `<option id="operator_value" attributeId=${operators.attributeOperatorId} value='${index}' ${existingOperator?.attributeId ? 'selected' : ''}>${operators?.displayName}</option>`
      })
      return operatorOption
    } else {
      operators.forEach((operators, index) => {
        operatorOption += `<option id="operator_value" attributeId=${operators.attributeOperatorId} value='${index}'}>${operators?.displayName}</option>`
      })
      return operatorOption
    }
  },
  'generateCriteriaValue': function(attributeDetail, defaultValues){
    const payload = window.rocketcyber.incidentSuppression.payload
    let inputType = attributeDetail.inputType
    let optionValue = attributeDetail.enums
    const existingCriteriaList = payload?.existingRuleDetails?.criteriaList
    const defaultValue = defaultValues.find(id => id.attributeId === attributeDetail?.attributeId)
    let existingValue = ''
    if(existingCriteriaList){
      existingValue = existingCriteriaList.find(id => id.attributeId === attributeDetail?.attributeId)
    }

    const inputTypeEnum = {
      NUMBER: 1,
      STRING: 2,
      DROPDOWN: 3
    }
    let determinedValue=''
    if(existingValue?.inputValue){
      determinedValue=existingValue?.inputValue
    } else if(defaultValue?.inputValue){
      determinedValue=defaultValue?.inputValue
    }
    let criteriaValue = ''
    if(inputType === inputTypeEnum.STRING){

      criteriaValue = `
      <td id="criteria_value"><input class="form-control" type="text" value="${determinedValue.replaceAll('"','&quot;')}"></td>
    `
      return criteriaValue
    } else if (inputType === inputTypeEnum.NUMBER){
      criteriaValue = `
      <td id="criteria_value"><input class="form-control" type="number" placeholder="####" value="${determinedValue}"></td>
    `
      return criteriaValue
    } else if (inputType === inputTypeEnum.DROPDOWN){
      let valueOptions = ''

      optionValue.forEach((optionValue) => {
        const defaultEnumValue = defaultValues.find(id => id.enumValueId === optionValue?.enumValueId)
        let determinedOption=''
        if(existingValue?.enumValueId){
          determinedOption=existingValue?.enumValueId
        } else if(defaultEnumValue?.enumValueId) {
          determinedOption=defaultEnumValue?.enumValueId
        }
        valueOptions += `<option enumValueId="${optionValue?.enumValueId}" ${determinedOption === optionValue?.enumValueId ? 'selected' : ''}>${optionValue?.optionName}</option>`
      })
      criteriaValue = `
      <td id="criteria_value"><select class="form-control"><option>'Select a value'</option>${valueOptions}</select></td>
    `
      return criteriaValue
    }
  },
  'generateCriteriaTable': function(payload){
    const attributeList = payload?.attributeList
    const defaultValue = payload?.defaultCriteriaList
    const incidentSuppression = window.rocketcyber.incidentSuppression
    attributeList.forEach(function(attributeDetail, index){
      const criteriaDetails = `
      <tr class="added_criterion" attributeId=${attributeDetail.attributeId} value="${index}">
        ${incidentSuppression.generateCriteriaName(attributeDetail.displayName)}
        <td id="criteria_operator"><select class="form-control"><option id="operator_value" value="">Select an operator</option>${incidentSuppression.generateOperator(attributeDetail.operators, index)}<select></td>
        ${incidentSuppression.generateCriteriaValue(attributeDetail, defaultValue)}
        <td class="addButton"><a id="add_button">Add</a> <a id="remove">Remove</a></td>
      </tr>
      `
      $('.criteria_details').append(criteriaDetails)
    })
    incidentSuppression.addButton()
    incidentSuppression.removeButton()
  },
  'addButton': function() {
    const incidentSuppression = window.rocketcyber.incidentSuppression
    const gObj = window.rocketcyber.incidentSuppression.gObj
    gObj.criteriaObj = []
    $('[id="add_button"]').on('click', function () {
      let attributeId = Number($(this).closest('tr').attr('attributeId'))
      let criteriaName = $(this).closest('tr').find('#criteria_name').text()
      let operatorId = Number($(this).closest('tr').find('#criteria_operator :selected').attr('attributeId'))
      let operatorValue = $(this).closest('tr').find('#criteria_operator :selected').text()
      let criterionId = ''
      if($(this).closest('tr').attr('criterionId')){
        criterionId = $(this).closest('tr').attr('criterionId')
      } else {
        criterionId = null
      }
      let criteriaValue = ''
      let inputValue = ''
      if ($(this).closest('tr').find('#criteria_value select').length) {
        criteriaValue = Number($(this).closest('tr').find('#criteria_value select option:selected').attr('enumValueId'))
        inputValue = $(this).closest('tr').find('#criteria_value select option:selected').val()
      } else {
        criteriaValue = $(this).closest('tr').find('#criteria_value input').val()
        inputValue = $(this).closest('tr').find('#criteria_value input').val()
      }
      if (isNaN(criteriaValue && !$(this).closest('tr').find('#criteria_value input').is('input'))){
        criteriaValue = ''
      }
      if(typeof(criteriaValue) !== 'number') {
        criteriaValue = criteriaValue.trim()
      }
      if(!criteriaValue && $(this).closest('tr').find('#criteria_value input').is('input')){
        $(this).closest('tr').find('#criteria_value input').val('')
        criteriaValue = $(this).closest('tr').find('#criteria_value input').val()
      } else {
        $(this).closest('tr').find('#criteria_value input').val(criteriaValue)
      }
      incidentSuppression.criteriaTableErrorHandling(attributeId, operatorValue, criteriaValue, $(this))

      let valueInput = $(this).closest('tr').find('#criteria_value input')
      let selectInput = $(this).closest('tr').find('#criteria_operator select')

      if(!$('#criteria_table_error p').text().length){
        if($(this).html() === 'Update') {
          for(let i = 0; i < gObj.criteriaObj.length; i++){
            if(gObj.criteriaObj[i].attributeId === attributeId){
              delete gObj.criteriaObj[i]
              gObj.criteriaObj = gObj.criteriaObj.filter(value => Object.keys(value).length !== 0)
            }
          }
        }
        $(this).closest('tr').css('background', '#e5e4e2')
        $(this).html('Update')
        gObj.criteriaObj.push({
          attributeId: attributeId,
          operatorId: operatorId,
          criteriaValue: criteriaValue,
          criteriaName: criteriaName,
          operatorValue: operatorValue,
          inputValue: inputValue,
          criterionId: criterionId
        })
        $(this).closest('tr').css('background', '#e5e4e2')
        $(this).html('Update')

        valueInput.removeClass('--invalid')
        selectInput.removeClass('--invalid')

        valueInput.parent().find('.help-block').remove()
        selectInput.parent().find('.help-block').remove()

        incidentSuppression.generateCriteriaTableSummary()
        incidentSuppression.populateReturnObj()
        if(gObj.returningObject.criteria.length !== 0){
          $('#criteria_table_error').css('display', 'none')
        }
      } else {
        let errorText = $('#criteria_table_error p').text()
        let errorDiv = `<div class='help-block --error-text'>${errorText}</div`
        if (errorText.includes('select')) {
          if (selectInput.next().text() != errorText) {
            selectInput.parent().append(errorDiv)
          }
        } else {
          if (valueInput.next().text() != errorText) {
            valueInput.parent().append(errorDiv)
          }
        }

        $('#criteria_table_error p').remove()
        valueInput.addClass('--invalid')
        selectInput.addClass('--invalid')
        if($(this).html() === 'Update') {
          for(let i = 0; i < gObj.criteriaObj.length; i++){
            if(gObj.criteriaObj[i].attributeId === attributeId){
              delete gObj.criteriaObj[i]
              gObj.criteriaObj = gObj.criteriaObj.filter(value => Object.keys(value).length !== 0)
            }
          }
        }
        incidentSuppression.generateCriteriaTableSummary()
        incidentSuppression.populateReturnObj()
      }
    })
  },
  'removeButton': function(){
    const gObj = window.rocketcyber.incidentSuppression.gObj
    const incidentSuppression = window.rocketcyber.incidentSuppression
    $('[id="remove"]').on('click', function(){
      let attributeId = Number($(this).closest('tr').attr('attributeId'))
      for(let i = 0; i < gObj.criteriaObj.length; i++){
        if(gObj.criteriaObj[i].attributeId === attributeId){
          delete gObj.criteriaObj[i]
          gObj.criteriaObj = gObj.criteriaObj.filter(value => Object.keys(value).length !== 0)
        }
      }
      $(this).closest('tr').css('background', '#fff')
      $(this).closest('td').find('a#add_button').html('Add')
      $(this).closest('tr').find('#criteria_value input').val('')
      $(this).closest('tr').find('#criteria_operator option[value=""]').prop('selected', true)
      if($('#criteria_table_error').css('display') === 'inline'){
        $('#criteria_table_error').css('display', 'none')
      }
      if($(this).closest('tr').find('#criteria_value').children().is('select')){
        $(this).closest('tr').find('#criteria_value select option:eq(0)').prop('selected', true)
      }
      incidentSuppression.generateCriteriaTableSummary()
      incidentSuppression.populateReturnObj()
    })
  },
  'generateMspAccount': function(){
    const incidentSuppression = window.rocketcyber.incidentSuppression
    const mspLevelInput = $('#mspLevel input')
    mspLevelInput.prop('checked', true)
    mspLevelInput.on('click',function(){
      $('#children_accounts, #selection_info').css('display', 'none')
      incidentSuppression.generateTargetAccountSummary()
      incidentSuppression.populateReturnObj()
      incidentSuppression.accountSelectionErrorHandling()
    })
  },
  'generateChildrenAccountList': function(accounts){
    const accountArray = accounts.map(function(accounts){
      return accounts['name']
    })
    let childrenAccounts = ''
    for(let i = 0; i < accountArray.length; i++){
      childrenAccounts += `<option index=${i}>${accountArray[i]}</option>`
    }
    return childrenAccounts

  },
  'generateChildrenAccountSelection': function(payload){
    const incidentSuppression = window.rocketcyber.incidentSuppression
    const childrenAccount = payload.childrenAccounts
    const childrenAccounts = `
    ${this.generateChildrenAccountList(childrenAccount)}
    `
    $('#account_select select').append(childrenAccounts)

    $('#children_accounts_selection input').on('click',function(){
      $('#children_accounts, #selection_info').css('display', 'flex')
      incidentSuppression.populateReturnObj()
    })

    $('#account_select select option').on('mousedown', function(e) {
      let $this = $(this),
        that = this,
        scroll = that.parentElement.scrollTop
      e.preventDefault()
      $this.prop('selected', !$this.prop('selected'))
      setTimeout(function() {
        that.parentElement.scrollTop = scroll
      }, 0)
      return false
    })
    this.addAccountsButton()
  },
  'generateSelectedAccountContainer': function(selectedAccount){
    selectedAccount.forEach(function(details){
      const accountBubble = `
      <div class='account_bubble'>
        <a class="remove_account_anchor" onclick="window.rocketcyber.incidentSuppression.removeAccount(this)" title="Remove">
            <div class="remove_account" index=${details.name}>
                <i class="fa fa-minus nomargin nopadding"></i>
            </div>
        </a>
        <div class="account_name">${details.name}</div>
      </div>
    `
      $('.account_container').append(accountBubble)
    })
    this.populateTargetIdsArray()
  },
  'addAccountsButton': function(){
    const childrenAccounts = window.rocketcyber.incidentSuppression.payload.childrenAccounts
    const incidentSuppression = window.rocketcyber.incidentSuppression
    $('.targetSubmit').on('click',function(){
      let selectedAccount = []
      $('#account_select select option:selected').each(function(){
        $('#account_select select option:selected').attr('disabled','disabled')
        let determinedAccount = childrenAccounts.find(id => id.name === $(this).text())
        selectedAccount.push({
          id: determinedAccount.id,
          name: determinedAccount.name
        })
      })
      $('#account_select select option').prop('selected', false)
      incidentSuppression.generateSelectedAccountContainer(selectedAccount)
      incidentSuppression.accountSelectionErrorHandling()
      incidentSuppression.populateTargetIdsArray()
    })
  },
  'removeAccount': function(account){
    const accountName = $(account).closest('div').find('.account_name').text()
    const incidentSuppression = window.rocketcyber.incidentSuppression
    $(':disabled').each(function(){
      if(accountName === $(this).text()){
        $(this).removeAttr('disabled')
      }
    })
    if(accountName )
      $(account).parent($('.account_bubble')).remove()
    incidentSuppression.populateTargetIdsArray()
  },
  'populateTargetIdsArray': function() {
    const childrenAccount = window.rocketcyber.incidentSuppression.payload.childrenAccounts
    const gObj = window.rocketcyber.incidentSuppression.gObj.returningObject
    const incidentSuppression = window.rocketcyber.incidentSuppression
    let selectedAccountName = []
    gObj.targetAccountIds = []
    $('.account_name').each(function(){
      let accountName = $(this).text()
      let determinedAccount = childrenAccount.find(id => id.name === accountName)
      selectedAccountName.push(determinedAccount.name)
      gObj.targetAccountIds.push(determinedAccount.id)
    })
    incidentSuppression.generateTargetAccountSummary(selectedAccountName)
  },
  'generateIntervalSelection': function(){
    const incidentSuppression = window.rocketcyber.incidentSuppression
    let defaultText = $('#defaultText')
    let customText = $('#customText')
    let input = $('#duration_selection input[type=number]')
    input.css('display', 'none')
    $('#duration_selection select').on('change',function(){
      defaultText.css('display','none')
      customText.css('display', 'inline')
      input.css({'display':'inline', 'border':'1px solid #CCC'})
      $('#create_duration .quantity-nav').css('display','inline')
      $('#duration_selection .quantity-nav').css('display','inline')
      if($('#duration_selection select option:selected').val() === 'always'){
        defaultText.css('display','inline')
        customText.css('display', 'none')
        input.val('').css('display', 'none')
        input.css('border', 'none')
        $('#create_duration .quantity-nav').css('display','none')
        incidentSuppression.intervalSelectionErrorHandling()
      }
      incidentSuppression.populateReturnObj()
      incidentSuppression.generateIntervalSummary()
    })
    input.on('change', function(){
      incidentSuppression.populateReturnObj()
      incidentSuppression.generateIntervalSummary()
      incidentSuppression.intervalSelectionErrorHandling()
    })
  },
  'generateIntervalTypeSelection': function(){
    const intervalTypeMap = window.rocketcyber.incidentSuppression.payload.intervalTypeMap
    for (const property in intervalTypeMap){
      const intervalHtml = `
        <option intervalType="${property}" ${intervalTypeMap[property] === 'always' ? 'selected' : ''}>${intervalTypeMap[property]}</option>
      `
      $('#duration_selection select').append(intervalHtml)

    }
  },
  'generateRuleNameSummary': function(){
    const gObj = window.rocketcyber.incidentSuppression.gObj.returningObject
    $('#rule_name').html(gObj.ruleName)
  },
  'generateCriteriaTableSummary': function(){
    const criteria = window.rocketcyber.incidentSuppression.gObj.criteriaObj
    $('#criteria_summary tbody tr').remove()
    criteria.forEach(function (criterionDetails) {
      const eventSummaryTable = `
    <tr>
      <td>${criterionDetails.criteriaName}</td>
      <td>${criterionDetails.operatorValue}</td>
      <td>${criterionDetails.inputValue}</td>
    </tr>
    `
      $('#criteria_summary tbody').append(eventSummaryTable)
    })
  },
  'generateTargetAccountSummary': function(selectedAccount){
    const defaultTarget = $('#msp_account').text()
    $('#account_total strong').remove()
    $('.target_account_summary ul li').remove()
    if($('#children_accounts_selection input').is(':checked')){
      let totalHtml = `
    <strong>${selectedAccount.length} Total<strong>
    `
      $('#account_total').append(totalHtml)
      selectedAccount.forEach(function(accountName){
        let targetAccountHtml = `
      <li>${accountName}</li>
      `
        $('.target_account_summary ul').append(targetAccountHtml)
      })
    } else {
      let targetAccountHtml = `
      <li>${defaultTarget}</li>
      `
      $('.target_account_summary ul').append(targetAccountHtml)
    }
  },
  'generateIntervalSummary': function(){
    let interval = window.rocketcyber.incidentSuppression.gObj.returningObject
    if(interval.intervalType !== 0){
      const summaryHtml = `
      <p>Suppression rule will run for ${interval.intervalCount} ${$('#duration_selection select option:selected').val()} </p>`
      $('.duration_summary p').html(summaryHtml)
    } else {
      const summaryHtml = `
      <p>Suppression rule will always run </p>`
      $('.duration_summary p').html(summaryHtml)
    }
  },
  'populateReturnObj': function(){
    const gObj = window.rocketcyber.incidentSuppression.gObj.returningObject
    const payload = window.rocketcyber.incidentSuppression.payload
    const criteriaObj = window.rocketcyber.incidentSuppression.gObj.criteriaObj
    const incidentSuppression = window.rocketcyber.incidentSuppression
    const alwaysSelected = $('#always_select input[type=radio]').prop('checked')
    const mspSelected = $('#mspLevel input[type=radio]').prop('checked')
    let newDate = $('#date_selection input[type=text]').val()
    newDate = newDate.replaceAll('-','/')
    let newTime = new Date(newDate)
    newTime = newTime.setHours(23, 59, 59, 59)
    const existingDate = payload?.existingRuleDetails?.stopDate
    let existingTime = ''
    if(existingDate !== null){
      existingTime = new Date(existingDate).getTime()
    } else {
      existingTime = null
    }
    gObj.criteria = []
    //Criteria object
    criteriaObj.forEach(function(criteriaDetails) {
      let inputType = ''
      let value = ''
      if(typeof(criteriaDetails.criteriaValue) === 'string'){
        inputType = 'inputValue'
        value = criteriaDetails.inputValue
      } else {
        inputType = 'enumValueId'
        value = criteriaDetails.criteriaValue
      }
      if(payload?.existingRuleDetails){
        gObj.criteria.push({
          criterionId: criteriaDetails.criterionId,
          attributeOperatorId: criteriaDetails.operatorId,
          [inputType]: value
        })
      } else {
        gObj.criteria.push({
          attributeOperatorId: criteriaDetails.operatorId,
          [inputType]: value
        })
      }
    })
    if(gObj.targetAccountIds.length === 0){
      gObj.targetAccountIds.push(payload.accountId)
    }
    //Interval Count / Type
    gObj.intervalCount = ''
    gObj.intervalType = ''
    gObj.ruleName = ''
    gObj.intervalType = Number($('#duration_selection select option:selected').attr('intervalType'))
    if(gObj.intervalType === 0){
      gObj.intervalCount = null
    } else {
      gObj.intervalCount = $('#duration_selection input[type=number]').val()
    }
    gObj.accountId = payload.accountId
    if(gObj.targetAccountIds.length === 0 || mspSelected){
      gObj.targetAccountIds = []
      gObj.targetAccountIds.push(gObj.accountId)
    } else {
      incidentSuppression.populateTargetIdsArray()
    }
    //Update date selection
    if(newDate){
      gObj.stopDate = newTime
    } else if (alwaysSelected){
      gObj.stopDate = null
    } else {
      gObj.stopDate = existingTime
    }

    gObj.eventId = payload.eventId
    gObj.ruleName = $('#rule_name_input').val()
  },
  'populateForm': function(){
    const returningObj = window.rocketcyber.incidentSuppression.gObj.returningObject
    let criteriaObj = returningObj.criteria
    let targetAccounts = returningObj.targetAccountIds
    $('#suppression_event_id').val(returningObj.eventId)
    $('#suppression_rule_name').val(returningObj.ruleName)

    $('#suppression_interval_count').val(returningObj.intervalCount)
    $('#suppression_interval_type').val(returningObj.intervalType)

    $('#suppression_criteria').val(JSON.stringify(criteriaObj))
    $('#suppression_targeted_account_ids').val(JSON.stringify(targetAccounts))
    $('#suppression_stop_date').val(returningObj.stopDate)
  },
  'ruleNameErrorHandling': function(){
    const specialCharacters = /[@!&\/\\#,+()$~%.\-_'^"\;:*?<>\[\]{}=`|]/g
    let ruleNameInput = $('#rule_name_input')
    let newName = ruleNameInput.val().replace(specialCharacters,'')
    ruleNameInput.val(newName)
    ruleNameInput.val(ruleNameInput.val().trim(/^\s+|\s+$/g))
    if(ruleNameInput.val().length === 0){
      $('#rule_name_error').parent().addClass('has-error')
      $('#rule_name_error').css('display','inline')
      return true
    } else {
      $('#rule_name_error').parent().removeClass('has-error')
      $('#rule_name_error').css('display','none')
      return false
    }
  },
  'criteriaTableErrorHandling': function(attributeId, operatorValue, criteriaValue, element){
    const payload = window.rocketcyber.incidentSuppression.payload
    const criteriaTableError = $('#criteria_table_error')
    let attributeList = payload.attributeList
    if(typeof(criteriaValue) !== 'number'){
      criteriaValue = criteriaValue.trim(/^\s+|\s+$/g)
    }
    $('#criteria_table_error p').remove()
    attributeList.forEach(function(details){
      if(details.attributeId === attributeId && details.valueFilters){
        details.valueFilters.forEach(function(filter){
          if(criteriaValue.toLowerCase() === filter){
            const error = `
                <p>${criteriaValue} is not acceptable</p>`
            criteriaTableError.append(error).css('display', 'inline')
            return true
          } else {
            return false
          }
        })
      }
    })
    if(operatorValue === 'Select an operator'){
      const error = `
        <p>Please select an operator</p>`
      criteriaTableError.append(error).css('display', 'inline')
    } else if(!criteriaValue && element.closest('tr').find('#criteria_value').children().is('input')){
      element.closest('tr').find('#criteria_value input').val(criteriaValue)
      const error = `
      <p>Please enter a value</p>`
      criteriaTableError.append(error).css('display', 'inline')
      return true
    } else if(!criteriaValue && !element.closest('tr').find('#criteria_value').children().is('input')){
      const error = `
      <p>Please select a value</p>`
      criteriaTableError.append(error).css('display', 'inline')
      return true
    }
  },
  'removeTableError': function(){
    Array.from($('.criteria_table').find('input')).forEach(field => {
      field.classList.remove('--invalid')
    })

    Array.from($('.criteria_table').find('select')).forEach(field => {
      field.classList.remove('--invalid')
    })
  },
  'tableError': function(){
    const criteria = window.rocketcyber.incidentSuppression.gObj.returningObject.criteria
    const criteriaTableError = $('#criteria_table_error')
    $('#criteria_table_error p').remove()

    Array.from($('.criteria_table').find('input')).forEach(field => {
      if (!field.value) {
        field.classList.add('--invalid')
      }
    })

    Array.from($('.criteria_table').find('select')).forEach(field => {
      if (field.selectedIndex === 0) {
        field.classList.add('--invalid')
      }
    })

    if(criteria.length === 0){
      const error = `
       <p>Please add a criteria</p>`
      criteriaTableError.append(error).css('display', 'inline')
      return true
    } else {
      return false
    }
  },
  'accountSelectionErrorHandling': function(){
    const accountError = $('#account_selection_error')
    accountError.html('')
    if($('#children_accounts_selection input').is(':checked') && !$('.account_bubble').length) {
      const error = 'Please select an account'
      accountError.html(error).css('display', 'inline')
      return true
    } else {
      accountError.css('display', 'none')
      return false
    }
  },
  'intervalSelectionErrorHandling': function(){
    let intervalCount = $('#interval_input').val()
    const durationError = $('#duration_selection .error')
    const optionType = $('#duration_selection option:selected')
    const dateSelection = $('#date_selection input[type=radio]')
    const datePicker = $('#date_selection input[type=text]')
    durationError.html('')
    if((!intervalCount && optionType.attr('intervalType') !== '0')){
      const error = 'Please select a duration'
      durationError.html(error).show()
      return true
    } else if((dateSelection.is(':checked') && !datePicker.val().length)){
      const error = 'Please enter a date'
      durationError.html(error).show()
      return true
    } else if (intervalCount > 100 && optionType.attr('intervalType') !== '0') {
      const error = 'Please select a duration of 100 or less'
      durationError.html(error).show()
      return true
    } else if(intervalCount <= 0 && optionType.attr('intervalType') !== '0'){
      const error = 'Please select a duration more than 0'
      durationError.html(error).show()
      return true
    } else{
      durationError.hide()
      return false
    }
  },
  'creationSuccessPopup': function(){
    const payload = window.rocketcyber.incidentSuppression.payload
    let successStatement = ''
    let redirectMessage = ''
    if(payload?.existingRuleDetails){
      successStatement = 'Rule successfully updated.'
      redirectMessage = 'You will be redirected to the suppression rules page.'
    } else {
      successStatement = 'Rule successfully created.'
      redirectMessage = 'You will be redirected to the incident detail page.'
    }
    const html=`
                    <div id="generateReportWindow" class="downloadResponse">
                        <p>${successStatement}</p>
                        <p>${redirectMessage}</p>
                      <button class="closebtn" onclick="window.rocketcyber.incidentSuppression.closeCreatePopup();  history.back();"> OK </button>
                    <style>
                    div#generateReportWindow {
                        position: fixed;
                        width: 400px;
                        margin-left: -200px;
                        margin-top: -80px;
                        height: 160px;
                        left: 50%;
                        display: block;
                        z-index: 1000;
                        border: 1px #000 solid;
                        top: 47%;
                        background: #5a6667;
                        color: #fff;
                        border-radius: 5px;
                        padding: 13px;
                    }

                    button.closebtn {
                        margin: 0 44%;
                        border: 2px #fff solid;
                        padding: 0px 10px;
                        border-radius: 5px;
                    }

                    #generateReportWindow p {
                        padding: 0 5px;
                        margin: 15px auto;
                    }
                    </style>
                </div>
                `
    $('#createButton').prop('disabled', true)
    $('#incident_suppression').css('pointer-events', 'none')
    $('body').prepend(html)
  },
  'creationFailedPopup': function(){
    const payload = window.rocketcyber.incidentSuppression.payload
    let failedStatement = ''
    if(payload?.existingRuleDetails){
      failedStatement = 'Unable to update rule, please try again.'
    } else {
      failedStatement = 'Unable to create rule, please try again.'
    }
    const html=`
                    <div id="generateReportWindow" class="downloadResponse">
                        <p>${failedStatement}</p>
                        <p>If you continue to have problems, please contact technical support.</p>
                      <button class="closebtn" onclick="window.rocketcyber.incidentSuppression.closeCreatePopup(); $('#suppressionRulePage').css('pointer-events', ''); $('#createButton').prop('disabled', false)"> OK </button>
                    <style>
                    div#generateReportWindow {
                        position: fixed;
                        width: 400px;
                        margin-left: -200px;
                        margin-top: -80px;
                        height: 160px;
                        left: 50%;
                        display: block;
                        z-index: 1000;
                        border: 1px #000 solid;
                        top: 47%;
                        background: #5a6667;
                        color: #fff;
                        border-radius: 5px;
                        padding: 13px;
                    }

                    button.closebtn {
                        margin: 0 44%;
                        border: 2px #fff solid;
                        padding: 0px 10px;
                        border-radius: 5px;
                    }

                    #generateReportWindow p {
                        padding: 0 5px;
                        margin: 15px auto;
                    }
                    </style>
                </div>
                `
    $('#createButton').prop('disabled', true)
    $('#incident_suppression').css('pointer-events', 'none')
    $('body').prepend(html)
  },
  'closeCreatePopup':function (){
    $('#generateReportWindow').remove()
    $('#incident_suppression').css('pointer-events', '')
  },
  'createButton': function(){
    $('#createButton').on('click',function (){
      const incidentSuppression = window.rocketcyber.incidentSuppression
      incidentSuppression.populateForm()
      let ruleNameErrorHandling = incidentSuppression.ruleNameErrorHandling()
      let accountSelectionErrorHandling = incidentSuppression.accountSelectionErrorHandling()
      let intervalSelectionErrorHandling = incidentSuppression.intervalSelectionErrorHandling()
      let tableError = ''
      const ruleNameErrorMessage = $('#rule_name_error').css('display')
      const tableErrorMessage = $('#criteria_table_error').css('display')
      const accountErrorMessage = $('#account_selection_error').css('display')
      const intervalErrorMessage = $('#duration_selection .error').css('display')

      if(ruleNameErrorHandling || accountSelectionErrorHandling || intervalSelectionErrorHandling || tableError){
        if (tableErrorMessage !== 'inline'){
          tableError = incidentSuppression.tableError()
        }
        return false
      } else if(ruleNameErrorMessage === 'inline' || tableErrorMessage === 'inline' || accountErrorMessage === 'inline' || intervalErrorMessage === 'inline'){
        if (tableErrorMessage !== 'inline'){
          tableError = incidentSuppression.tableError()
        }
        return false
      }else {
        incidentSuppression.removeTableError();
        $('#hiddenSubmitButton').click()
      }
    })
  },
  'updateRule': function(){
    const incidentSuppression = window.rocketcyber.incidentSuppression
    const update = 'Update'
    $('#creation_update').html(update)
    $('#createButton').attr('value', 'Update')
    $('#update_warning').css('display', 'block')
    incidentSuppression.updateRuleName()
    incidentSuppression.updateAccountsTable()
    incidentSuppression.updateSummary()
    incidentSuppression.updateInterval()
  },
  'updateRuleName': function(){
    const payload = window.rocketcyber.incidentSuppression.payload
    const existingRuleName = payload.existingRuleDetails.ruleName
    $('.rule_name input').val(existingRuleName)
  },
  'updateAccountsTable': function(){
    const existingValues = window.rocketcyber.incidentSuppression.payload.existingRuleDetails
    const childrenAccounts = window.rocketcyber.incidentSuppression.payload.childrenAccounts
    const payload = window.rocketcyber.incidentSuppression.payload
    const incidentSuppression = window.rocketcyber.incidentSuppression
    let existingAccounts = []
    if(existingValues.targetedAccountIds[0] !== payload?.accountId){
      existingValues.targetedAccountIds.forEach(function(accountId){
        let determinedAccount = childrenAccounts.find(child => child.id === accountId)
        existingAccounts.push({
          id: determinedAccount.id,
          name: determinedAccount.name
        })
      })
      existingAccounts.forEach(function(account){
        $('#account_select select option').each(function(){
          let optionValue = this.value
          if(account.name === optionValue){
            $(this).attr('disabled', 'disabled')
          }
        })
      })
      incidentSuppression.generateSelectedAccountContainer(existingAccounts)
      $('#children_accounts_selection input').prop('checked',true)
      $('#children_accounts, #selection_info').css('display', 'flex')
    } else {
      existingAccounts.push(payload.targetAccountIds)
    }
    incidentSuppression.populateTargetIdsArray(existingAccounts)
  },
  'updateInterval': function(){
    const existingValues = window.rocketcyber.incidentSuppression.payload.existingRuleDetails
    const ruleName = existingValues.ruleName
    let existingDate = existingValues.stopDate
    let endDate = ''
    let messageDate = ''
    let calendarDate = ''
    if(existingDate !== null){
      endDate = new Date(existingDate)
      messageDate = `${endDate.getMonth()+1<10?'0'+(endDate.getMonth()+1):(endDate.getMonth()+1)}/${endDate.getDate()<10?'0'+endDate.getDate():endDate.getDate()}/${endDate.getFullYear()}`
      calendarDate = `${endDate.getFullYear()}-${endDate.getMonth()+1<10?'0'+(endDate.getMonth()+1):(endDate.getMonth()+1)}-${endDate.getDate()<10?'0'+endDate.getDate():endDate.getDate()}`
    }
    const incidentSuppression = window.rocketcyber.incidentSuppression
    const datePicker = $('#date_selection input[type=text]')
    let html = ''
    if(existingDate){
      html = `${ruleName} will run until ${messageDate}`
    } else {
      html = `${ruleName} will always run`
    }
    if(existingValues){
      $('#existing_duration').html(html).css('display', 'block')
      $('#update_duration').css('display', 'block')
      $('#create_duration').css('display', 'none')
      $('#date_selection input[type=radio]').on('click', function(){
        datePicker.val(calendarDate)
        datePicker.parent().css('display', 'inline')
        datePicker.datepicker({
          templates: {
            leftArrow: '<i class="fa-solid fa-chevron-left"></i>',
            rightArrow: '<i class="fa-solid fa-chevron-right"></i>',
          },
          todayHighlight: true,
          orientation: 'bottom auto',
          maxViewMode: 2
        });
        const updateIntervalSummary = `
          <p>Suppression rule will run until ${messageDate}</p>`
        $('.duration_summary p').html(updateIntervalSummary)
        incidentSuppression.intervalDatePicker()
        incidentSuppression.populateReturnObj()
      })
      $('#always_select input[type=radio]').on('click', function(){
        $('#date_selection input[type=text]').val('')
        $('#date_selection input[type=text]').parent().css('display', 'none')
        const updateIntervalSummary = `
            <p>Suppression rule will always run</p>`
        $('.duration_summary p').html(updateIntervalSummary)
        incidentSuppression.populateReturnObj()
        $('#datepicker_selection_error').hide()
      })
    }
    if(!endDate){
      $('#always_select input[type=radio]').prop('checked', true)
    }
  },
  'updateSummary': function(){
    const incidentSuppression = window.rocketcyber.incidentSuppression
    const existingValue = window.rocketcyber.incidentSuppression.payload.existingRuleDetails
    $('#rule_name').html(existingValue?.ruleName)
    incidentSuppression.updateCriteriaTableSummary()
    incidentSuppression.updateIntervalSummary()
  },
  'updateCriteriaTableSummary': function(){
    const existingValue = window.rocketcyber.incidentSuppression.payload.existingRuleDetails.criteriaList
    existingValue.forEach(function(existingValue){
      let attributeId = existingValue.attributeId
      let criterionId = existingValue.criterionId
      $(`tr[attributeid="${attributeId}"]`).attr('criterionId',criterionId)
      $(`tr[attributeid="${attributeId}"] td.addButton #add_button`).click()
    })
  },
  'intervalDatePicker': function(){
    const errorMessage = $('#datepicker_selection_error')
    const incidentSuppression = window.rocketcyber.incidentSuppression
    $('#date_selection input[type=text]').change(function() {
      if(errorMessage.css('display') === 'inline'){
        errorMessage.hide()
      }
      let newDate = $('#date_selection input[type=text]').val()
      newDate = newDate.replaceAll('-','/')
      newDate = new Date(newDate)
      newDate = newDate.setHours(23, 59, 59, 59)
      newDate = new Date(newDate)
      newDate = newDate.toLocaleString()
      const updateIntervalSummary = `
        <p>Suppression rule will run until ${newDate}</p>`
      $('.duration_summary p').html(updateIntervalSummary)
      incidentSuppression.populateReturnObj()
    })
  },
  'updateIntervalSummary': function(){
    const existingEndDate = window.rocketcyber.incidentSuppression.payload?.existingRuleDetails?.stopDate
    if(existingEndDate) {
      let endDate = new Date(existingEndDate)
      endDate = endDate.toLocaleString()
      const updateIntervalSummary = `
                <p>Suppression rule will run until ${endDate}</p>`
      $('.duration_summary p').html(updateIntervalSummary)
    }
  },
  'createViewModalWindow':function (payload,suppression,account, modalId, targetId, deleteLink) {
    let tableHTML=''
    if(payload?.criteriaList){
      tableHTML = rocketcyber.incidentSuppression.createModalTable(payload?.criteriaList)
    }
    let subTextHTML =''
    if(suppression && payload?.editable!==true){
      subTextHTML=`<label>Provider created rules must be edited at the Provider level. Please switch to Provider level or contact your provider for changes.</label>`
    } else if(!suppression){
      subTextHTML =`<label>If you want to make changes to this rule you must do so from the Suppression Rules page.</label>`
    } else if(deleteLink) {
      subTextHTML = `<label>Are you sure that you want to delete this rule?</label>`
    }
    let modalHtml = ''
    if(payload?.stopDate){
      let endDate = new Date(payload?.stopDate)
      endDate = endDate.toLocaleString()
      modalHtml = `This rule will expire ${endDate}`
    } else {
      modalHtml = 'This rule will always run'
    }
    const incidentURL = `/accounts/${account}/apps/incidents/${payload?.originIncidentId}`
    const targetAccountsHTML = rocketcyber.incidentSuppression.createModalAccountList(payload?.targetedAccounts)
    const modalHTML=`<div aria-hidden="true" class="modal fade in" id="${modalId}" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg">
        <div class="modal-content" id="suppression_rule_modal">
           <div class="modal-header">
            <div class="modalTitle">
              <h2>Suppression Rule</h2>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="subText">
             <h3>${ payload?.ruleName ? payload?.ruleName : ''}</h3>
             ${subTextHTML}<br>
            </div>
           </div>
           <div class="modal_spacer"></div>
           <div class="modal-body">
              <div class="row">
                 <div class="col-xs-12" ">
                    <div class="panel-body" id="timeTable" ">
                       <h4>Selected event attributes:</h4>
                        ${tableHTML}
                        ${targetAccountsHTML}
                       <h4>Duration</h4>
                       <label id="modal_duration">${modalHtml}</label>
                       <h4>Created By:</h4>
                       <label id="modal_createdBy">${payload?.createdBy}</label>
                       <h4>Last Edited By:</h4>
                       <label id="modal_editedBy">${payload?.modifiedBy}</label>
                       <h4>Originating incident:</h4>
                       <label id="modal_originatingIncident"><a href="${incidentURL}">${payload?.originIncidentName}</a></label>
                    </div>
                 </div>
              </div>
           </div>
           <div class="modal_spacer"></div>
           <div class="modal-footer">
             <div class="text-right">
                <button class="btn btn-secondary close_button" data-dismiss="modal" aria-label="Close">Close</button>
                <button class="btn btn-prime btn-critical btn-default delete_rule_button" aria-label="Delete">Delete</button>
             </div>
           </div>
        </div>`
    const cssBody =
        `<style>
        .modal label {
          font-style: italic;
          font-weight: 700;
        }
        #modal_attributeTable {
          width: 597px;
          border: none;
          border-bottom: 1px solid #E5E5E5;
          padding: 5px;
          margin: 5px 0;
        }
        table#modal_attributeTable thead{
            background:#fff
        }
        #modal_attributeTable th {
          text-align: left;
          font-size:14px;
          line-height:22px;
          font-weight:700;
          font-family:'Roboto', 'Open Sans', Arial, sans-serif
        }
        #modal_attributeTable tr {
            border-top: 1px solid #E5E5E5;
        }
        table#modal_attributeTable td{
            font-weight:400;
            font-size:14px;
            line-height:22px;
            font-family:'Roboto', 'Open Sans', Arial, sans-serif
        }
        #modal_targetAccount ul li label{
          margin-left: 10px;
          font-weight: 400;
          font-style: normal;
        }
        #modal_duration {
          margin-left: 20px;
        }
        #modal_createdBy, #modal_editedBy,
        #modal_originatingIncident, #modal_originatingId {
          margin-left: 20px;
          margin-top: 1px;
          font-weight: 400;
          font-style: normal
        }
        #modal_targetAccount ul {
          display: flex;
          padding: 0;
          margin: 0 20px;
        }
        #modal_targetAccount li {
          margin-left: 25px;
          margin-right: 10px;
        }
        #suppressionModal .modal-footer {
            border: none;
        }
        #modal_attributeTable th,#modal_attributeTable td {
            padding-left: 10px;
        }
        #modal_criteria_value {
            overflow-wrap: anywhere;
            width: 65%;
        }
        #modal_criteria_name {
            width: 20%;
        }
        #modal_criteria_operator {
            width: 15%;
        }
        #modal_attributeTable tr {
            height: 30px;
            border-top: 1px solid gray;
        }
        .modal-header {
          border-radius: 4px;
        }
        .modal-backdrop.in {
          background: rgba(54, 62, 78, 0.7);
          opacity: .7;
        }
        .modalTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;
        }
        .modalTitle button {
            margin: 0;
            width: 9px;
            height: 21px;
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #001D30;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        .subText label {
        margin: 5px 0 0 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #626D7A;
        height: 45px;
        }
        .modalTitle h2 {
          margin: 0;
          height: 26px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #001D30;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
        .subText h3 {
          margin: 0;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #626D7A;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
        #timeTable {
            padding: 0;
        }
        .modal-body::-webkit-scrollbar {
            background: white;
        }
        .modal-body::-webkit-scrollbar-track {
            background: white;
        }
        .modal-body::-webkit-scrollbar-thumb {
            position: absolute;
        width: 8px;
        height: 211px;
        left: 512px;
        top: 4px;
        background: #757575;
        mix-blend-mode: normal;
        opacity: 0.8;
        border-radius: 4px;
        flex: none;
        order: 1;
        flex-grow: 0;
        z-index: 1;
        }
        div#timeTable h4 {
        margin-bottom: 5px;margin-top: 15px;
        height: 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #001D30;
        flex: none;
        order: 0;
        flex-grow: 0;
        }
        .modal_spacer {
    width: 100%;
    border-top: solid 1px #c2c9c9;
    margin: 0;
}
div#suppression_rule_modal {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: absolute;
    left: 200px;
    top: 55px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}
#suppression_rule_modal .modal-body {
padding: 5px 24px;
}
      </style>`
    $('head').append(cssBody)
    $(modalHTML).insertBefore(`#${targetId}`)
    if(deleteLink){
      const incidentSuppression = window.rocketcyber.incidentSuppression
      $('.delete_rule_button').show()
      $('.close_button').html('Cancel')
      incidentSuppression.deleteRuleButton(account, payload?.ruleId)
    }
  },
  'createModalTable':function (criteriaList) {
    //create rows
    if(!Array.isArray(criteriaList)){
      return
    }
    let rowsHTML = ''
    criteriaList.forEach(criteria=>{
      rowsHTML+= `<tr>
        <td id="modal_criteria_name">${criteria?.displayName}</td>
        <td id="modal_criteria_operator">${criteria?.operatorName}</td>
        <td id="modal_criteria_value">${criteria?.value}</td>
      </tr>`
    })
    const tableHTML = `<table className="table" data-controller="checkbox-selectable" id="modal_attributeTable">
      <thead className="bg-transparent">
      <tr>
        <th>Criteria</th>
        <th>Operator</th>
        <th className="customer">Value</th>
      </tr>
      </thead>
      <tbody>
        ${rowsHTML}
      </tbody>
    </table>`
    return tableHTML
  },
  'createModalAccountList':function(targetedAccounts){
    if(!Array.isArray(targetedAccounts)){
      return
    }
    const accounts = targetedAccounts.map(account=>`<li value="account">${account.name}</li>`)
    const accountCount = targetedAccounts.length
    const list = `<div id="modal_targetAccount">
      <h4>Targeted account: ${accountCount} Total</h4>
      <ul>
        ${accounts.join('')}
      </ul>
    </div>`
    return list
  },
  'deleteRuleButton': function(account, ruleId){
    const incidentSuppression = window.rocketcyber.incidentSuppression
    $('.delete_rule_button').on('click', function(){
      incidentSuppression.deleteRuleCall(account, ruleId)
    })
  },
  'deleteRuleCall': function(accountId, ruleId){
    const url = `/accounts/${accountId}/suppressions/${ruleId}`
    const incidentSuppression = window.rocketcyber.incidentSuppression
    $.ajax({
      type: 'DELETE',
      url: url,
      success: () => {
        // delete success popup
        incidentSuppression.deleteSuccessPopup()
      },
      error: () => {
        // delete failed popup
        incidentSuppression.deleteFailedPopup()
      }
    })
  },
  'deleteSuccessPopup': function(){
    const html=`
                    <div id="generateReportWindow" class="downloadResponse">
                        <p>Rule successfully deleted.</p>
                      <button class="closebtn" onclick="location.reload(); $('#generateReportWindow').remove()"> OK </button>
                    <style>
                    div#generateReportWindow {
                        position: fixed;
                        width: 400px;
                        margin-left: -200px;
                        margin-top: -80px;
                        height: 160px;
                        left: 50%;
                        display: block;
                        z-index: 1000;
                        border: 1px #000 solid;
                        top: 47%;
                        background: #5a6667;
                        color: #fff;
                        border-radius: 5px;
                        padding: 13px;
                    }

                    button.closebtn {
                        margin: 0 44%;
                        border: 2px #fff solid;
                        padding: 0px 10px;
                        border-radius: 5px;
                    }

                    #generateReportWindow p {
                        padding: 0 5px;
                        margin: 15px auto;
                    }
                    </style>
                </div>
                `
    $('.text-right button').prop('disabled', true)
    $('.modal').prepend(html)
  },
  'deleteFailedPopup': function(){
    const html=`
                    <div id="generateReportWindow" class="downloadResponse">
                        <p>Rule failed to delete</p>
                        <p>If you continue to have problems, please contact technical support.</p>
                      <button class="closebtn" onclick="window.rocketcyber.incidentSuppression.closeFailedPopup()"> OK </button>
                    <style>
                    div#generateReportWindow {
                        position: fixed;
                        width: 400px;
                        margin-left: -200px;
                        margin-top: -80px;
                        height: 160px;
                        left: 50%;
                        display: block;
                        z-index: 1000;
                        border: 1px #000 solid;
                        top: 47%;
                        background: #5a6667;
                        color: #fff;
                        border-radius: 5px;
                        padding: 13px;
                    }

                    button.closebtn {
                        margin: 0 44%;
                        border: 2px #fff solid;
                        padding: 0px 10px;
                        border-radius: 5px;
                    }

                    #generateReportWindow p {
                        padding: 0 5px;
                        margin: 15px auto;
                    }
                    </style>
                </div>
                `
    $('.text-right button').prop('disabled', true)
    $('.modal').prepend(html)
  },
  'closeFailedPopup':function (){
    $('#generateReportWindow').remove()
    $('.text-right button').prop('disabled', false)
  }
}
