import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    toastr.options= {
      progressBar: true,
      positionClass: 'toast-bottom-right'
    };
    this.check_status();
  }

  dataset() {
    return this.scope.element.dataset;
  }

  jobId() {
    return this.dataset().jobid;
  }

  accountId() {
    return this.dataset().accountid;
  }

  destinationElement() {
    return this.scope.element;
  }

  check_status() {
    this.refreshInterval = setInterval((job_id, accountId) => {
      $.ajax({
        type: "get",
        url: `/accounts/${accountId}/job_status`,
        data: { job_id: job_id },
        success: (data) => {
		console.log(data);
    	  toastr.info(data.message);

	  if(!["queued", "working"].includes(data.status)) {
	    clearInterval(this.refreshInterval);
	    this.destinationElement().remove();
	  }
        }
      });
    }, 5000, this.jobId(), this.accountId());
  }
}
