// app/javascripts/controllers/clipboard_controller.js
/*global toastr*/
import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.formTarget.addEventListener('submit', this.enableAllCheckboxes.bind(this))
  }

  toggleDeviceRemediationFields(event) {
    Array.from(document.getElementsByClassName('device-remediate')).forEach(element => {
      element.disabled = !event.target.checked;
    })

  }

  toggleMicrosoftRemediationFields(event) {
    Array.from(document.getElementsByClassName('ms-device-remediate')).forEach(element => {
      element.disabled = !event.target.checked;
    })
  }

  enableAllCheckboxes(event) {
    Array.from(this.formTarget.querySelectorAll('input[type="checkbox"]')).forEach(element => {
      element.disabled = false;
    })
  }
}
