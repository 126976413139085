/* globals rocketcyber,jQuery */
const shouldLoadDefenderManagerPage = () => {
  const parsedPath = window.location.pathname.split('/')
  return parsedPath.length === 4 && parsedPath[1] === 'accounts' && parsedPath[3] === 'defender'
}

window.rocketcyber.deviceHeartbeatService.functions.onLoadDefenderManagerPage = (function () {
  const deviceConnectivity = rocketcyber.enumerators.deviceConnectivity
  const populateDevices = (dataMap) => {
    const details = dataMap.reduce((acc, device) => {
      acc[device.deviceId] = device
      return acc
    }, {})
    rocketcyber.deviceIdMap = details
    jQuery('tr').each(function () {
      const indicatorSelectorText = 'div.indicator';
      const deviceId = jQuery(this).attr('data-device-status-id')
      const currentDevice = rocketcyber.deviceIdMap[deviceId]
      if (!currentDevice) {
        jQuery(indicatorSelectorText, this).removeClass().addClass('indicator unknown')
        return
      }
      let indicator
      if (currentDevice.isolated) {
        indicator = 'isolated'
      } else {
        switch (currentDevice.connectivity) {
          case deviceConnectivity.OFFLINE:
            indicator = 'offline'
            break
          case deviceConnectivity.ONLINE:
            indicator = 'online'
            break
          default:
            indicator = 'unknown'
        }
      }
      const lastActiveDate = new Date(currentDevice.lastConnected)
      jQuery(indicatorSelectorText, this)
        .removeClass()
        .addClass(`indicator ${indicator}`)
        .attr('title', `Last Seen: ${lastActiveDate.toLocaleString()}`)
    })
  }

  const deviceDetails = async () => {
    let devices = []
    jQuery('[data-device-status-id]').each(function() {
      const deviceId = jQuery(this).attr('data-device-status-id')
      if (deviceId) {
        devices.push(deviceId)
      }
    })
    const url = `${rocketcyber.deviceHeartbeatService.getUrl(document.location.hostname)}/api/devices/search/deviceId`
    const body = {
      'deviceIds': devices
    }
    const data = await getData(url, body)
    rocketcyber.dataMap = data || []
    populateDevices(rocketcyber.dataMap)
  }

  const getData = async (url, body) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${rocketcyber.deviceHeartbeatService.getToken(document.location.hostname)}`)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
    const res = await fetch(url, requestOptions)
    return res.json()
  }
  return deviceDetails
})()

document.addEventListener('turbolinks:load', function () {
  if (shouldLoadDefenderManagerPage()) {
    window.rocketcyber.deviceHeartbeatService.functions.onLoadDefenderManagerPage()
  }
})
