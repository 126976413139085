if (!window.rocketCyber) {
  window.rocketCyber = {}
}
window.rocketcyber.deviceSearch = {
  'payload': '',
  'filterPanelHtml': `
       <div class="panel panel-default search-panel"  data-controller="toggler checkbox-selectable">
          <div id="searchTypeHeader" class="no-height border-bottom-0">
          <div class="collapse in" id="searchContent">
             <div id="searchInput" class="border-bottom-0 filter-dropdown">
                <ul class="options center relative list-unstyled">
                   <li>
                      <form action="/devices" class="fancy-form" method="get">
                         <div class="form-inline">
                            <div id="filterSelectGroup" class="form-group margin-right-6">
                               <div class="input-group">
                                  <select id="filterSelect" class="form-control nomargin filter_select" name="filter" placeholder="Search" type="select" value="" onChange="window.rocketcyber.deviceSearch.selectChange(this)">
                                     <option>Choose a Filter</option>
                                  </select>
                               </div>
                            </div>
                         </div>
                      </form>
                   </li>
                </ul>
             </div>
             <div class="filter_search">
             <div class="filter_panel">
                <div class="filter_container active_filters" id="advanceFilter">
                <div class="filter_bubbles_container">
                <span id="containerPlaceholder">Active Filters</span>
                </div>
                </div>
          </div>
          <div class="search_panel">
                <a class="search_panel_button btn btn-secondary" onclick="window.rocketcyber.deviceSearch.submitSearch()">Search</a>
             </div>
             </div>
            </div>
            <div class="filterAndSortInfo"><div class="checkboxResolve"><div id="actionContainer"></div></div></div>
       </div>
  `,
  'filterCss': `<style>
        #searchInput {
      padding-left: 15px;
      height: auto;
      min-height: 51px;
    }
    #searchContent {
      border: none;
    }
    .panel.panel-default.search-panel {
        min-height: 4em;
        margin: 0;
        color: #676a6c;
        box-shadow: none;
    }
    ul.options.center.relative.list-unstyled {
        margin: auto;
        position: absolute;
    }
    .filter_panel {
        width: 100%;
        border: 0;
        min-height: 34px;
        display: inline-block;
        margin-bottom: 1px;
        padding-bottom: 0;
        margin-right: 12px;
    }
    #containerPlaceholder {
      display: none;
    }
    .active_filters {
      margin: 0;
    }
    .filter-dropdown {
        display: inline-flex;
        width: 800px;
    }
    .filterAndSortInfo {
      display: flex;
      margin: 10px 16px 0;
      align-items: baseline;
    }
    .checkboxResolve {
    display: flex;
    align-items: baseline;
    }
    .checkbox-count {
    margin: 0 16px 0 0 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* text/primary */
    color: #999999;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    border-right: 1px solid #626d7a;
    padding-right: 16px;
}

a.resolve_button {
    margin: 0 0 0 16px;
    align-items: baseline;
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
/* identical to box height, or 157% */
/* text/primary */
    color: #999999;
/* Inside auto layout */
    flex: none;
    flex-grow: 0;
    padding: 0;
}
    @media only screen and (min-width: 1920px) {
        .filter-dropdown {
            display: inline-block;
        }
    }
    .sort_panel {
        width: 15%;
        border: #ddd 2px solid;
        border-radius: 5px;
        min-height: 58px;
        padding-bottom: 10px;
        margin-left: 15px;
        margin-top: 20px;
    }
    label.filter_label {
        margin-left: 10px;
        font-size: 18px;
        position: relative;
        padding-top: 20px;
        padding-left: 5px;
        margin-bottom: -5px;
    }
    option {
        font-size: 14px;
        min-height: 1em !important;
    }
    .no-height.border-bottom-0.searchHeader {
        margin-bottom: -5px;
        padding-bottom: 0;
    }
    .search_panel_button.btn.btn-secondary {
        z-index: 2;
    }
    .filter_bubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 6px;
    height: 28px;
    
    /* Kaseya global/gray/08 */
    
    background: #EBEBEB;
    border-radius: 4px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    }
    
    .remove_filter {
        text-align: left;
        margin-top: 4px 8px 4px 0;
        background-color: #EBEBEB !important;
    }
    .remove_filter i.fa-solid {
        color: #000;
    }
    .filter_line {
    
height: 20px;

/* Label2 Tables/primary/Medium */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.filter_line span {
    margin-right: 5px;
}
    .sortBy {
        position: relative;
        width: 300px;
        top: 10px;
        margin-left: 10px;
    }
    input.sortBy_checkbox {
        margin-left: 10px;
        margin-right: 10px;
    }
    select.sortBy_select {
        height: 30px;
        font-size: 13px !important;
        margin: 0;
        padding: 5px 0;
    }
    select.form-control.nomargin.filter_select {
        min-width: 198px;
        height: 34px;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .search_panel {
    width: 76px;
    }
    button.search_panel_button,a.search_panel_button {
        margin: 0;
    }
    .tableCaret {
        left: 5px;
        position: relative;
        font-size: 14px;
    }
    .row.pagination {
        height: 30px;
    }
    .paginationRow {
        display: inline-block;
        width: 100%;
    }
    .paginationRow .arrow {
        display: inline-flex;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .arrow a {
        margin: 10px 15px;
    }
    .paginationInputContainer input[type="number"] {
        width: 40px;
        text-align: center;
    }
    td .fa.fa-circle {
        margin-left: 10px;
    }
    .download_panel {
      border: 0;
      margin: 0;
      padding: 0;
      position: revert;
      display: inline-flex;
    }
    .fileTypeDiv {
      display: flex;
      flex-direction: row;
      position: inherit;
      /* Field Placeholder/Regular */
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */
      /* text/primary */
      color: #001D30;
      /* Inside auto layout */
      order: 0;
      flex-grow: 0;
      }
    #downloadBTN a, #downloadBTN i {
    align-items: center;
    display: inline-flex;
    width: 16px;
    height: 21px;
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    color: #001D30;
    /* Inside auto layout */
    order: 0;
    flex-grow: 0;
    }

    input.fileType {
        display: list-item;
        position: relative;
        left: -15px;
        top: 13px;
        line-height: normal;
        margin-top: -5px;
    }
    a#helper {
        font-size: 30px !important;
        color: #4b5354;
    }
    
    .toolTipDiv {
        width: 200px;
        position: absolute;
        background: #fff;
        border: 2px #4b5354 solid;
        padding: 0px 10px;
        margin: 0px 5px;
        border-radius: 5px;
        top: 36px;
        right: 25px;
        z-index: 100;
        font-size: 12px;
    }
    .input-group-addon{
    border-left: 1px solid #5555;
    text-transform: lowercase;
    }
    #error {
     color: red;
     font-weight: bold;
     display: inherit;
     margin-left: 15px;
     vertical-align: middle;
     }
     #error p {
         margin: 0;
     }
    #searchFilterInput {
    display: inline-block !important;
    }
    #overlayProtector {
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: fixed;
    background-color: #000;
    z-index: 999;
    }
    div#generateReportWindow {
        position: fixed;
        width: 337px;
        height: auto;
        left: 50%;
        display: block;
        z-index: 1000;
        border: 1px #000 solid;
        top: 47%;
        background: #5a6667;
        color: #fff;
        border-radius: 5px;
        padding: 13px;
    }
    div#actionContainer {
    display: flex;
}

div.resolveInput span{
    margin: 0 auto;
    /* Body/Regular */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    /* text/primary */
    color: #999999;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
div.resolveInput input {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
}
div.deleteInput span {
    margin: 0 auto;
    /* Label1 Buttons and Fields/secondary/Regular */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 143% */
    /* text/critical */
    color: #FFB3B3;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
div.deleteInput input {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFB3B3;
    text-decoration: none;
}
div.deleteInput input.btn.btn-link, div.resolveInput input.btn.btn-link {
    text-decoration: none;
}
div.resolveInput, div.deleteInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0 8px 0;
    gap: 8px;
    width: 83px;
    height: 24px;
    /* background/secondary
    Gray 04
    */
    border-radius: 2px;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

/*div.resolveInput:hover, div.resolveInput span:hover, div.resolveInput input:hover {*/
/*    background: #F6F7F8;*/
/*    color:#001D30;*/
/*}*/
/*div.deleteInput:hover, div.deleteInput:focus-visible {*/
/*    background: #FFD7D7*/
/*}*/
/*div.deleteInput input:hover, div.deleteInput span:hover{*/
/*    color: #D22F2D;*/
/*}*/

input.btn.btn-link {
    margin-left: 5px;
}
     
    button.closebtn {
        margin: 0 44%;
        border: 2px #fff solid;
        padding: 0px 10px;
        border-radius: 5px;
    }
    .clearAllBtn {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    margin-left: auto;
    margin-right: 13px;
    margin-bottom: auto;
    width: 14px;
    height: 21px;
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    text-align: center;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    }
    .filter_search{
    display: flex;
    margin: 10px 16px;
}
.filter_container {
    flex-wrap: wrap;
    /* margin: 0; */
    box-sizing: border-box;

/* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 8px 5px 12px;
    gap: 8px;
    /* width: 100%; */

/* background/primary

White
*/
    background: #FFFFFF;
/* border/secondary

Gray 25
*/
    border: 1px solid #CCCCCC;
    border-radius: 4px;

/* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.clearAllBtn a{
    color: #001D30;
}
    #generateReportWindow p {
        padding: 0 5px;
        overflow-wrap: break-word;
    }
    select#filterSelect {box-sizing: border-box;

/* Auto layout */display: flex;flex-direction: row;align-items: center;padding: 8px;gap: 12px;width: 202px;height: 36px;

/* background/primary

White
*/background: #FFFFFF;
/* border/secondary

Gray 25
*/border: 1px solid #CCCCCC;border-radius: 4px;

/* Inside auto layout */flex: none;order: 1;align-self: stretch;flex-grow: 0;}

input#searchFilterInput {
    box-sizing: border-box;
/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
padding: 8px;
gap: 12px;
width: 202px;
height: 32px;
/* background/primary
White
*/
background: #FFFFFF;
/* border/secondary
Gray 25
*/
border: 1px solid #CCCCCC;
border-radius: 4px;
/* Inside auto layout */
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;    
}
.sort-tree {
  margin-left: auto !important;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    float: left;
    position: relative;
}
.sort-tree p {
  margin: 0 9px 0 0;
}
.sort-tree .fa-list-tree {
    margin-right: 9px;
font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */
text-align: center;
/* text/primary */
color: #001D30;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}
.sort-tree .tableCaret {
    margin-right: 9.5px;
    margin-left: 4px;
    color: #001D30;
}
.sort-tree .fa-arrow-right {
font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */
text-align: center;
/* text/primary */
color: #001D30;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
margin: 0 9.5px;
}
.sort-tree span {
    text-transform: capitalize;
    height: 22px;
    /* Body/Regular */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    /* text/primary */
    color: #001D30;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.fa-stack {
  text-align: center;
  height: 14px;
  top: -1px;
}

.fa-stack .fa-caret-down {
  position: absolute;
  bottom: 0;
  width: 8px;
  font-size: 10px;
}
.downloadBTN {
  height: 30px;
}
#downloadTooltip {
  right: 0;
}
.fa-stack .fa-caret-up {
  position: absolute;
  top: 0;
  width: 8px;
  font-size: 10px;
}
i.fa.fa-caret-down.tableCaret {
    bottom: -2px;
}
table th input#check_all {
    width: 14px;
    height: 14px;
}

table th {
    color: #626D7A;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
}

table th a {
    color: #626D7A !important;
    display: contents;
}

table tr a {
    color: #02892B;
}
span.badge {
    color: #383838;
    background: #ECECEC;
}

.fa-caret-up.tableCaret.grey, .fa-caret-down.tableCaret.grey {
    color: #CCCCCC !important;
}
.input-group.input-daterange {
    display: inline-flex;
}
div#datePickerGap {
    width: 24px;
    height: 24px;
    margin: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

i.fa-regular.fa-calendar {
    margin-left: -34px;
    z-index: 2;
    position: relative;
    height: 24px;
    width: 24px;
    text-align: center;
    margin-top: 10px;
}
.filter_bubbles_container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    width: 95%;
}
input#start_date,input#end_date {
    text-align: left;
}
    </style>`,
  'init': function (payload) {
    if (!$ || !jQuery) {
      console.log('missing jQuery')
      return
    }
    const deviceSearch = window.rocketcyber.deviceSearch
    deviceSearch.payload = payload
    const FilterPanelHtml = deviceSearch.filterPanelHtml
    const FilterPanelCss = deviceSearch.filterCss
    const schema = payload?.configs?.schema
    const helper = $('#helper')
    deviceSearch.payload = payload
    $('head').append(FilterPanelCss)
    $('.app-index-wrapper .col-xs-12:eq(1)').prepend(FilterPanelHtml)
    jQuery('.sortBy').hide()
    /*
      mouseOver for disabled anchor
      */
    helper.mouseenter(function () {
      $('.toolTipDiv').show()
    })
    helper.mouseleave(function () {
      $('.toolTipDiv').hide()
    })

    if (!schema) {
      return
    }
    schema.forEach(function (item) {
      const option = `
        <option 
            value="${item?.name}"
            name="${item?.displayName}" 
            optionType="${item?.dataType}"
            providedValues="${item?.values ? item.values : ''}">
          ${item?.displayName?.replace('Customer', 'Organization')}
        </option>`
      $('#filterSelect').append(option)
    })
    if (payload?.totalCount != 0) {
      this.insertPagination(payload)
      $('.panel-default.border-bottom-0').removeClass('border-bottom-0')
      // Show border bottom on the table container when no data is shown in the table
    }
    this.prePopulateFilter(schema)
    deviceSearch.checkboxSelectedCount()
    deviceSearch.prepopulateColumnSort()
    deviceSearch.downloadPanel()
    deviceSearch.removeAllFilters()
    deviceSearch.actionContainerBuilder()
  },
  'actionContainerBuilder': function () {
    const path = document.location.pathname.substring(document.location.pathname.lastIndexOf('/') + 1)
    const resolveBtn = '.resolveInput input[value="Resolve"]'
    const deleteBtn = '.deleteInput input[value="Delete"]'
    switch(path) {
    case 'devices':
      $('#actionContainer').append($('.deleteInput'))
      $('.pull-right.text-right').hide()
      $(deleteBtn).addClass('disabled')
      $(deleteBtn).on('click', function(){
        $('.deleteInput span, .deleteInput input[value="Delete"]').css('color','#D22F2D')
        $('.deleteInput').css('background','#FFB3B3')
      })
      break
    case 'incidents':
      $('#actionContainer').append($('.resolveInput, .deleteInput'))
      $('.pull-right.text-right.pt-15').hide()
      $('.resolveInput input[value="Resolve"],.deleteInput input[value="Delete"]').addClass('disabled')
      $(resolveBtn).on('click', function(){
        $('.resolveInput span, .resolveInput input[value="Resolve"]').css('color','#001D30')
        $('.resolveInput').css('background','#F6F7F8')
      })
      $(deleteBtn).on('click', function(){
        $('.deleteInput span, .deleteInput input[value="Delete"]').css('color','#D22F2D')
        $('.deleteInput').css('background','#FFB3B3')
      })
      break
    case 'triage':
      $('#actionContainer').append($('.whitelistBtn, .incidentBtn, .logicBtn, .archiveBtn'))
      $('.pull-right.text-right').hide()
      $('.whitelistBtn input[value="Add to Whitelist"], .incidentBtn input[value="Add to Incident"], .logicBtn input[value="Add a Logic Rule"], .archiveBtn input[value="Archive"]').addClass('disabled')
      $(resolveBtn).on('click', function(){
        $('.whitelistBtn span, .whitelistBtn input[value="Add to Whitelist"]').css('color','#001D30')
        $('.whitelistBtn').css('background','#F6F7F8')

        $('.incidentBtn span, .incidentBtn input[value="Add to Incident"]').css('color','#001D30')
        $('.incidentBtn').css('background','#F6F7F8')

        $('.logicBtn span, .logicBtn input[value="Add a Logic Rule"]').css('color','#001D30')
        $('.logicBtn').css('background','#F6F7F8')

        $('.archiveBtn span, .archiveBtn input[value="Archive"]').css('color','#001D30')
        $('.archiveBtn').css('background','#F6F7F8')
      })
      break
    }
  },
  'selectChange': function (element) {
    const payload = window.rocketcyber.deviceSearch.payload
    jQuery('.sortBy').hide()
    $('.sortBy_checkbox').prop('checked', false)

    $('#filterInput,#filterAddButton').remove()
    if (!element?.selectedIndex) {
      return
    }
    const selectedOption = $('#filterSelect option')[element.selectedIndex]
    const deviceSearch = window.rocketcyber.deviceSearch
    const optionType = selectedOption?.attributes?.optiontype.nodeValue
    const providedValues = selectedOption?.attributes?.providedValues.nodeValue

    // Determine type of input to show to insert
    const addButton = `
    <div id="filterAddButton" class="form-group">
        <div class="input-group">
            <a class="btn btn-tertiary" title="Add filter" id="addFilterBtn" optionType=${optionType} >
                + Add Filter
            </a>
        </div>
        <span id="error"></span>
    </div>`
    let filterInput = ''
    if (optionType === 'string' || optionType === 'customDetailsSearch') {
      if (providedValues) {
        const optionsArray = providedValues.split(',')
        const options = []
        optionsArray.forEach(function (option) {
          options.push(`<option value="${option}">${option}</option>`)
        })
        filterInput = `<div class="input-group">
                <select class="form-control nomargin filter_select">
                </option>
                ${options.toString().replace(',', '')}
                </select>
            </div>`
      } else {
        filterInput = `
            <div class="input-group">
                <input class="form-control nomargin" id="searchFilterInput" name="filter" placeholder="Search" type="search" value="" maxlength="100"/>
            </div>`
      }
    } else if (optionType === 'iso date') {
      filterInput = `
          <div class="input-group input-daterange">
              <div id="startDateDiv">
                   <input autocomplete="off" class="form-control" data-date-format="mm/dd/yyyy" maxlength="10" id="start_date" name="start_date" type="text" value=""
                          oninput="this.value = this.value.replace(/[^0-9/]/g, '');">
                    <i class="fa-regular fa-calendar"></i>
              </div>
              <div id="datePickerGap"><i class="fa-regular fa-arrow-right"></i></div>
              <div id="endDateDiv">
                  <input autocomplete="off" class="form-control" data-date-format="mm/dd/yyyy" maxlength="10" id="end_date" name="end_date" type="text" value=""
                         oninput="this.value = this.value.replace(/[^0-9/]/g, '');">
                  <i class="fa-regular fa-calendar"></i>
              </div>            
          </div>
    `
    } else if (optionType === 'int') {
      filterInput = `
          <div class="input-group">
              <input class="form-control nomargin numberInput" id="searchFilterInput" name="filter" placeholder="####" type="text" min="0" maxlength="10" value=""/>
          </div>
            `
    } else if(optionType === 'bigint') {
      filterInput = `
      <div class="input-group">
        <input class="form-control nomargin numberInput" id="searchFilterInput" name="filter" placeholder="####" type="text" min="0" maxlength="16" value=""/>
      </div>
        `
    }
    const filterInputParent = `<div id="filterInput" class="form-group margin-right-6">${filterInput}</div>${addButton}`
    $(filterInputParent).insertAfter('#filterSelectGroup')

    if (optionType == 'iso date') {
      $('.input-daterange input').datepicker({
        templates: {
          leftArrow: '<i class="fa-solid fa-chevron-left"></i>',
          rightArrow: '<i class="fa-solid fa-chevron-right"></i>',
        },
        todayHighlight: true,
        orientation: 'bottom auto',
        maxViewMode: 2
      });

      // Propagate Date selected to the other datepicker when the other is blank
      $('#startDateDiv input').datepicker().on('changeDate', function() {
        if (!$('#endDateDiv input').datepicker('getDate')) {
          $('#endDateDiv input').datepicker('setDate', $('#startDateDiv input').val())
        }
      })

      $('#endDateDiv input').datepicker().on('changeDate', function() {
        if (!$('#startDateDiv input').datepicker('getDate')) {
          $('#startDateDiv input').datepicker('setDate', $('#endDateDiv input').val())
        }
      })
    }

    jQuery('.sortBy').show()
    //Button click
    $('#addFilterBtn').on('click', function(){
      if (optionType !== 'int' && optionType !== 'bigint' && optionType !== 'string') {
        window.rocketcyber.deviceSearch.addFilter()
      } else {
        deviceSearch.inputValidation(optionType)
        deviceSearch.filterErrorHandler(optionType)
        if (!$('#error p').text()) {
          deviceSearch.addFilter()
          deviceSearch.removeAllFilters()
        }
      }
    })

    //Enter pressed
    $('#searchFilterInput').on('keypress', function (event) {
      const keyPressed = event.keyCode || event.which
      if (keyPressed === 13) {
        $('#addFilterBtn').click()
        event.preventDefault()
        return false
      }
    })
  },
  'addFilter': function () {
    //Add filter bubbles
    //Filter type of filter type and pull the value correctly.
    $('#containerPlaceholder').hide()
    const payload = window.rocketcyber.deviceSearch.payload
    const schema = payload?.configs?.schema
    const deviceSearch = window.rocketcyber.deviceSearch
    const filteredArray = schema.filter(item => item?.default)
    const filterType = $('#filterSelect').find(':selected')
    const optionValue = filterType.val()
    const optionName = filterType.attr('name')
    const optionType = filterType.attr('optionType')
    const providedvalues = filterType.attr('providedvalues')
    const sortCheck = $('.sortBy_checkbox').is(':checked')
    const sortValue = $('.sortBy_select').val()
    let queryValue = ''
    if (!filterType || !optionType) {
      return
    }
    let filterValue = ''
    if (optionType === 'string' && providedvalues) {
      const selected = $('#filterInput').find(':selected').val()
      filterValue = selected
      queryValue = `${optionValue}=${selected}`
    } else if (optionType === 'iso date') {
      const startDate = $('#start_date').val()
      const endDate = $('#end_date').val()
      if (!startDate) {
        return
      }
      let isoStart = new Date(startDate)
      let isoEnd = new Date(endDate)
      //todo add date validation
      isoStart = isoStart.toISOString()
      isoEnd = isoEnd.toISOString()
      if (startDate !== endDate) {
        queryValue = `${optionValue}=${isoStart}|${isoEnd}`
        filterValue = `${startDate} thru ${endDate}`
      } else {
        queryValue = `${optionValue}=${isoStart}|`
        filterValue = `${startDate}`
      }
    } else {
      filterValue = $('#filterInput input').val()
      queryValue = `${optionValue}=${filterValue}`
    }

    if (!filterValue) {
      return
    }
    let sortHtml = ''
    if (sortCheck && sortValue) {
      queryValue += `&sort=${optionValue}:${sortValue}`
      //add sort to Query
      sortHtml = this.sortHtml(sortValue)
    }

    const filterBubble = this.addFilterBubble(queryValue, optionName, filterValue, sortHtml)
    $('.filter_bubbles_container').append(filterBubble)
    $('#downloadBTN').addClass('disabled')
    deviceSearch.downloadTooltip()

    //Clear result
    $('#filterInput input').each(function (input) {
      this.value = ''
    })
    $('#filterInput select').val('')
  },
  'addFilterBubble': function (queryValue, filterType, filterValue, sortHtml) {
    if(filterType === 'stopDate'){
      filterType = 'Expires On'
    }

    filterType = filterType.replace('Customer', 'Organization')

    const bubbleHtml = `
    <div class="filter_bubble" style="" queryValue="${queryValue}">
       <div style="position: relative;">
          <div class="filter_line">
             <div class="filter_field">${filterType}</div>
             <span>:</span>
             <div class="filter_value">${filterValue}</div>
          </div>
       </div>
       <a class="remove_filter_anchor" title="Remove filter" onclick="window.rocketcyber.deviceSearch.removeFilter(this);">
          <div class="remove_filter"><i class="fa-solid fa-xmark nomargin nopadding"></i></div>
       </a>
    </div>
    `
    return bubbleHtml
  },
  'sortHtml': function (sortValue) {
    const sortHtml = `
      <div class="sort_label">Sort By</div>
      <span>:</span>
      <div class="sort_value">${sortValue}</div>
    `
    return sortHtml
  },
  'removeFilter': function (item) {
    const devices = window.rocketcyber.deviceSearch
    $('#downloadBTN').addClass('disabled')
    $(item).parent('.filter_bubble').remove()
    devices.removeAllFilters()
    devices.downloadTooltip()
  },
  'removeAllFilters': function () {
    const removeFiltersHTML = '<div class="clearAllBtn"><a class="removeFilters"><i class="fa-regular fa-circle-xmark"></i></a></div>'
    const devices = window.rocketcyber.deviceSearch
    $('.clearAllBtn').remove()
    if($('.filter_bubble').length > 0){
      $('.filter_container').append(removeFiltersHTML)
    } else {
      $('.clearAllBtn').remove()
      $('#containerPlaceholder').show()
    }

    $('.removeFilters').on('click', function(){
      $('.filter_bubble').remove()
      $('.clearAllBtn').remove()
      $('#containerPlaceholder').show()
    })
  },
  'submitSearch': function () {
    const filterArray = $('#advanceFilter .filter_bubble').toArray()
    const payload = window.rocketcyber.deviceSearch.payload
    if (!payload?.accountId) {
      if (!filterArray?.length || filterArray.length === 0) {
        return
      }
    }
    let paramArray = []
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    filterArray.forEach(function (filter) {
      paramArray.push(filter?.attributes?.queryvalue?.value)
    })
    if (payload?.accountId) {
      paramArray.push(`accountId=${payload?.accountId}`)
    }
    if (params?.appId || params?.app_id) {
      const appId = params?.appId || params?.app_id
      paramArray.push(`appId=${appId}&app_id=${appId}`)
    }
    const urlQueryString = new URLSearchParams(window.location.search)
    let sortArray = urlQueryString.getAll('sort')
    //Simple is selected, generate sort by configs
    const query = this.generateQueryParams(paramArray, sortArray)
    document.location.search = query
  },
  'downloadPanel': function () {
    const panelHTML = `
      <div class="download_panel">
                <div class="fileTypeDiv">
                <label class="radio">
                    <input class="radio" type="radio" id="CSV" class="fileType" name="file_type" value="CSV" checked><i></i>CSV</label>
                    <label class="radio">
                    <input class="radio" type="radio" id="JSON" class="fileType" name="file_type" value="JSON"><i></i>JSON</label></div>
                    <div class="downloadBTN">
                    <a id="downloadBTN" class="btn btn-primary" onclick="window.rocketcyber.deviceSearch.downloadSearchResults()">
                    <i class="fa-solid fa-arrow-down-to-line"></i>Download
                    </a>
                    <span class="downloadTooltip" data-placement="bottom" data-toggle="tooltip" title="" data-original-title="Download"></span>
                    </div>
                    </div>
                </div>
    `
    $('.table-header').append(panelHTML)

  },
  'downloadTooltip': function () {
    $('.downloadBTN').unbind('mouseover')
    $('.downloadBTN').unbind('mouseout')
    if ($('#downloadBTN').hasClass('disabled')){
      $('.downloadBTN').on('mouseover', function(){
        $('#downloadTooltip').remove()
        $('<div class="tooltip fade bottom in" role="tooltip" id="downloadTooltip" style="display: block;">'+
          '<div class="tooltip-inner">' +
          '<div class="tooltip-headline">Download</div>' +
          '<div class="tooltip-body">Download is disabled if applied filters is changed. Please click search or refresh page to be able to download filtered report</div> </div></div>').insertAfter('.downloadTooltip')
      })
      $('.downloadBTN').on('mouseout',function(){
        $('#downloadTooltip').remove()
      })
    }
  },
  'downloadSearchResults': function (e) {
    $('#downloadBTN').addClass('disabled')
    const filterArray = $('#advanceFilter .filter_bubble').toArray()
    const payload = window.rocketcyber.deviceSearch.payload
    if (!payload?.accountId) {
      if (!filterArray?.length || filterArray.length === 0) {
        return
      }
    }
    let paramArray = []
    let sortArray = []
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    filterArray.forEach(function (filter) {
      paramArray.push(filter?.attributes?.queryvalue?.value)
    })
    if (payload?.accountId) {
      paramArray.push(`accountId=${payload?.accountId}`)
    }
    if (params?.appId || params?.app_id) {
      const appId = params?.appId || params?.app_id
      paramArray.push(`appId=${appId}`)
    }
    const query = this.generateQueryParams(paramArray, sortArray)
    // Make request to report API
    // const myHeaders = new Headers();
    const customResults = $('#customResults')
    const userID = customResults.attr('data-user')
    const email = customResults.attr('data-email')
    const key = customResults.attr('data-source')
    const url = customResults.attr('data-customerapi')
    const token = customResults.attr('data-key')
    let itemType
    const path = document.location.pathname.substring(document.location.pathname.lastIndexOf('/') + 1)
    switch (path) {
    case 'devices':
      itemType = 'agents'
      break
    case 'firewalls':
      itemType = 'firewalls'
      break
    case 'incidents':
      itemType = 'incidents'
      break
    case 'triage':
      itemType = 'events'
      break
    case 'suppressions':
      itemType = 'suppression'
      break
    }
    const fileType = $('input[name=file_type]:checked').val().toLowerCase()
    const bearer = `Bearer ${token}`

    let requestOptions = {
      method: 'GET',
      withCredentials: true,
      headers: {
        'Authorization': bearer,
        'source-key': key,
        'Content-Type': 'application/json'
      },
      redirect: 'follow'
    }
    let fetchHTMLString = `${query}&userId=${userID}&fileType=${fileType}&itemType=${itemType}`
    const urlString = `${url}/v3/reportApi`
    fetchHTMLString = '?' + fetchHTMLString.replace(/.*\?/g, '')                    // replace everything before '?'
      .split('&')                              // split by '&'
      .filter((element, paramIndex, paramArray) => paramArray.indexOf(element) === paramIndex) // filter duplicates
      .join('&')
    fetchHTMLString = urlString + fetchHTMLString
    fetch(fetchHTMLString, requestOptions)
      .then((response) => {
        let responseMessage
        if (response.status === 201) {
          responseMessage = `An email containing a link to the requested data will be sent to the following email: ${email}`
        } else {
          responseMessage = 'Failed to generate requested report'
        }
        window.rocketcyber.deviceSearch.displayDownloadMessage(responseMessage)

      })
      .catch(error => console.log('error', error))
  },
  'displayDownloadMessage': function (message) {
    const html = `
      <div id="overlayProtector"></div>
      <div id="generateReportWindow" class="downloadResponse">
          <p>${message}</p>
        <button class="closebtn" onclick="window.rocketcyber.deviceSearch.closeDownloadMessage();"> OK </button>
      </div>
    `
    $('body').prepend(html)
  },
  'closeDownloadMessage': function () {
    $('#generateReportWindow').remove()
    $('#downloadBTN').removeClass('disabled')
    $('#overlayProtector').remove()
  },
  'generateQueryParams': function (paramArray, sortArray) {
    const bottomPageSize = `pageSize=${$('#bottomPageSize').find(':selected').val() || 100}`
    const userId = `userId=${Number($('#customResults').attr('data-user'))}`
    let queryparams = `?${bottomPageSize}`
    paramArray.forEach(function (param) {
      queryparams += `&${param}`
    })
    sortArray.forEach(function (param) {
      queryparams += `&sort=${param}`
    })
    queryparams += '&config=true'
    if(isNaN(Number($('#customResults').attr('data-user'))) === false && $('#customResults').attr('data-user')){
      queryparams+= `&${userId}`
    }
    //Add Search Type
    const searchType = $('.searchType:checked').val()
    if (searchType) {
      queryparams += `&searchType=${searchType}`
    }
    //dedup query params
    queryparams = '?' + queryparams.replace(/.*\?/g, '')                    // replace everything before '?'
      .split('&')                              // split by '&'
      .filter((element, paramIndex, paramArray) => paramArray.indexOf(element) === paramIndex) // filter duplicates
      .join('&')
    return queryparams
  },
  'prePopulateFilter': function (schema) {
    //Create function to validate data types in input
    const qs = location.search.substring(1)
    const queryObj = this.queryStringToJSON(qs)
    const bottomPageSize = $('#bottomPageSize')
    if (Object.keys(queryObj).length === 0) {
      return
    }
    for (const [key, value] of Object.entries(queryObj)) {
      if (key === 'pageSize') {
        bottomPageSize.val(value)
        if (!bottomPageSize.val()) {
          //Default to 20
          $('#bottomPageSize').val(20)
        }
      } else {
        //find key inside of schema to get values
        const schemaObj = this.searchArray(key, schema)
        if (schemaObj) {
          //check if there is a sort
          let displayValue = ''
          if (schemaObj.dataType === 'iso date') {
            const dates = value.split('|')
            const startDate = new Date(dates[0]).toLocaleDateString()
            const endDate = new Date(dates[1]).toLocaleDateString()
            if(endDate === 'Invalid Date'){
              displayValue = `${startDate}`
            } else {
              displayValue = `${startDate} thru ${endDate}`
            }
            // handle different date formats
          } else {
            displayValue = value
          }
          let queryValue = []
          if (Array.isArray(value)) {
            for (let index in value) {
              queryValue.push(`${key}=${value[index]}`)
            }
          } else {
            queryValue.push(`${key}=${value}`)
          }
          let sortHTML = ''
          //handle sorts
          for (let index in queryValue) {
            let queryString = queryValue[index]
            if (queryObj?.sort) {
              let sort = []
              !Array.isArray(queryObj.sort) ? sort.push(queryObj.sort) : sort = queryObj.sort
              if (sort?.length > 0) {
                for (let item in sort) {
                  const sA = sort[item].split(':')
                  if (schemaObj.name === sA[0]) {
                    sortHTML = this.sortHtml(sA[1])
                    queryString += `&sort=${sort[item]}`
                  }
                }
              }
            }
            const disVal = Array.isArray(displayValue) ? displayValue[index] : displayValue
            const filterBubble = this.addFilterBubble(queryString, schemaObj.displayName, disVal, sortHTML)
            $('.filter_bubbles_container').append(filterBubble)
          }
        }
      }
    }
  },
  'checkboxSelectedCount': function (){
    const checkboxCountHTML = '<p class="checkbox-count">0 selected</p>'
    const checkAllBox = $('#check_all')
    const triageBtns = $('.whitelistBtn span, .whitelistBtn input[value="Add to Whitelist"], .incidentBtn span, .incidentBtn input[value="Add to Incident"], .logicBtn span, .logicBtn input[value="Add a Logic Rule"], .archiveBtn span, .archiveBtn input[value="Archive"]')
    $('.checkboxResolve').prepend(checkboxCountHTML)
    if(checkAllBox.length === 0){
      $('.checkbox-count').hide()
      return
    }
    if($('table tr td input:checkbox').length === 0){
      $('#check_all').attr('disabled','disabled')
      return
    }
    $('input:checkbox').on('change',function(){
      $('.checkbox-count').html('')
      let checkboxCount = $('table tr td input:checkbox:checked').length
      if($('table tbody tr td input:checkbox:checked').length === $('table tbody tr td input:checkbox').length){
        checkboxCount = $('table tbody tr td input:checkbox:checked').length
        $('.checkbox-count').css('color', '#001D30')
        triageBtns.css('color', '#001D30')
        $('.resolveInput span, .resolveInput input[value="Resolve"]').css('color', '#001D30')
        $('.deleteInput span, .deleteInput input[value="Delete"]').css('color', '#D22F2D')
        $('.resolveInput input[value="Resolve"],.deleteInput input[value="Delete"]').removeClass('disabled')
        $('.checkbox-count').html(`${checkboxCount} selected`)
        checkAllBox.prop('checked',true)
        checkAllBox.removeClass('intermediateState')
        triageBtns.removeClass('disabled')
        return
      }
      // if($('table tr td input:checkbox:checked').length === $('table tr td input:checkbox').length){
      if(checkboxCount > 0 && $('table tr td input:checkbox:checked').length !== $('table tr td input:checkbox').length ){
        $('.checkbox-count').css('color', '#001D30')
        triageBtns.css('color', '#001D30')
        $('.resolveInput span, .resolveInput input[value="Resolve"]').css('color', '#001D30')
        $('.deleteInput span, .deleteInput input[value="Delete"]').css('color', '#D22F2D')
        $('.resolveInput input[value="Resolve"],.deleteInput input[value="Delete"]').removeClass('disabled')
        triageBtns.removeClass('disabled')
        if (checkboxCount > 1){
          checkAllBox.addClass('intermediateState')
          checkAllBox.prop('checked', true)
        } else {
          checkAllBox.removeClass('intermediateState')
          checkAllBox.prop('checked', false)
        }
        $('.checkbox-count').html(`${checkboxCount} selected`)
      } else {
        $('.checkbox-count').css('color', '#999999')
        $('.resolveInput span, .resolveInput input[value="Resolve"]').css('color', '')
        $('.deleteInput span, .deleteInput input[value="Delete"]').css('color', '')
        triageBtns.css('color', '')
        $('.checkbox-count').html('0 selected')
        checkAllBox.removeClass('intermediateState')
        checkAllBox.prop('checked', false)
        $('.resolveInput input[value="Resolve"],.deleteInput input[value="Delete"]').addClass('disabled')
        triageBtns.addClass('disabled')
      }
    })
  },
  'tableSortTree': function (sortArray){
    let sort = [...new Set(sortArray)]
    let columnName = window.rocketcyber.deviceSearch.payload.configs.columns
    if(!Array.isArray(sort) || columnName.length === 0){
      return
    }
    const sortTreeHTML = `
      <div class="sort-tree"><p><i class="fa-solid fa-list-tree"></i> Sorting Tree: </p></div>
    `
    $('.filterAndSortInfo').append(sortTreeHTML)
    if(sort.length > 0){
      let sortedHTML = ''
      for(let i = 0; i < sort.length; i++){
        let sA = sort[i].split(':')
        let sortByHTML = ''
        let sortBy = sA[1]
        let queryName = sA[0]
        let sortName = ''
        if(!columnName.filter(column => column?.property === queryName || column?.link === queryName)[0]?.name){
          sortName = queryName
        } else {
          sortName = columnName.filter(column => column?.property === queryName || column?.link === queryName)[0]?.name
        }
        if (sortBy.toLowerCase() === 'asc'){
          sortByHTML = '<i class="fa fa-caret-up tree-caret" aria-hidden="true"></i>'
        } else {
          sortByHTML = '<i class="fa fa-caret-down tree-caret" aria-hidden="true"></i>'
        }
        let arrowLength = 1 + i

        sortName = sortName.replace("Customer", "Organization")
                           .replace('accountId', 'Organization')

        if (arrowLength < sort.length) {
          sortedHTML += `<span>${sortName} ${sortByHTML}<i class="fa-regular fa-arrow-right"></i></span>`
        } else {
          sortedHTML += `<span>${sortName} ${sortByHTML}</i></span>`
        }
      }
      $('.sort-tree').append(sortedHTML).insertAfter
    } else {
      $('.sort-tree').append('<span>None</span>')
      $('.sort-tree .fa-list-tree, .sort-tree p, .sort-tree span').css('color','#999999')
    }
  },
  'searchArray': function (nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
        return myArray[i]
      }
    }
  },
  'queryStringToJSON': function (qs) {
    qs = qs || location.search.slice(1)

    let pairs = qs.split('&')
    let result = {}
    pairs.forEach(function (p) {
      let pair = p.split('=')
      let key = pair[0]
      let value = decodeURIComponent(pair[1] || '')

      if (result[key]) {
        if (Object.prototype.toString.call(result[key]) === '[object Array]') {
          result[key].push(value)
        } else {
          result[key] = [result[key], value]
        }
      } else {
        result[key] = value
      }
    })

    return JSON.parse(JSON.stringify(result))
  },
  'prepopulateColumnSort': function () {
    const devices = window.rocketcyber.deviceSearch
    let sort = []
    $('div[data-controller="search-table"] th').each(function () {
      const payload = rocketcyber.deviceSearch.payload
      const id = Number($(this).attr('data-schemaId'))
      if (!id || !payload) {
        return
      }
      const columnSchema = payload?.configs?.schema?.find(item => item.id === id)
      if (!columnSchema) {
        return
      }
      const qs = location.search.substring(1)
      const queryObj = rocketcyber.deviceSearch.queryStringToJSON(qs)
      if (Object.keys(queryObj).length === 0) {
        return
      }
      if (columnSchema) {
        this.innerHTML = `<a id="${id}" onClick="window.rocketcyber.deviceSearch.columnSort(${id})">${this.innerHTML}
        <span class="fa fa-stack"><i class="fa fa-caret-up tableCaret grey"></i><i class="fa fa-caret-down tableCaret grey"></i></span></a>`
      }
      if (queryObj.sort) {
        !Array.isArray(queryObj.sort) ? sort.push(queryObj.sort) : sort = queryObj.sort
        for (let item in sort) {
          const sA = sort[item].split(':')
          if (columnSchema.name === sA[0]) {
            if (sA.length = 2) {
              if (sA[1].toLowerCase() === 'asc') {
                $(`#${id} .fa-caret-up.tableCaret`).removeClass('grey')
              } else {
                $(`#${id} .fa-caret-down.tableCaret`).removeClass('grey')
              }
            }
          }
        }
      }
    })
    devices.tableSortTree(sort)
  },
  'columnSort': function (id) {
    //check current query string for existing sort
    const payload = rocketcyber.deviceSearch.payload

    if (payload.totalCount === 0) {
      return
    }

    const schema = payload?.configs?.schema?.find(item => item.id === id)
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    const filterArray = $('#advanceFilter .filter_bubble').toArray()
    if (!schema || !payload) {
      return
    }
    const qs = location.search.substring(1)
    const queryObj = this.queryStringToJSON(qs)
    let existingSort = false
    let sort = []
    if (Object.keys(queryObj).length === 0) {
      return
    }
    if (queryObj.sort) {
      !Array.isArray(queryObj.sort) ? sort.push(queryObj.sort) : sort = queryObj.sort
      for (let item in sort) {
        const sA = sort[item].split(':')
        if (schema.name === sA[0]) {
          if (sA.length === 2) {
            existingSort = true
            if (sA[1].toLowerCase() === 'asc') {
              sort[item] = `${sA[0]}:DESC`
            } else {
              sort.splice(item, 1)
            }
          }
        }
      }
    }
    if (!existingSort) {
      //add sort to href
      if (!queryObj.sort) {
        sort = [`${schema.name}:ASC`]
      } else if (Array.isArray(queryObj.sort)) {
        sort.push(`${schema.name}:ASC`)
      } else {
        sort = [queryObj?.sort, `${schema.name}:ASC`]
      }
    }
    if (queryObj.sort) {
      delete queryObj.sort
    }
    let newParamsArray = []
    filterArray.forEach(function (filter) {
      newParamsArray.push(filter?.attributes?.queryvalue?.value)
    })
    let paramArray = []
    newParamsArray.forEach(function(element){
      if(element.indexOf('&sort')>-1){
        let modifiedParam = element.split('&')
        modifiedParam = modifiedParam.filter(el => el.indexOf('sort=')<=-1)
        modifiedParam.join('')
        element = modifiedParam
      }
      paramArray.push(element)
    })
    newParamsArray = paramArray
    const bottomPageSize = `pageSize=${$('#bottomPageSize').find(':selected').val()}`
    let newParams = `${bottomPageSize}&${newParamsArray.toString().replaceAll(',','&')}`
    newParams += '&config=true'
    const userId = `userId=${Number($('#customResults').attr('data-user'))}`
    if(isNaN(Number($('#customResults').attr('data-user'))) === false && $('#customResults').attr('data-user')){
      newParams += `&${userId}`
    }
    //TODO remove searchType
    const searchType = $('.searchType:checked').val()
    if (searchType) {
      newParams += `&searchType=${searchType}`
    }
    if (params?.appId || params?.app_id) {
      const appId = params?.appId || params?.app_id
      newParams += `&appId=${appId}&app_id=${appId}`
    }
    let newQuery = sort.length > 0 ? `${newParams}&sort=${sort.join('&sort=')}` : newParams

    newQuery = '?' + newQuery.replace(/.*\?/g, '')                    // replace everything before '?'
      .split('&')                              // split by '&'
      .filter((element, paramIndex, paramArray) => paramArray.indexOf(element) === paramIndex) // filter duplicates
      .join('&')
    document.location.href = `${newQuery}`
  },
  'insertPagination': function (payload) {
    const checkLeftArrowDisabled = payload?.currentPage === 1 ? 'disabled' : '';
    const checkRightArrowDisabled =payload?.currentPage === payload.totalPages ? 'disabled' : '';

    let showingCountStart = 1;
    let showingCountEnd = payload?.data.length
    const url = new URL(window.location.href)

    let pageSize = url.searchParams.get('pageSize') || 100
  
    if (payload?.currentPage > 1) {
      showingCountStart = Number(pageSize) * (payload?.currentPage - 1) + 1;
      showingCountEnd = payload?.data.length * payload.currentPage;

      if (payload?.currentPage == payload?.totalPages) {
        showingCountEnd = payload?.totalCount
      }
    }
    let paginationHTML = `
      <nav class="pagy-bootstrap-nav" role="navigation">
        <div class="pagy-nav">
          <div>Showing <strong>${ showingCountStart} - ${ showingCountEnd } of ${ payload?.totalCount } </strong>items</div>
          <div class="pagination-container">
            <a class="pagination-link ${checkLeftArrowDisabled}" onclick="window.rocketcyber.deviceSearch.changePage(this)" value="${payload?.currentPage - 1}">
              <i class="fa-regular fa-chevron-left"></i>
            </a>
            
            <input class="current-page"
                   id="pageinationIput"
                   onChange="window.rocketcyber.deviceSearch.changePage(this)"
                   onInput="window.rocketcyber.deviceSearch.changeTextWidth(this)"
                   style="width: ${String(payload.currentPage).length + 3}ch;"
                   type="number"
                   min="1"
                   max="${payload.totalPages}"
                   value="${ payload?.currentPage }">
            <div class="margin-left-6">/</div>
            <div class="margin-left-6">${ payload?.totalPages }</div>

            <a class="pagination-link ${checkRightArrowDisabled}" onclick="window.rocketcyber.deviceSearch.changePage(this)" value="${payload?.currentPage + 1}">
              <i class="fa-regular fa-chevron-right"></i>
            </a>
            <div class="page-size-options">
              <div class="page-size-selection-container">
                <select id="bottomPageSize" class="page-size-selection" onChange="window.rocketcyber.deviceSearch.submitSearch()">
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </select>
              </div>
              <span class="margin-left-10">rows per page</span>
            </div>
          </div>
        </div>
      </nav>`
    $(paginationHTML).insertAfter('.panel-body.nopadding:has(table) .row')
    $('#pageinationIput').on('keypress', function (event) {
      const keyPressed = event.keyCode || event.which
      if (keyPressed === 13) {

        event.preventDefault()
        window.rocketcyber.deviceSearch.changePage(this)
        return false
      }
    })
  },
  'changeTextWidth': function (element) {
    var page = element.value
    var max = Number(element.max)
    if (page > max) {
      element.value = element.value.slice(0, element.max.length)
    }

    element.style.width = (3 + element.value.length) + "ch";
  },
  'changePage': function (element) {
    const page = $(element).prop('nodeName').toLowerCase() === 'input' ? $(element).val() : $(element).attr('value')
    console.log(page);
    const regex = new RegExp('^[0-9]*$')
    if (!page || !regex.test(page) || page == '0' || Number(page) > rocketcyber.deviceSearch.payload.totalPages) {
      const currentpage = rocketcyber?.deviceSearch?.payload?.currentPage ? rocketcyber.deviceSearch.payload.currentPage : 1
      $('.paginationInputContainer input').val(currentpage)
      return
    }
    const url = new URL(window.location.href)
    url.searchParams.set('page', page)
    document.location.href = url.href
  },
  'searchType': function (payload) {
    //Check if simple or advance
    const searchTypeEnum = {
      ADVANCED: 'advanced',
      SIMPLE: 'simple',
      DISABLED_FEATURE: 'disable_feature'
    }
    const schema = payload?.configs?.schema
    const defaultFilter = schema.filter(item => item?.default)
    if (defaultFilter.length === 0) {
      return searchTypeEnum.DISABLED_FEATURE
    }
    //Advanced if more than 2 bubbles
    return searchTypeEnum.ADVANCED

  },
  'inputValidation': function(optionType){
    const numericRegex = /^\d+$/
    const symbolRegex = /[%&]/
    let input = $('#searchFilterInput')
    let whitespaceTrim = /^\s+|\s+$/g

    //Checks for non-numeric characters
    if(optionType === 'int' || optionType === 'bigint'){
      if(numericRegex.test(input.val()) === false){
        input.val(input.val().replace(/[^0-9]/g,''))
      }
    }

    //Checks for invalid characters %& in string
    if(optionType === 'string' && $('.input-group input').length > 0) {
      if (symbolRegex.test(input.val()) === true) {
        input.val(input.val().replace(/[%&]/g, ''))
      }
    }

    //Trim whitespace
    if(optionType === 'string' && $('.input-group input').length > 0) {
      input.val(input.val().trim(whitespaceTrim))
    }
  },
  'filterErrorHandler': function (optionType) {
    const maxInput = 100
    const maxBigInt = 9007199254740991
    const maxInt = 2147483647
    let input = $('#searchFilterInput').val()
    const whitespaceRegex = /^\s+$/
    const symbolRegex = /[%&]/
    const errorMessage = $('#error p')

    if (errorMessage) {
      errorMessage.remove()
    }
    //Check string length
    if(optionType === 'string' && $('.input-group input').length > 0) {
      if (input.length > maxInput) {
        const error = `
         <p>Input exceeds max characters of ${maxInput}</p>
       `
        $('#error').append(error)
      } else if(whitespaceRegex.test(input) === true || symbolRegex.test(input) === true) {
        const error = `
         <p>Invalid input</p>
       `
        $('#error').append(error)
      } else {
        errorMessage.remove()
      }
    }

    //Check if input is greater than bigint
    if (optionType === 'bigint') {
      if (input > maxBigInt) {
        const error = `
         <p>Input needs to be less than ${maxBigInt}</p>
       `
        $('#error').append(error)
      } else {
        errorMessage.remove()
      }
    }
    //Check if input is greater than int
    if (optionType === 'int'){
      if(input > maxInt){
        const error = `
         <p>Input needs to be less than ${maxInt}</p>
       `
        $('#error').append(error)
      }
      else {
        errorMessage.remove()
      }
    }
  }
}