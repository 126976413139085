import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.toggleCurrentTab()
  }

  toggleCurrentTab() {
    if (this.targetTabPane && this.targetTabPane.id) {
      let parentTab       = this.element.querySelector(`[data-toggle="tab"][href="#${this.targetTabPane.id}"]`).parentNode
      let activeParentTab = parentTab.parentNode.querySelector(".active")
      let activeTabPane   = this.targetTabPane.parentNode.querySelector(".active")

      activeParentTab.classList.remove('active')
      activeTabPane.classList.remove('active')
      parentTab.classList.add('active')
      this.targetTabPane.classList.add('active')
    }
  }

  get anchor() {
    return window.location.hash
  }

  get targetTab() {
    return this.element.querySelector(`[data-toggle="tab"][href="${this.anchor}"]`)
  }

  get targetTabPane() {
    if (this.targetTab == null) { return }
    return this.targetTab.closest('.tab-pane')
  }
}
