// app/javascripts/controllers/config_key_value_controller.js
/*global toastr*/
import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['row', 'tenantId', 'accountId']

  del(event) {
    this.removeTableRow();
    this.deleteMapping();
    event.preventDefault();
  }

  deleteMapping() {
    return $.ajax({
      type: "post",
      url: this.url(),
      timeout: 0,
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))},
      data: { "app_id": this.appId(), "tenant_id": this.tenantId() }
    });
  }

  removeTableRow() {
    const i = this.rowTarget.rowIndex;
    this.rowTarget.closest("table").deleteRow(i);
  }

  url() {
    return `/accounts/${this.accountId()}/antivirus_customer_map/remove`;
  }

  authenticityToken() {
    return this.scope.element.dataset.authenticity_token;
  }

  tenantId() {
    return this.tenantIdTarget.value;
  }

  accountId() {
    return this.accountIdTarget.value;
  }

  appId() {
    return this.scope.element.dataset.app_id;
  }
}
