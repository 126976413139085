import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'accessToken',
    'accountId',
    'apiKey',
    'apiToken',
    'appId',
    'appSecret',
    'baseUrl',
    'clientId',
    'companyId',
    'companyName',
    'gsmKey',
    'guid',
    'outputDisplay',
    'password',
    'privateApiKey',
    'publicApiKey',
    'refreshToken',
    'secret',
    'subdomain',
    'tenantId',
    'type',
    'username',
    'credentialId'
  ]

  connect() {
    let url = new URL(window.location.href)
    let appId = url.hash.replace("#", "")
    let hasError = document.getElementsByClassName('toast toast-error').length >= 1
    if (hasError && this.hasAppIdTarget && this.appIdTarget.innerText == appId) {
      this.update({ error: true, code: 500})
    }
  }

  call(event) {
    if(this.customValid(event))
      this.runTest(event);

    event.preventDefault();
  }

  runTest(event) {
    const type = this.typeTarget.innerText
    const accountId = event.target.dataset.account
    const search_params = this.get_search_params(type)
    $.ajax({
      type: "post",
      url: `/accounts/${accountId}/credentials/test`,
      data: new URLSearchParams(search_params).toString(),
      success: (data) => {
        this.update(data);
      }
    })
  }

  customValid(event) {
    const type = this.typeTarget.innerText;
    switch(type) {
      case "bitdefender":
        return this.bitdefenderValid(event)
        break
      case "syncro":
        return this.syncroValid(event);
      break
    }
    if (this.hasOutputDisplayTarget) {
      return this.validateForm()
    } else {
      return true;
    }
  }

  validateForm() {
    const form = this.outputDisplayTarget.closest('form')
    const fields = form.querySelectorAll('input[type="text"]')

    const validateFields = Array.from(fields).map(input => input.checkValidity())

    if (validateFields.includes(false)) {
      form.click();
      return false;
    } else {
      return true
    }
  }

  bitdefenderValid() {
    if(!this.baseUrlTarget.checkValidity()) {
      $("input[type=submit]", this.baseUrlTarget.closest("form")).click()
      return false
    }
    return true;
  }

  syncroValid(event) {
    if(!this.subdomainTarget.checkValidity()) {
      $("input[type=submit]", this.subdomainTarget.closest("form")).click();
      return false;
    }
    return true;
  }

  update(data) {
    const customAlert = this.getAlert(this.getMessage(data), data.error);

    this.formFieldsFor(this.typeTarget.innerText).forEach(field => {
      data.error ? field.parentElement.classList.add('has-error') : field.parentElement.classList.remove('has-error')
    })

    this.outputDisplayTarget.innerHTML = customAlert
  }

  getMessage(data) {
    let message = null;

    if(data.error) {
      message = data.message ? data.message : "credential authentication failed.";
      message = "Error " + data.code + ": " + message;
    } else {
      message = "Success " + data.code + ": valid credential detected.";
    }
    return message;
  }

  getAlert(message, error) {
    const alert = `<div class="alert ${error ? 'alert-warning' : 'alert-success' } --aligned-center">
      <div class="alert-content">
        <div class="alert-icon">
          <i class="fa-regular ${error ? 'fa-triangle-exclamation' : 'fa-circle-check'}"></i>
        </div>
        <div class="alert-body">
          <div class="alert-subtitle size-14 --inline">
            ${message}
          </div>
        </div>
      </div>
      <div aria-label="Close" class="button close" data-dismiss="alert">
        <div aria-hidden="true" class="span">×
      </div>
    </div>
    </div>`

    return alert;
  }

  formFieldsFor(type) {
    switch(type){
      case "hibp":
        return [this.accessTokenTarget]
        break;
      case "cylance":
        return [this.tenantIdTarget, this.appIdTarget, this.appSecretTarget, this.baseUrlTarget]
        break;
      case "passly":
        return [this.tenantIdTarget, this.appIdTarget, this.appSecretTarget]
        break;
      case "datto":
        return [this.usernameTarget, this.secretTarget]
        break;
      case "dns_filter":
        return [this.usernameTarget, this.passwordTarget]
        break;
      case "duo":
        return [this.tenantIdTarget, this.appSecretTarget, this.appIdTarget]
        break;
      case "ironscales":
        return [this.refreshTokenTarget, this.companyNameTarget, this.subdomainTarget]
        break;
      case "kaseya":
        return [this.usernameTarget, this.passwordTarget, this.companyNameTarget, this.baseUrlTarget]
        break;
      case "sentinelone":
        return [this.apiTokenTarget, this.baseUrlTarget]
        break;
      case "sophos":
        return [this.clientIdTarget, this.secretTarget]
        break;
      case "syncro":
        return [this.subdomainTarget, this.apiKeyTarget]
        break;
      case "webroot":
        return [this.usernameTarget, this.passwordTarget, this.clientIdTarget, this.secretTarget, this.gsmKeyTarget]
        break;
      case "graphus":
        return [this.subdomainTarget, this.guidTarget]
        break;
      case "darkweb_id":
        return [this.usernameTarget, this.passwordTarget]
        break;
      case "saas_alert":
        return [this.apiKeyTarget]
        break;
      default:
        return [this.baseUrlTarget, this.accessTokenTarget]
    }
  }

  get_search_params(type){
    // bitdefender, deep_instinct, and haveibeenpwnd are all access_token based
    switch(type){
      case "bitdefender":
        return {
          app:           type,
          access_token:  this.accessTokenTarget.value,
          credential_id: this.credentialIdTarget.value,
          base_url:      this.baseUrlTarget.value
        }
        break;
      case "deep_instinct":
        return {
          app:           type,
          access_token:  this.accessTokenTarget.value,
          credential_id: this.credentialIdTarget.value,
          base_url:      this.baseUrlTarget.value
        }
        break;
      case "hibp":
        return {
          app: type,
          access_token:  this.accessTokenTarget.value,
          credential_id: this.credentialIdTarget.value
        }
        break;
      case "connectwise":
        return {
          app: type,
          connectwise_company_id:          this.companyIdTarget.value,
          connectwise_psa_public_api_key:  this.publicApiKeyTarget.value,
          connectwise_psa_private_api_key: this.privateApiKeyTarget.value,
          base_url:                        this.baseUrlTarget.value,
          credential_id:                   this.credentialIdTarget.value
        }
        break
      case "cylance":
        return {
          app:           type,
          tenant_id:     this.tenantIdTarget.value,
          app_id:        this.appIdTarget.value,
          app_secret:    this.appSecretTarget.value,
          credential_id: this.credentialIdTarget.value,
          base_url:      this.baseUrlTarget.value
        };
        break;
      case "passly":
        return {
          app:           type,
          tenant_id:     this.tenantIdTarget.value,
          app_id:        this.appIdTarget.value,
          app_secret:    this.appSecretTarget.value,
	        credential_id: this.credentialIdTarget.value
        };
        break;
      case "datto":
        return {
          app: type,
          username:      this.usernameTarget.value,
          secret_key:    this.secretTarget.value,
          credential_id: this.credentialIdTarget.value
        }
        break
      case "dns_filter":
        return {
          app: type,
          dns_filter_username: this.usernameTarget.value,
          dns_filter_password: this.passwordTarget.value,
	        credential_id:       this.credentialIdTarget.value
        };
        break;
      case "ironscales":
        return {
          app: type,
          refresh_token: this.refreshTokenTarget.value,
          credential_id: this.credentialIdTarget.value
        }
        break;
      case "kaseya":
        return {
          app: type,
          username:      this.usernameTarget.value,
          password:      this.passwordTarget.value,
          company_name:  this.companyNameTarget.value,
          base_url:      this.baseUrlTarget.value,
          credential_id: this.credentialIdTarget.value
        }
        break
      case "sentinelone":
        return {
          app: type,
          api_token:     this.apiTokenTarget.value,
          credential_id: this.credentialIdTarget.value
        }
        break;
      case "sophos":
        return {
          app: type,
          sophos_client_id:     this.clientIdTarget.value,
          sophos_client_secret: this.secretTarget.value,
	        credential_id:        this.credentialIdTarget.value
        }
	      break;
      case "duo":
        return {
          app: type,
          duo_host:            this.tenantIdTarget.value,
                duo_integration_key: this.appIdTarget.value,
                duo_secret:          this.appSecretTarget.value,
          credential_id:       this.credentialIdTarget.value,
          account_id:          this.accountIdTarget.value
        };
        break;
      case "syncro":
        return {
          app: type,
          subdomain:     this.subdomainTarget.value,
          api_key:       this.apiKeyTarget.value,
          credential_id: this.credentialIdTarget.value
        }
        break
      case "webroot":
        return {
          app: type,
          webroot_username:      this.usernameTarget.value,
          webroot_password:      this.passwordTarget.value,
          webroot_client_id:     this.clientIdTarget.value,
          webroot_client_secret: this.secretTarget.value,
          webroot_gsm_key:       this.gsmKeyTarget.value,
	        credential_id:         this.credentialIdTarget.value
        }
        break;
      case "graphus":
        return {
          app: type,
          api_domain: this.subdomainTarget.value,
          guid:       this.guidTarget.value
        }
      case "datto_edr":
        return {
          app:           type,
          access_token:  this.accessTokenTarget.value,
          credential_id: this.credentialIdTarget.value,
          base_url:      this.baseUrlTarget.value
        }
      case "darkweb_id":
        return {
          app:      type,
          username: this.usernameTarget.value,
          password: this.passwordTarget.value
        }
      case "saas_alert":
        return {
          app:      type,
          api_key:  this.apiKeyTarget.value
        }
    }
  }
}
