import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  static values = {
    separator: String
  }

  initialize() {
    this.separator = this.separatorValue || ','
    this.regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }

  keyPressed(e) {
    // Prevent registering space bar key presses
    if (e.keyCode === 32)
      e.preventDefault()
  }

  submitted(e) {
    this.inputTargets.forEach((input) => {
      this.validate(input)
    })
  }

  validate(input) {
    let values = input.value.split(this.separator)
    let invalid = []

    for (let i = 0; i < values.length; i++) {
      if (values[i] == '' || !this.regex.test(values[i]))
        invalid.push(`'${values[i]}'`)
    }

    if (invalid.length > 0)
      input.setCustomValidity('Invalid email addresses: ' + invalid.join(', '))
    else
      input.setCustomValidity('')
  }
}
