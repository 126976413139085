window.rocketcyber.notifications = {
  vars:{
    clientId:null,
    unViewedNotificationArray:[],
    activeNotificationsArray:[],
    userEmail:'',
    unviewedCount:0,
    apiURL:'',
    maxTitleLength:40,
    maxMessageLength:120,
    maxMessageBodyLength: 3000
  },
  functions:{
    onLoadDashboardPage:async ()=>{
      (function(d, script) {
        script = d.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.onload = function(){
          // remote script has loaded
        }
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/showdown/1.9.0/showdown.min.js'
        d.getElementsByTagName('head')[0].appendChild(script)
      }(document))

      const func = rocketcyber.notifications.functions
      //Set Variables
      func.setVariables()
      // eslint-disable-next-line no-undef
      const vars= rocketcyber.notifications.vars
      if(jQuery&&vars.userEmail&&vars.apiURL){
        func.addCSS()
        //get Active accounts array
        await func.getActiveNotifications('notAcknowledged')
        //Add Bell
        func.notificationBell()
      }

    },
    setVariables:()=>{
      rocketcyber.notifications.vars.userEmail=jQuery('#user_email').text()
      rocketcyber.notifications.vars.apiURL=jQuery('#notification_api').text()
      rocketcyber.notifications.vars.clientId= jQuery('#account_id').text()
    },
    updateUnviewedArray:(itemId)=>{
      const newArray = rocketcyber.notifications.vars.unViewedNotificationArray.filter(item=>item.id !== itemId)
      rocketcyber.notifications.vars.unViewedNotificationArray=newArray
      //update bell
      if(newArray.length>0){
        jQuery('.newAlerts').text(newArray.length)
      }else{
        jQuery('.newAlerts').remove()
      }
    },
    getActiveNotifications:async (type)=>{
      const id = rocketcyber.notifications.vars.clientId
      const email = rocketcyber.notifications.vars.userEmail
      const api = rocketcyber.notifications.vars.apiURL
      let unviewedQuery=''
      if(type){
        unviewedQuery=`?email=${email}&type=${type}`
      }
      const settings = {
        'url': `${api}/api/consoleNotifications/${id}${type?unviewedQuery:''}`,
        'method': 'GET',
        'timeout': 0,
        'headers': {
          'Authorization': 'Bearer localhostapitoken'
        },
      }
      try {
        const resp =await $.ajax(settings).done(function (response) {
          return response
        })
        if(resp){
          if(resp.length>0){
            if(type){
              rocketcyber.notifications.vars.unViewedNotificationArray=resp
              rocketcyber.notifications.vars.unviewedCount=resp.length
            }else{
              rocketcyber.notifications.vars.activeNotificationsArray=resp
            }

          }else{
            rocketcyber.notifications.vars.unViewedNotificationArray=[]
            rocketcyber.notifications.vars.unviewedCount=0
          }
        }
      }catch (e){
        rocketcyber.notifications.vars.unViewedNotificationArray=[]
        rocketcyber.notifications.vars.unviewedCount=0
      }
    },
    postViewEvent:(id)=>{
      const vars=rocketcyber.notifications.vars
      var data = JSON.stringify({
        'accountId': vars.clientId,
        'notificationId': id,
        'email': vars.userEmail
      })
      var xhr = new XMLHttpRequest()
      xhr.withCredentials = true

      xhr.addEventListener('readystatechange', function() {
        if(this.readyState === 4) {
          console.log(this.responseText)
        }
      })

      xhr.open('POST',  `${vars.apiURL}/api/consoleNotifications/acknowledge`,)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Authorization', 'Bearer localhostapitoken')

      xhr.send(data)
    },
    removeCard:(itemId)=>{
      const item=rocketcyber.notifications.vars.unViewedNotificationArray.find(item => item.id === itemId)
      const id=`card_${item.id}`
      rocketcyber.notifications.functions.postViewEvent(item.id)
      jQuery(`#${id}`).remove()
      rocketcyber.notifications.functions.updateUnviewedArray(item.id)
      if(!$('.newAlerts').length){
        let emptyHTML = rocketcyber.notifications.functions.emptyNotification()
        $('#notifications_main').css('height', '418px')
        jQuery('#notifications_body').html(emptyHTML)
      }
    },
    buildNotificationsMain:()=>{
      if(jQuery('#notifications_main').length>0){
        jQuery('.notification.View').remove()
        $('.notificationDropdownOverlay').remove()
        $('.notificationAlert').css('background', '')
        return
      }
      $('.notificationAlert').css('background', '#121515')
      const notificationDropdown = rocketcyber.notifications.functions
      const vars=rocketcyber.notifications.vars
      let htmlString=''
      if(vars.unViewedNotificationArray.length>0){
        //Build unviewed Array
        htmlString=notificationDropdown.buildArrayHTML(vars.unViewedNotificationArray)
      } else {
        htmlString=notificationDropdown.emptyNotification()
      }
      const overlay = '<div class="notificationDropdownOverlay" onclick="rocketcyber.notifications.functions.closeView()"></div>'
      const main='<div class="notification View">' +
        '<div id="notifications_main">'+
        '<h4>Notifications</h4>'+
        '<div class="notification_spacer"></div>'+
        '<div id="notifications_body">'+
        `${htmlString}`+
        '</div>'+
        '<div class="notification_spacer"></div>'+
        '<div style="text-align: center;"><a class="viewAllNotificationsAnchor" onclick="rocketcyber.notifications.functions.viewAllNotifications()">View All</a></div>'+
        '</div>'+
        '</div>'
      jQuery('.notificationAlert').append(main)
      jQuery('body').prepend(overlay)
      if(vars.unViewedNotificationArray.length === 0){
        $('#notifications_main').css('height', '418px')
      }
      $(document).mouseup(function(e)
      {
        let container = $("#notifications_main");

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0)
        {
          container.remove()
          $('.notificationDropdownOverlay').remove()
          $('.notificationAlert').css('background', '')
        }
      })
    },
    emptyNotification: () => {
      let htmlString = `
            <div class='empty_notification'>
                <svg class="empty_icon" width="167" height="90" viewBox="0 0 167 90" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M160 12C163.866 12 167 15.134 167 19C167 22.866 163.866 26 160 26H120C123.866 26 127 29.134 127 33C127 36.866 123.866 40 120 40H142C145.866 40 149 43.134 149 47C149 50.866 145.866 54 142 54H131.826C126.952 54 123 57.134 123 61C123 63.5773 125 65.9107 129 68C132.866 68 136 71.134 136 75C136 78.866 132.866 82 129 82H46C42.134 82 39 78.866 39 75C39 71.134 42.134 68 46 68H7C3.13401 68 0 64.866 0 61C0 57.134 3.13401 54 7 54H47C50.866 54 54 50.866 54 47C54 43.134 50.866 40 47 40H22C18.134 40 15 36.866 15 33C15 29.134 18.134 26 22 26H62C58.134 26 55 22.866 55 19C55 15.134 58.134 12 62 12H160ZM160 40C163.866 40 167 43.134 167 47C167 50.866 163.866 54 160 54C156.134 54 153 50.866 153 47C153 43.134 156.134 40 160 40Z" fill="#EEEFF0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M71.1186 60.3066C71.0404 60.8599 71 61.4252 71 62C71 68.6274 76.3726 74 83 74C89.6274 74 95 68.6274 95 62C95 61.4252 94.9596 60.8599 94.8815 60.3066H124V87C124 88.6569 122.657 90 121 90H45C43.3431 90 42 88.6569 42 87V60.3066H71.1186Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M96 60C96 67.1797 90.1797 73 83 73C75.8203 73 70 67.1797 70 60C70 59.7674 70.0061 59.5362 70.0182 59.3066H42L51.5604 31.0389C51.9726 29.8202 53.1159 29 54.4023 29H111.598C112.884 29 114.027 29.8202 114.44 31.0389L124 59.3066H95.9818C95.9939 59.5362 96 59.7674 96 60Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M94.0976 60.9545C94.0976 66.5025 89.129 72 83 72C76.871 72 71.9024 66.5025 71.9024 60.9545C71.9024 60.7748 71.9077 59.5962 71.918 59.4188H51L59.1614 39.5755C59.5132 38.6338 60.4891 38 61.5873 38H104.413C105.511 38 106.487 38.6338 106.839 39.5755L115 59.4188H94.082C94.0923 59.5962 94.0976 60.7748 94.0976 60.9545Z" fill="#ECECEC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.598 30.25C111.973 30.25 112.327 30.3696 112.618 30.5781C112.908 30.7866 113.135 31.0839 113.255 31.4393L122.75 59.5123V86C122.75 86.4832 122.554 86.9208 122.237 87.2374C121.921 87.5541 121.483 87.75 121 87.75H45C44.5168 87.75 44.0793 87.5541 43.7626 87.2374C43.4459 86.9208 43.25 86.4832 43.25 86V59.5123L52.7446 31.4393C52.8648 31.0839 53.0916 30.7866 53.3822 30.5781C53.6728 30.3696 54.0271 30.25 54.4023 30.25H111.598Z" stroke="#969696" stroke-width="2.5"/>
<path d="M56 59C59.9366 59 64.1849 59 68.7449 59C70.6212 59 70.6212 60.3186 70.6212 61C70.6212 67.6274 76.1174 73 82.8973 73C89.6772 73 95.1734 67.6274 95.1734 61C95.1734 60.3186 95.1734 59 97.0496 59H122M48.5737 59H51H48.5737Z" stroke="#969696" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M82.0996 2V18.7559M56 6.30273L67.0996 18.7559M108.1 6.30273L97 18.7559" stroke="#969696" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                
                <div class="empty_message">
                <p class="empty_header">You're all caught up</p>
                <p class="empty_subtext">You don't have any new messages</p>
                </div>
            </div>
      `
      return htmlString
    },
    buildArrayHTML:(arr)=>{
      let htmlString=''
      const func=rocketcyber.notifications.functions
      for(let item in arr){
        if(arr[item].notificationType===0){
          htmlString+=func.notificationInfo(arr[item])
        }
        else if(arr[item].notificationType===1){
          htmlString+=func.notificationWarn(arr[item])
        }
        else if(arr[item].notificationType===2){
          htmlString+=func.notificationUrgent(arr[item])
        }
      }
      return htmlString
    },
    buildActiveNotificationArrayHTML:async ()=>{
      await rocketcyber.notifications.functions.getActiveNotifications(false)
      const arr=rocketcyber.notifications.vars.activeNotificationsArray
      const func=rocketcyber.notifications.functions
      let cardList=''
      for(let item in arr){
        if(arr[item].notificationType===0){
          cardList+=func.notificationCardInfo(arr[item])
        }
        else if(arr[item].notificationType===1){
          cardList+=func.notificationCardWarn(arr[item])
        }
        else if(arr[item].notificationType===2){
          cardList+=func.notificationCardUrgent(arr[item])
        }
      }
      return cardList
    },
    notificationBell:()=>{
      const count = rocketcyber.notifications.vars.unviewedCount
      const bell='<li class="notificationAlert">'+
        '<a onclick="rocketcyber.notifications.functions.buildNotificationsMain()">' +
        '<span class="notificationTooltip" data-placement="bottom" data-toggle="tooltip" title="" data-original-title="Notifications">' +
        '<i class="main-icon fa-solid fa-bell notificationBell"></i>' +
        '</a>'+
        `${count>0?'<sub class="newAlerts">'+count+'</sub>':''}`+
        '</span></a></li>'
      jQuery(bell).insertBefore('#current_provider_nav li.dropdown.pull-left')
      $('.notificationTooltip').on('mouseover', function(){
        $('<div class="tooltip fade bottom in" role="tooltip" id="notificationTooltip" style="display: block;">' +
          '<div class="tooltip-arrow" style="left: 50%;"></div><div class="tooltip-inner">Notifications</div></div>').insertAfter('.notificationTooltip')
      })
      $('.notificationTooltip').on('mouseout',function(){
        $('#notificationTooltip').remove()
      })
    },
    notificationsMessageModal:(id)=>{
      const item=rocketcyber.notifications.vars.unViewedNotificationArray.find(item => item.id === id)

      rocketcyber.notifications.functions.postViewEvent(item.id)
      rocketcyber.notifications.functions.updateUnviewedArray(item.id)
      jQuery('.notification.View').remove()
      $('.notificationDropdownOverlay').remove()
      //getNotifications type
      let icon='<i class="fa-regular fa-circle-info"></i>'
      let type = 'info'
      if(item.notificationType===1){
        icon='<i class="fa-regular fa-circle-exclamation"></i>'
        type = 'warn'
      }else if(item.notificationType===2){
        icon='<i class="fa-regular fa-triangle-exclamation"></i>'
        type = 'urgent'
      }
      const converter = new showdown.Converter()
      const messageHTML=converter.makeHtml(item.message)
      const startDate = new Date(item.startDate).toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const startTime = new Date(item.startDate).toLocaleTimeString()
      const endDate=new Date(item.endDate).toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const endTime = new Date(item.endDate).toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const displayModel=`
        <div class="notification View">
           <div class="notificationOverlay" onclick="rocketcyber.notifications.functions.closeView()"></div>
           <div class="viewNotificationDisplay popout">
              <div class="notificationModalBody">
              <div class="popoutHeader">
                    <div class="notification_type">${icon}</div>
                   <h4 class="${type}">${item.title}</h4>
                   <div class="popupCloseDiv"><a class="notificationDisplayCloseButton" onclick="rocketcyber.notifications.functions.closeView()"><i class="fa-regular fa-xmark-large"></i></a></div>
                </div>
                 <div class="notificationDate">
                    <label>Start Date:</label>
                    <div id="displayStartDate">${startDate} ${startTime}</div>
                 </div>
                 <div class="notificationDate">
                    <label>End Date:</label>
                    <div id="displayEndDate">${endDate} ${endTime}</div>
                 </div>
                 <div class="modalBodyMessage">
                    ${messageHTML.length > globalVars.maxMessageBodyLength ? messageHTML.substring(0, globalVars.maxMessageBodyLength):messageHTML}
                 </div>
              </div>
           </div>
        </div>
        </div>
      `
      jQuery('body').prepend(displayModel)
      jQuery('')
    },
    closeView:()=>{
      jQuery('.notificationView').remove()
      jQuery('.notification.View').remove()
      $('.notificationDropdownOverlay').remove()
      $('.notificationAlert').css('background', '')
      $('#header').css('z-index', '1000')
    },
    notificationInfo:(item)=>{
      const id=`card_${item.id}`
      const startDate = new Date(item.startDate)
      const notificationDate = startDate.toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const notificationTime = startDate.toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const info = `<div class="notification_card info" id=${id}>`+
        '<div class="notification_type"><i class="fa-regular fa-circle-info"></i></div>'+
        `<div class="notification_details">
            <div class="notification_header">
            <div class="notification_title">${item.title.length>globalVars.maxTitleLength?item.title.substring(0,globalVars.maxTitleLength)+'...':item.title}</div>
            <a class="notification_close" onclick="rocketcyber.notifications.functions.removeCard(${item.id})"><i class="fa-regular fa-xmark-large"></i></a>
            </div>
            <div class="notification_message">${item.message.length>globalVars.maxMessageLength?item.message.substring(0, globalVars.maxMessageLength)+'...':item.message}</div>
            <div class="notification_footer">
            <div class="notification_date">${notificationDate} ${notificationTime}</div>
            <a class="detail_link" onclick="rocketcyber.notifications.functions.notificationsMessageModal(${item.id})"> Details </a>
            </div>
           </div>`+
        '</div>'
      return info
    },
    notificationWarn:(item)=>{
      const id=`card_${item.id}`
      const startDate = new Date(item.startDate)
      const notificationDate = startDate.toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const notificationTime = startDate.toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const warn=`<div class="notification_card warn" id=${id}>`+
        '<div class="notification_type"><i class="fa-regular fa-circle-exclamation"></i></div>'+
        `<div class="notification_details">
            <div class="notification_header">
            <div class="notification_title">${item.title.length>globalVars.maxTitleLength?item.title.substring(0,globalVars.maxTitleLength)+'...':item.title}</div>
            <a class="notification_close" onclick="rocketcyber.notifications.functions.removeCard(${item.id})"><i class="fa-regular fa-xmark-large"></i></a>
            </div>
            <div class="notification_message">${item.message.length>globalVars.maxMessageLength?item.message.substring(0, globalVars.maxMessageLength)+'...':item.message}</div>
            <div class="notification_footer">
            <div class="notification_date">${notificationDate} ${notificationTime}</div>
            <a class="detail_link" onclick="rocketcyber.notifications.functions.notificationsMessageModal(${item.id})"> Details </a>
            </div>
           </div>`+
        '</div>'
      return warn
    },
    notificationUrgent:(item)=>{
      const id=`card_${item.id}`
      const startDate = new Date(item.startDate)
      const notificationDate = startDate.toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const notificationTime = startDate.toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const urgent = `<div class="notification_card urgent" id=${id}>`+
        '<div class="notification_type"><i class="fa-regular fa-triangle-exclamation"></i></div>'+
        `<div class="notification_details">
            <div class="notification_header">
            <div class="notification_title">${item.title.length>globalVars.maxTitleLength?item.title.substring(0,globalVars.maxTitleLength)+'...':item.title}</div>
            <a class="notification_close" onclick="rocketcyber.notifications.functions.removeCard(${item.id})"><i class="fa-regular fa-xmark-large"></i></a>
            </div>
            <div class="notification_message">${item.message.length>globalVars.maxMessageLength?item.message.substring(0, globalVars.maxMessageLength)+'...':item.message}</div>
            <div class="notification_footer">
            <div class="notification_date">${notificationDate} ${notificationTime}</div>
            <a class="detail_link" onclick="rocketcyber.notifications.functions.notificationsMessageModal(${item.id})"> Details </a>
            </div>
           </div>`+
        '</div>'
      return urgent
    },
    notificationCardInfo:(item)=>{
      const date=new Date(item.startDate)
        .toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const time = new Date(item.startDate)
        .toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const card=`<div class="listCard info" id="${item.id}" onclick="rocketcyber.notifications.functions.viewAllNotificationsDisplayMessage(${item.id})">
          <div class="listBody">
             <div class="cardIcon"><i class="fa-regular fa-circle-info"></i></div>
             <div class="cardDetails">
             <div class="title">${item.title.length>globalVars.maxTitleLength?item.title.substring(0,globalVars.maxTitleLength)+'...':item.title}</div>
             <div class="cardMessage">${item.message.length>globalVars.maxMessageLength?item.message.substring(0, globalVars.maxMessageLength)+'...':item.message}</div>
             <div class="cardDate">${date} ${time}</div>
             </div>
          </div>
       </div>`
      return card
    },
    notificationCardWarn:(item)=>{
      const date=new Date(item.startDate)
        .toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const time = new Date(item.startDate)
        .toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const card=`<div class="listCard warn" id="${item.id}" onclick="rocketcyber.notifications.functions.viewAllNotificationsDisplayMessage(${item.id})">
          <div class="listBody">
             <div class="cardIcon"><i class="fa-regular fa-circle-exclamation"></i></div>
             <div class="cardDetails">
             <div class="title">${item.title.length>globalVars.maxTitleLength?item.title.substring(0,globalVars.maxTitleLength)+'...':item.title}</div>
             <div class="cardMessage">${item.message.length>globalVars.maxMessageLength?item.message.substring(0, globalVars.maxMessageLength)+'...':item.message}</div>
             <div class="cardDate">${date} ${time}</div>
             </div>
          </div>             
       </div>`
      return card
    },
    notificationCardUrgent:(item)=>{
      const date=new Date(item.startDate)
        .toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const time = new Date(item.startDate)
        .toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const card=`<div class="listCard urgent" id='${item.id}' onclick="rocketcyber.notifications.functions.viewAllNotificationsDisplayMessage(${item.id})">
          <div class="listBody">
             <div class="cardIcon"><i class="fa-regular fa-triangle-exclamation"></i></div>
             <div class="cardDetails">
             <div class="title">${item.title.length>globalVars.maxTitleLength?item.title.substring(0,globalVars.maxTitleLength)+'...':item.title}</div>
             <div class="cardMessage">${item.message.length>globalVars.maxMessageLength?item.message.substring(0, globalVars.maxMessageLength)+'...':item.message}</div>
             <div class="cardDate">${date} ${time}</div>
             </div>
          </div>
       </div>`
      return card
    },
    viewAllNotifications:async ()=>{
      jQuery('.notification.View').remove()
      $('.notificationDropdownOverlay').remove()
      $('.notificationAlert').css('background', '')
      const listHTML = await rocketcyber.notifications.functions.buildActiveNotificationArrayHTML()
      const viewAllNotifications=`
        <div class="notification View">
           <div class="notificationOverlay" onclick="rocketcyber.notifications.functions.closeView()"></div>
           <div class="viewAllDisplay">
           <div class="viewAllHeader">
                 <label>Notifications</label>
                <a class="closeDivButton" onclick="rocketcyber.notifications.functions.closeView()"><i class="fa-regular fa-xmark-large"></i></a>
           </div>
                <div class="notification_spacer"></div>
                <div class="viewAllDetails">
              <div class="notificationList">
                 <div class="notificationListBody">
                    <div class="list">
                        ${listHTML}
                    </div>
                 </div>
              </div>
              <div class="notificationBody" style="display: none;">
              <div class="notificationBodyHeader">
                <div class="notification_type"></div>
                 <h4></h4>
                 <div class="popOut"></div>
              </div>
                  <div class="notificationDate">
                      <label>Start Date:</label>
                      <div id="displayStartDate"></div>
                  </div>
                  <div class="notificationDate">
                      <label>End Date:</label>
                      <div id="displayEndDate"></div>
                  </div>
                 <div class="notificationBodyMessage">
                 <label>Message:</label>
                 </div>
              </div>
              </div>
           </div>
           
           </div>
        </div>
      `
      $('#header').css('z-index', '1')
      jQuery('body').prepend(viewAllNotifications)
      if(jQuery('.viewAllDisplay .list .listCard').length>0){
        jQuery('.viewAllDisplay .list .listCard:first').trigger('click')
      }

    },
    viewAllNotificationsDisplayMessage:(itemId)=>{
      $('.listCard').css('background', '#fff')
      $(`#${itemId}`).css('background', 'rgba(141, 198, 63, 0.15)')
      const item=rocketcyber.notifications.vars.activeNotificationsArray.find(item => item.id === itemId)
      jQuery('.notificationBody h4').text(item.title)
      let startDate= new Date(item.startDate).toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})

      let startTime = new Date(item.startDate).toLocaleTimeString()
      let endDate= new Date(item.endDate).toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      let endTime = new Date(item.endDate).toLocaleTimeString()
      let icon=''
      let type = ''
      const popOut=`<a id="popOut" onclick="rocketcyber.notifications.functions.popoutModal(${item.id})" title="Popout"><i class="fa fa-external-link" aria-hidden="true" ></i></a>`
      if(item.notificationType===0){
        jQuery('.notification_type').html('<i class="fa-regular fa-circle-info"></i>')
        type = 'info'
      }else if(item.notificationType===1){
        jQuery('.notification_type').html('<i class="fa-regular fa-circle-exclamation"></i>')
        type = 'warn'
      }else if(item.notificationType===2){
        jQuery('.notification_type').html('<i class="fa-regular fa-triangle-exclamation"></i>')
        type = 'urgent'
      }
      const converter = new showdown.Converter()
      const messageHTML=converter.makeHtml(item.message)
      const globalVars = rocketcyber.notifications.vars
      $('.notificationBody h4').attr('class', type)
      $('#popOut').remove()
      jQuery('.popOut').append(popOut)
      jQuery('#displayStartDate').text(`${startDate} ${startTime}`)
      jQuery('#displayEndDate').text(`${endDate} ${endTime}`)
      jQuery('.notificationBodyMessage').html(messageHTML.length > globalVars.maxMessageBodyLength ? messageHTML.substring(0, globalVars.maxMessageBodyLength):messageHTML)
      //make anchor tags open up in a new window.
      jQuery('.notificationBodyMessage a').attr('target','_blank')
      rocketcyber.notifications.functions.postViewEvent(item.id)
      jQuery('.notificationBody').show()
    },
    popoutModal:(id)=>{
      const item=rocketcyber.notifications.vars.activeNotificationsArray.find(item => item.id === id)

      rocketcyber.notifications.functions.postViewEvent(item.id)
      rocketcyber.notifications.functions.updateUnviewedArray(item.id)
      jQuery('#notifications_main').remove()
      //getNotifications type
      let icon='<i class="fa-regular fa-circle-info"></i>'
      let type = 'info'
      if(item.notificationType===1){
        icon='<i class="fa-regular fa-circle-exclamation"></i>'
        type = 'warn'
      }else if(item.notificationType===2){
        icon='<i class="fa-regular fa-triangle-exclamation"></i>'
        type = 'urgent'
      }
      const converter = new showdown.Converter()
      const messageHTML=converter.makeHtml(item.message)
      const startDate = new Date(item.startDate).toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const startTime = new Date(item.startDate).toLocaleTimeString()
      const endDate=new Date(item.endDate).toLocaleString(undefined, {year:'2-digit', month:'2-digit', day:'2-digit'})
      const endTime = new Date(item.endDate).toLocaleTimeString()
      const globalVars = rocketcyber.notifications.vars
      const displayModel=`
             <div class="viewNotificationDisplay popout">
                <div class="notificationModalBody">
                <div class="popoutHeader">
                    <div class="notification_type">${icon}</div>
                   <h4 class="${type}">${item.title}</h4>
                   <div class="popupCloseDiv"><a class="notificationDisplayCloseButton" onclick="rocketcyber.notifications.functions.popOutClose()"><i class="fa-regular fa-xmark-large"></i></a></div>
                </div>
                <div class="notification_spacer"></div>
                   <div class="notificationDate">
                      <label>Start Date:</label>
                      <div id="displayStartDate"> ${startDate} ${startTime}</div>
                   </div>
                   <div class="notificationDate">
                      <label>End Date:</label>
                      <div id="displayEndDate"> ${endDate} ${endTime}</div>
                   </div>
                   <div class="modalBodyMessage">
                      ${messageHTML.length > globalVars.maxMessageBodyLength ? messageHTML.substring(0, globalVars.maxMessageBodyLength):messageHTML}
                   </div>
                </div>
             </div>
        `
      jQuery('.viewAllDisplay').hide()
      jQuery('.notification.View').prepend(displayModel)
    },
    popOutClose:()=>{
      jQuery('.viewAllDisplay').show()
      jQuery('.viewNotificationDisplay.popout').remove()
    },
    addCSS:()=>{
      // create a style element
      let style = document.createElement('style')

      // add the CSS as a string
      style.innerHTML = `
      .notificationBell {
          color: white;
          color: white;
          font-size: 20px;
          line-height: 56px;
          margin: 0 5px;
          vertical-align: middle;
      }
.notificationAlert {
          z-index: 1002;
          float: right;
      }
      .notificationAlert a {
      display: flex !important;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 8px;
          width: 40px;
          height: 56px;
          flex: none;
          order: 3;
          flex-grow: 0;
          z-index: 1002;
      }
      .notificationOverlay {
          width: 100%;
          height: 100%;
          position: fixed;
          z-index: 1000;
          background: #000;
          opacity: .7;
      }
      .notificationDropdownOverlay {
          width: 100%;
          height: 100%;
          position: fixed;
          z-index: 120;
          background: #000;
          opacity: .7;
      }
      .newAlerts {
          /* Auto layout */
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px 5px 1px;
          gap: 6px;
          position: absolute;
          width: 17px;
          height: 16px;
          left: 19px;
          top: 9px;
          color: #fff;
          
          /* border/critical
          Critical 50
          */
          background: #D22F2D;
          border-radius: 10px;
          
          /* Inside auto layout */
          flex: none;
          order: 4;
          flex-grow: 0;
          z-index: 4;
      }
      /* Dropdown Menu */
div#notifications_main::after{
    display: none;
}
div#notifications_main {
          /* Auto layout */
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          
          position: absolute;
          width: 410px;
          right: 0;
          left: auto;
          /* background/primary
          White
          */
          background: #FFF;
          /* shadow/sm
          
          Used for dropdowns, menus and tooltips
          */
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          margin: 0;
          border-radius: 0;
          z-index: 1001;
}
div#notifications_main h4 {
          /* Section */
          width: 91px;
          height: 24px;
          
          /* H4 Subtext/Regular */
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          
          display: flex;
          align-items: center;
          
          /* text/secondary */
          color: #626D7A;
          
          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 18px 0 15px 24px;
      }
.notification_spacer {
          width: 100%;
          border-top: solid 1px #c2c9c9;
          margin: 0;
      }
div#notifications_body {
          overflow: hidden;
          height: 100%;
          margin-bottom: 0;
          width: 100%;
      }
a.viewAllNotificationsAnchor {
          /* CTA right */
          margin: 12px 24px 12px 335px !important;
          width: 51px;
          height: 20px;
          
          /* Label1 Buttons and Fields/secondary/Medium */
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */
          display: flex;
          align-items: center;
          text-align: right;
          
          /* text/link-secondary */
          color: #2D4E80;
          
          
          /* Inside auto layout */
          flex: none;
          order: 1;
          flex-grow: 0;
          padding: 0;
          border: none;
      }
.notification_footer {
          display: flex;
          justify-content: space-between
      }
.notification_details {
          display: flex;
          flex-direction: column;
          width: 100%;
      }
.notification_header {
          display: flex;
          justify-content: space-between;
      }
.notification_title {
        /* Label */
        top: 0;
        width: 188px;
        height: 20px;
        margin: 0;
        
        /* Label1 Buttons and Fields/secondary/SemiBold */    
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        
        /* identical to box height, or 143% */
        display: flex;
        align-items: center;
        
        /* text/primary */
        color: #001D30;
        
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
      }
.notification_type {
        margin: 0 20px 0 18px;
        width: 14px;
        height: 21px;
        font-family: 'Font Awesome 6 Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */
        text-align: center;
        
        /* text/primary */
        color: #001D30;
        
        
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
      }
.notification_message {
          /* Description */
          width: 95%;
          overflow-wrap: anywhere;
            /* Label2 Tables/primary/Regular */
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
            /* or 143% */
          display: flex;
          align-items: center;
            
            /* text/secondary */
          color: #626D7A;
            
            
            /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin: 6px 0;
      }

.detail_link {
    /* Call to action */
    width: 38px;
    height: 20px !important;
    margin-right: 25px !important;

/* Label2 Tables/secondary/SemiBold */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 20px;
/* identical to box height, or 167% */
    display: flex;
    align-items: center;
    text-align: right;

/* text/link-primary */
    color: #166EFA !important;


/* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

a.notification_close {
        width: 14.4px;
        height: 15.12px;
        font-family: 'Font Awesome 6 Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14.4px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        
        /* icon/secondary */
        color: #626D7A;
        
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
      margin: 0 25px 0 0 !important;
      }

.notification_card {
        width: 100%;
        padding: 15px 0;
        border: none !important;
        background-color: #fff;
        display: flex;
      }
      .notification_card:hover {
    background-color: #F6F7F8;
}
#notifications_body div:nth-child(n+5){
    display: none;
}
.notification_card.urgent .notification_title {
  color: #D22F2D;
}
.listCard.urgent .title {
  color: #D22F2D;
}
 i.fa-regular.fa-circle-info {
          width: 14px;
          height: 21px;
          
          font-family: 'Font Awesome 6 Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */
          
          text-align: center;
          
          /* text/primary */
          color: #001D30;
          
          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
      }
 i.fa-regular.fa-triangle-exclamation {
          width: 14px;
          height: 21px;
          
          font-family: 'Font Awesome 6 Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */
          text-align: center;
          
          /* icon/critical */
          color: #D22F2D;

          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
      }
 i.fa-regular.fa-circle-exclamation {
          width: 14px;
          height: 21px;
          font-family: 'Font Awesome 6 Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */
          text-align: center;
          
          /* icon/warning */
          color: #E5B000;

          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
      }
      .empty_notification {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.empty_notification svg {
    position: absolute;
    left: 29.63%;
    right: 29.63%;
    top: 28.66%;
    bottom: 49.04%;
}
.empty_message {
    position: absolute;
    left: 25px;
    top: 52.66%;
    bottom: 49.04%;
}
.empty_header {
    width: 362px;
height: 22px;

/* H5 Subsection Title/Medium */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */



/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
    margin:0
}
.empty_subtext {
    width: 362px;
height: 22px;

/* Body/Regular */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */

text-align: center;

/* text/secondary */

color: #626D7A;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
    margin-top: 6px;
}

/*View All*/
div.viewAllDisplay{
    /* View All Modal */
/* Auto layout */

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

position: absolute;
width: 1048px;
height: 658px;
left: 25%;
top: 117px;

/* light/background/primary

White
*/
background: #FFFFFF;
}
.viewAllTitle {
   /* Dialog Title */
width: 104px;
height: 26px;
margin: 24px 0 12px 24px;
    
/* H2 Subtitle/Med */
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;
/* identical to box height, or 144% */


/* text/primary */
color: #001D30;


/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0; 
}

.listCard {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    padding: 12px 16px 12px 24px;
    gap: 12px;
    width: 524px;
    height: auto;

/* Table Select */

/* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0;
}

.listBody .title{
    /* Label */


width: 188px;
height: 20px;

/* Label1 Buttons and Fields/secondary/SemiBold */

font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.listMessage {
    width: 454px;
    height: 40px;

/* Label2 Tables/primary/Regular */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
/* or 143% */

/* text/secondary */
    color: #626D7A;


/* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 6px 0 6px 0;
}
.listBody {
    display: flex;
    flex-direction: column; 
}

.listDate {
    /* Date */


width: 126px;
height: 20px;

/* Label2 Tables/primary/Regular */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */


/* text/secondary */

color: #626D7A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

/*View All*/
.viewAllDisplay {
     display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

position: absolute;
width: 1048px;
height: 658px;
left: 196px;
top: 117px;

/* light/background/primary

White
*/
background: #FFFFFF;
/* shadow/lg

Modal dialogs, drag and drop function
*/
box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
border-radius: 4px; 
      z-index: 1001;
}

.viewAllHeader label{
     margin: 24px 0 12px 24px;
     width: 104px;
height: 26px;

/* H2 Subtitle/Med */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;
/* identical to box height, or 144% */


/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.viewAllHeader a i{
     margin: 24px 24px 12px 24px;
     text-align: center;
     width: auto;
     height: 20px;
     font-family: 'Font Awesome 6 Pro';
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 20px;
/* identical to box height, or 125% */


/* Kaseya global/midnight/90 */
     color: #001D30;


/* Inside auto layout */
     flex: none;
     order: 1;
     flex-grow: 0;
}

.viewAllHeader {
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
}
.listBody {
    display: flex;
flex-direction: row;

gap: 12px;

width: 524px;
height: 116px;

/* Table Select */

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0; 
}
.listCard {
     display: flex;
     flex-direction: row;
}
.listCard .cardIcon {
     margin: 10px 14px 0 0;
     width: 14px;
height: 21px;

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */

text-align: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.cardDetails .title {
     margin-top: 12px;
    width: auto;
height: 20px;

/* Label1 Buttons and Fields/secondary/SemiBold */

font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0; 
}

.cardDetails .cardMessage {
overflow-wrap: anywhere;
    width: 85%;
height: auto;

/* Label2 Tables/primary/Regular */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

display: flex;
align-items: center;

/* text/secondary */

color: #626D7A;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0; 
}

.cardDetails .cardDate {
height: 20px;

/* Label2 Tables/primary/Regular */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;
text-align: right;

/* text/secondary */

color: #626D7A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0; 
}


.viewAllDetails {display: flex;flex-direction: row;width: 100%;}

.notificationList {
    overflow: hidden;
    height: 595px;
    overflow-y: scroll;
    border-right: solid 1px #c2c9c9;
}
.notificationHeader i {
    width: 14px;
height: 21px;

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */

text-align: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 15px 14px 0 29px;
}
.notificationHeader h4 {
    margin: 15px 211px 15px 0;
width: 214px;
height: 24px;

/* H3 Card Section Title/Medium */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.notificationDate {
    width: auto;
    height: 20px;

/* Misc/Supporting Text */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
/* identical to box height, or 167% */
    display: flex;
    /* align-items: center; */
    text-align: right;

/* text/secondary */
    color: #626D7A;


/* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 4px 24px;
}
.notificationList::-webkit-scrollbar {
    background: white;
}
.notificationList::-webkit-scrollbar-track {
    background: white;
}
.notificationList::-webkit-scrollbar-thumb {
    position: absolute;
width: 8px;
height: 211px;
left: 512px;
top: 4px;

background: #757575;
mix-blend-mode: normal;
opacity: 0.8;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
z-index: 1;
}

.notificationBodyMessage {
    border: solid 1px #dcdcdc;
    margin: 15px 24px;
    height: 460px;
    overflow: hidden;
    overflow-y: scroll;
}
.notificationBodyMessage::-webkit-scrollbar {
    background: white;
}
.notificationBodyMessage::-webkit-scrollbar-track {
    background: white;
}
.notificationBodyMessage::-webkit-scrollbar-thumb {
    position: absolute;
width: 8px;
height: 211px;
left: 512px;
top: 4px;

background: #757575;
mix-blend-mode: normal;
opacity: 0.8;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
z-index: 1;
}

.notificationBodyMessage p {
  overflow-wrap: anywhere;
    margin: 0 16px;
    width: 444px;

/* Body/Regular */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
/* or 157% */

display: flex;


/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

.notificationBody {
    width: 50%;
}

.notificationHeader {
    display: flex;
    flex-direction: row;
}

div#displayEndDate {
    margin-left: 5px;
}

div#displayStartDate {
    margin-left: 5px;
}
.viewNotificationDisplay.popout {
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

position: absolute;
width: 640px;
height: 658px;
left: 35%;
top: 117px;

/* light/background/primary

White
*/
background: #FFFFFF;
/* shadow/lg

Modal dialogs, drag and drop function
*/
box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
border-radius: 4px;
z-index: 1001;
}


.popoutHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
}
.popupCloseDiv a i{
    margin: 24px 32px;
    position: absolute;
    left: 565px;
    width: 10px;
height: 20px;

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 20px;
/* identical to box height, or 125% */


/* Kaseya global/midnight/90 */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.notificationModalBody {
    margin: 0;
    width: 100%;
    height: 100%;
}
.notification_spacer {
          width: 100%;
          border-top: solid 1px #c2c9c9;
          margin: 0;
      }

.popoutHeader .notification_type {
    width: 18px;
height: 21px;

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
/* identical to box height, or 117% */

/* text/primary */

color: #001D30;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
    margin: 26.5px 8px 26.5px 24px;
}
h4.urgent {
    color: #D22F2D !important;
}
.popoutHeader h4{
   width: auto;
   height: 26px;

/* H2 Subtitle/Med */
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 26px;
/* identical to box height, or 144% */


/* text/primary */
   color: #001D30;


/* Inside auto layout */
   flex: none;
   order: 0;
   flex-grow: 0;
   margin-top: 24px;
}

.popoutDate {
   margin: 5px 0 5px 24px;
   width: auto;
height: 20px;

/* Misc/Supporting Text */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 167% */

display: flex;

text-align: right;


/* text/secondary */

color: #626D7A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.popoutDate label {
   margin-right: 5px;
}

.modalBodyMessage {
    border: solid 1px #dcdcdc;
    height: 78%;
    margin: 10px 25px 0 25px;
    overflow: hidden;
    overflow-y: scroll;
}
.modalBodyMessage::-webkit-scrollbar {
    background: white;
}
.modalBodyMessage::-webkit-scrollbar-track {
    background: white;
}
.modalBodyMessage::-webkit-scrollbar-thumb {
    position: absolute;
width: 8px;
height: 211px;
left: 512px;
top: 4px;

background: #757575;
mix-blend-mode: normal;
opacity: 0.8;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
z-index: 1;
}
.modalBodyMessage h1 {
margin: 24px 0 0 10px;
width: 560px;
height: 24px;

/* H3 Card Section Title/Medium */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}
.modalBodyMessage p {
overflow-wrap: anywhere;
  margin: 8px 10px;
    width: 560px;
height: auto;

/* Body/Regular */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
/* or 157% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}
.notificationBodyHeader {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
}
.notificationBodyHeader h4 {
    /* Label */


width: auto;
height: 24px;

/* H3 Card Section Title/Medium */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.notificationBodyHeader .popOut {
  position: absolute;
  left: 76%;
  margin: 0 29px 0 211px;
    width: 14px;
height: 21px;

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */

text-align: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.notificationBodyMessage h1 {
overflow-wrap: anywhere;
      margin: 10px 10px 8px 16px !important;
    width: 444px;
height: auto;

/* H3 Card Section Title/Medium */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

/* text/primary */

color: #001D30;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.popoutHeader .notification_type .fa-regular.fa-triangle-exclamation {
    width: 18px;
    height: 21px;
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #D22F2D;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.popoutHeader .notification_type .fa-regular.fa-circle-exclamation {
    width: 18px;
    height: 21px;
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #E5B000;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.popoutHeader .notification_type .fa-regular.fa-circle-info {
    width: 18px;
    height: 21px;
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #001D30;
    flex: none;
    order: 0;
    flex-grow: 0;
}
      `
      // add it to the head
      document.getElementsByTagName('head')[0].appendChild(style)
    }
  }
}