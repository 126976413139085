window.rocketcyber = {
  errors: [],
  constants: {
    NUMBER_MORE_THEN_DEVICES: 160000
  },
  enumerators: {
    deviceConnectivity: {
      FIRST: 0,
      OFFLINE: 0,
      UNKNOWN: 1,
      ONLINE: 2,
      ISOLATED: 3,
      LAST: 3,
    }
  },
  deviceHeartbeatService: {
    getToken: (hostname) => {
      // default to US instance
      let target = 'b125a4f3857a768f50c0c9611f5adaaa8b5a49483d69befc578f17e97c34a180'

      if (!hostname || typeof hostname !== 'string') return target
      const hostSplit = hostname.split('.')
      const instance = hostSplit.length > 1 ? hostSplit[0] : 'app'
      if (instance === 'eu') {
        target = 'dba0abf2d9cd94bc35541b32388f61858e200d1c656a3b84fce2dd66d0331411'
      } else if (instance === 'staging') {
        target = '546A576E5A7234743777217A25432A462D4A614E645267556B58703273357638'
      }
      return target
    },
    getUrl: (hostname) => {
      // default to US instance
      let target = 'https://dhs.us.rocketcyber.com'

      if (!hostname || typeof hostname !== 'string') return target
      const hostSplit = hostname.split('.')
      const instance = hostSplit.length > 1 ? hostSplit[0] : 'app'
      if (instance === 'eu') {
        target = 'https://dhs.eu.rocketcyber.com'
      } else if (instance === 'staging') {
        target = 'https://dhs.dev.rocketdevnow.com'
      }
      return target
    },
    functions: {}
  }
}

