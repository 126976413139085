import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['accountSelectTypeAllAccounts', 'addButton', 'removeButton', 'addActionButton', 'removeActionButton', 'addDependencyButton', 'removeDependencyButton', 'appType', 'appSelect']

  connect() {
    this.addButtonTarget.addEventListener('keyup', () => {
      this.addItem()
    })

    this.addActionButtonTarget.addEventListener('keyup', () => {
      this.addAction()
    })

    this.removeButtonTarget.addEventListener('keyup', () => {
      this.removeItem(this.target)
    })

    this.removeActionButtonTarget.addEventListener('keyup', () => {
      this.removeAction(this.target)
    })

    this.showToggle()
  }

  showToggle() {
    const toggle = document.querySelector('.include-descendants')
    const accountIdField = this.element.querySelector('input[name="account_id"]')

    if (this.accountSelectTypeAllAccountsTarget.checked) {
      toggle.classList.add('hidden')
      accountIdField.classList.add('hidden')
    } else {
      toggle.classList.remove('hidden')
      accountIdField.classList.remove('hidden')
    }
  }

  addItem() {
    var collection = document.querySelector('.conditions')
    var item = document.querySelector('.condition-template').cloneNode(true)
    var inputs = item.querySelector('.form-group').children

    item.classList.remove('condition-template', 'hidden')
    item.classList.add('condition')
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = false
    }

    collection.appendChild(item)
  }

  addAction() {
    var collection = document.querySelector('.actions')
    var action = document.querySelector('.action-template').cloneNode(true)
    var actionSelect = action.querySelector('.action-select')

    action.classList.remove('action-template', 'hidden')
    action.classList.add('action')
    actionSelect.disabled = false

    this.showTemplateSelect(action)

    collection.appendChild(action)
  }

  addDependency() {
    var collection = document.querySelector('.dependencies')
    var dependency = document.querySelector('.dependency-template').cloneNode(true)
    var inputs = dependency.querySelector('.form-group').children

    dependency.classList.remove('dependency-template', 'hidden')
    dependency.classList.add('dependency')
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = false
    }

    collection.appendChild(dependency)
  }

  removeItem(e) {
    e.target.closest('.condition').remove()
  }

  removeAction(e) {
    e.target.closest('.action').remove()
  }

  removeDependency(e) {
    e.target.closest('.dependency').remove()
  }

  showActionInputs(e) {
    this.showIncidentInputs(e)
    this.showEmailInputs(e)
  }

  showIncidentInputs(e) {
    var target = e.target
    var selectedIndex = target.selectedIndex

    this.showTemplateSelect(target, selectedIndex)
    this.showConsolidateToggle(target, selectedIndex)
    this.uncheckConsolidateCheckbox(e)
    this.toggleConsolidateInputs(e)
  }

  showTemplateSelect(target, selectedIndex) {
    var row = target.closest('.action')
    var incidentTemplate = row.querySelector('.incident-template')

    incidentTemplate.classList.remove('hidden')
    incidentTemplate.disabled = false
  }

  showConsolidateToggle(target, selectedIndex) {
    var row = target.closest('.action')
    var consolidateToggle = row.querySelector('.consolidate-results')

    if (selectedIndex == 1 || selectedIndex == 2) {
      consolidateToggle.classList.remove('hidden')
    } else {
      consolidateToggle.classList.add('hidden')
    }
  }

  toggleConsolidateInputs(e) {
    var state = e.target.checked
    var row = e.target.closest('.action')
    var inputs = row.querySelectorAll('.consolidate-input')

    for (var i = 0; i < inputs.length; i++) {
      if (state) {
        inputs[i].classList.remove('hidden')
        inputs[i].disabled = false
      } else {
        inputs[i].classList.add('hidden')
        inputs[i].disabled = true
      }
    }
  }

  uncheckConsolidateCheckbox(e) {
    var row = e.target.closest('.action')
    var consolidateCheckbox = row.querySelector('input[name="consolidate_results"]')
    consolidateCheckbox.checked = false
  }

  showEmailInputs(e) {
    let target = e.target
    let row = target.closest('.action')
    let emailField = row.querySelector('.email-input')
    let selectedIndex = target.selectedIndex

    this.toggleEmailField(emailField, selectedIndex)
  }

  toggleEmailField(target, selectedIndex) {
    if (selectedIndex == 3) {
      target.disabled = false
      target.classList.remove('hidden')
    } else {
      target.disabled = true
      target.classList.add('hidden')
    }
  }
}
