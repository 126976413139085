import JobStatusV2Controller from '../shared/job_status_v2_controller'

export default class extends JobStatusV2Controller {

  disconnect() {
    this.clearInterval()
  }

  get url() {
    return this.form.action || this.form.dataset.action
  }

  get intervalLength() {
    return 5000
  }

  get incidentStatusUrl() {
    return this.form.dataset.incident_status;
  }

  get operation() {
    return this.form.dataset.operation
  }

  get parentContainer() {
    return this.operation == 'delete' ? 'tr' : this.form
  }

  get completionMessage() {
    return this.form.dataset.completion_message || 'Job Processing Complete!'
  }

  get replaceElement() {
    return this.form.dataset.replace_element
  }

  get messagesContainer() {
    return this.form.querySelector(this.form.dataset.message_container)
  }

  get loadingContainer() {
    return this.form.querySelector(this.form.dataset.loading_container)
  }

  async incidentStatus(url) {

    const headers = new Headers()
    const csrfHeaderValue = document.querySelector('meta[name="csrf-token"]').content

    headers.append('X-CSRF-Token', csrfHeaderValue)
    headers.append('Content-Type', 'application/json')

    const options = {
      headers,
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'same-origin',
    }
    try {
      const response = await fetch(url, options)
      return response.json();
    } catch (e) {
      return e;
    }
  }

  get toRemove() {
    return this.operation == 'delete'
  }

  onSuccess(body) {
    const status = body.status
    switch (status) {
      case 'queued': {
        this.message('In queue...')
        this.loading(true)
        break
      }
      case 'working': {
        this.message('In progress...')
        this.loading(true)
        break
      }
      case 'failed': {
        this.onError()
        break
      }
      case 'completed': {
        this.clearInterval()
        this.message(this.completionMessage)
        this.loading(false)
        this.postCompleteProcess()
        break
      }
    }
    this.status = status
  }

  postCompleteProcess() {
    if(this.toRemove)
      this.remove(1000)
    else
      this.replace(1000)
  }

  remove(time) {
    $(this.form).closest(this.parentContainer).fadeOut(time, function() { this.remove() });
  }

  replacingText(message, time) {
    $(this.form).closest(this.parentContainer).fadeOut(time,
      function(element, message) {
        $(element).replaceWith("<div>" + message + "</div>")
      }.bind(null, this.form, message));
  }

  replace(time) {
    if(this.operation == 'remediation') {
      $(this.replaceElement).replaceWith(this.warningMsg);
      this.replacingText(this.completionMessage, 1000);
    } else {
      this.incidentStatus(this.incidentStatusUrl).then((obj) => {
	let elem = this.successMsg;
	let message = this.completionMessage;
        if(obj.state != "resolved") {
	  elem = this.warningMsg;
	  message = "Job completed but incident was not resolved";
	}
        $(this.replaceElement).replaceWith($(elem));
        this.replacingText(message, 1000);
      });
    }
  }

  get warningMsg() {
    return "<span class='text-warning' title='Remediation'><i class='fa fa-exclamation-circle'></i></span>";
  }

  get successMsg() {
    return "<span class='text-success' title='Resolved'><i class='fa fa-check-circle'></i></span>";
  }
}
