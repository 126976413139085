import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'field',
    'authenticateButton',
    'appId',
    'outputDisplay'
  ]

  connect() {
    if (this.hasFieldTarget && this.hasAuthenticateButtonTarget) {
      this.fieldTargets.forEach((input) => {
        input.addEventListener('keyup', this.toggleAuthenticateButton.bind(this))
      })

      this.toggleAuthenticateButton()
    }

    this.checkForToastMessages()
  }

  getAlert(message, error) {
    const alert = `<div class="alert alert-warning --aligned-center">
      <div class="alert-content">
        <div class="alert-icon">
          <i class="fa-regular fa-triangle-exclamation"></i>
        </div>
        <div class="alert-body">
          <div class="alert-subtitle size-14 --inline">
            ${message}
          </div>
        </div>
      </div>
      <div aria-label="Close" class="button close" data-dismiss="alert">
        <div aria-hidden="true" class="span">×
      </div>
    </div>
    </div>`

    return alert;
  }

  checkForToastMessages() {
    let hasError = document.getElementsByClassName('toast toast-error').length >= 1
    let url = new URL(window.location.href)
    let appId = url.hash.replace("#", "")

    if (hasError && this.hasAppIdTarget && this.appIdTarget.innerText == appId) {
      Array.from(this.fieldTargets).forEach(input => {
        input.parentElement.classList.add('has-error')
      })
      const customAlert = this.getAlert("Error 401: credential authentication failed");
      this.outputDisplayTarget.innerHTML = customAlert
    }
  }

  toggleAuthenticateButton() {
    var inputsFilled = Array.from(this.fieldTargets).every(input => input.value)

    if (inputsFilled) {
      this.authenticateButtonTarget.disabled = false
    } else {
      this.authenticateButtonTarget.disabled = true
    }
  }
}
