import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.check_job_re_pull_setup_data();
    this.check_job_pull_board_statuses();
    this.check_job_setup_psa_config();
  }

  check_job_re_pull_setup_data() {
    let job_id = this.params().get("job_re_pull_setup_data");
    let target = $(`.${this.credentialType()}.jobRePullSetupData`).get(0);
    this.generic_check(job_id, target, "Re-Pull Setup Data");
  }

  check_job_pull_board_statuses() {
    let job_id = this.params().get("job_pull_board_statuses");
    let target = $(`.${this.credentialType()}.jobPullBoardStatuses`).get(0);
    this.generic_check(job_id, target, "Pull Board Statuses");
  }

  check_job_setup_psa_config() {
    let job_id = this.params().get("job_setup_psa_config");
    let target = $(`.${this.credentialType()}.jobSetupPsaConfig`).get(0);
    this.generic_check(job_id, target, "Setup PSA Config");
  }

  generic_check(job_id, target, message) {
    if(this.validJobAndTarget(job_id, target)) {
      // check status of job; update label
      this.check_status(job_id, target, message);
    }
  }

  check_status(job_id, destination_element, message) {
    const accountId = this.scope.element.dataset.accountid;
    const refreshInterval = setInterval(call_job_status, 5000, job_id, destination_element, message, accountId, this.credentialType());
    function call_job_status(job_id, destination_element, message, accountId, credential_type) {
      $.ajax({
        type: "get",
        url: `/accounts/${accountId}/credentials/job_status`,
        data: { job_id: job_id, credential_type: credential_type, message: message },
        success: (data) => {
          const status = (data.status ? data.status : 'Error');
          const alertClass = (status == 'Error' ? 'alert-warning' : 'alert-info')

          if(!["queued", "working", "failed"].includes(data.status)) {
            clearInterval(refreshInterval);
            setTimeout((d_element) => d_element.innerText = '', 5000, destination_element);
          }

          if(data.message) {
            renderOutput(`${message} (${status}): ${data.message}`, alertClass);
          }
        },
        error: () => {
          renderOutput(`${message} (Error): failed.`, "alert-warning");
        }
      });

      function renderOutput(str, alertClass) {
        destination_element.innerText = '';

        const div = document.createElement("div");
        div.classList.add("alert", "alert-dismissible", alertClass);
        div.innerText = str;
        destination_element.append(div);
      }
    }
  }

  validJobAndTarget(job_id, target) {
    return ![job_id, target].includes(null);
  }

  credentialType() {
    return this.params().get("credential_type");
  }

  params() {
    return new URL(location.href).searchParams;
  }
}
