import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  call(ev) {
    let url = ev.target.attributes["data-url"].value;
    this.formTarget.action = url;
  }
}
