import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['device']

  update(data) {
    const setRowValue = function(type, id, value) {
      if(type == 'device')
        $("#" + type + " tr[data-device-status-id='" + id + "'] td.comments").get(0).innerHTML = value;
      else
        $("#" + type + " .row_" + id + " td.comments").get(0).innerHTML = value;
    }

    const element = this.context.scope.element;
    const type = element.dataset.type;
    $.ajax({
      type: "POST",
      url: '/devices/' + this.context.scope.element.dataset.value + '/update_comments',
      data: { comments: this.deviceTarget.value, type: type },
      success: (data) => {
        const comments = data.details["comments"]
        this.deviceTarget.innerHTML = comments;
        setRowValue(type, data.id, comments);
        $("#" + element.id + " .close").click();
      }
    });
    data.preventDefault();
  }

  updateComment(e) {
    const setRowValue = function(id, value) {
      $('#' + id).find('.comments').get(0).innerHTML = value
    }

    const element = this.context.scope.element
    const type = element.dataset.type

    $.ajax({
      type: 'POST',
      url: '/devices/' + element.dataset.value + '/update_comments',
      data: { comments: this.deviceTarget.value, type: type },
      success: (data) => {
        const comments = data.details["comments"]
        this.deviceTarget.innerHTML = comments
        setRowValue(data.id, comments)
        $(element).find('.close').click()
      }
    })
    e.preventDefault()
  }
}
