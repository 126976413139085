/* globals rocketcyber,jQuery */
const shouldRunDashboardPage = () => window.location.pathname == '/'

window.rocketcyber.deviceHeartbeatService.functions.onLoadDashboardFirewallAnalyzeConfig = (function () {
  const deviceConnectivity = rocketcyber.enumerators.deviceConnectivity
  const populateOptionsConnectivity = (data, deviceIdToLocationMap) => {
    if (!Array.isArray(data)) {
      console.log(`[populateOptionsConnectivity] Expected parameter 'data' to be an array, instead received ${JSON.stringify(data)}`)
      return
    }
    data.forEach(device => {
      const location = deviceIdToLocationMap[device.deviceId]
      let text
      if (device.isolated) {
        text = '(isolated)'
      } else {
        switch (device.connectivity) {
          case deviceConnectivity.ONLINE:
            text = '(online)'
            break
          default:
            text = '(offline)'
        }
      }
      const target = jQuery(`#syslog .form-group:eq(0) option:eq(${location})`)
      target.text(`${target.text()} ${text}`)
    })
  }
  const configDeviceIds = async () => {
    const url = `${rocketcyber.deviceHeartbeatService.getUrl(document.location.hostname)}/api/devices/search/deviceId`
    let deviceIds = []
    let deviceIdToLocationMap = {}
    jQuery('#syslog .form-group:eq(0) option').each(function (index, item) {
      if (index === 0) return
      const deviceId = jQuery(item).attr('data-device-id')
      deviceIds.push(deviceId)
      deviceIdToLocationMap[deviceId] = index
    })
    const body = {
      'deviceIds': deviceIds
    }
    const data = await getData(url, body)
    if (data.statusCode) {
      console.log(`[configDeviceIds] Error Encountered: ${JSON.stringify(data)}`)
      return
    }
    rocketcyber.dashboardFirewallAnalyzeConfigData = data
    rocketcyber.dashboardFirewallAnalyzeConfigMap = deviceIdToLocationMap
    populateOptionsConnectivity(rocketcyber.dashboardFirewallAnalyzeConfigData, rocketcyber.dashboardFirewallAnalyzeConfigMap)
  }
  const getData = async (url, body) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${rocketcyber.deviceHeartbeatService.getToken(document.location.hostname)}`)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
    const res = await fetch(url, requestOptions)
    return res.json()
  }
  return configDeviceIds
})()
window.rocketcyber.deviceHeartbeatService.functions.onLoadDashboardPage = (function () {
  const deviceConnectivity = rocketcyber.enumerators.deviceConnectivity
  const populateDashboard = (dataMap) => {
    if (!Array.isArray(dataMap)) {
      console.log(`[populateDashboard] Expected parameter 'dataMap' to be an array, instead received ${JSON.stringify(dataMap)}`)
      return
    }
    const details = dataMap.reduce((acc, device)=> {
      switch (device.connectivity) {
        case deviceConnectivity.OFFLINE:
          acc[0] += 1
          break
        case deviceConnectivity.ONLINE:
          acc[1] += 1
          break
        case deviceConnectivity.ISOLATED:
          acc[2] += 1
          break
      }
      return acc
    }, [0, 0, 0])
    const [
      offline,
      online,
      isolated,
    ] = details
    jQuery('h4#headingDevicesOnline').text(online)
    jQuery('h4#headingDevicesOffline').text(offline)
    jQuery('h4#headingDevicesIsolated').text(isolated)
  }

  const deviceDetails = async () => {
    const ids = jQuery('#client_id').text()
    let arr = ids.split(',')
    const actualIds = arr.filter(item => item !== '')

    const url = `${rocketcyber.deviceHeartbeatService.getUrl(document.location.hostname)}/api/devices/search/accountId`
    const body = {
      accountIds: actualIds,
      pageSize: rocketcyber.constants.NUMBER_MORE_THEN_DEVICES
    }
    let dataMap = await getData(url, body)
    if (dataMap.statusCode) {
      if (dataMap.statusCode === 400 && dataMap.name === 'No Devices Found') {
        dataMap = []
      }
      else {
        console.error(`[deviceDetails] Error Encountered: ${JSON.stringify(dataMap)}`)
        return
      }
    }
    rocketcyber.dataMap = dataMap
    rocketcyber.intervalId = setInterval(() => {
      populateDashboard(rocketcyber.dataMap || [])
    }, 1000)
  }

  const getData = async (url, body) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${rocketcyber.deviceHeartbeatService.getToken(document.location.hostname)}`)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
    const res = await fetch(url, requestOptions)
    return res.json()
  }
  return deviceDetails
})()

document.addEventListener('turbolinks:load', function () {
  if (shouldRunDashboardPage()) {
    window.rocketcyber.deviceHeartbeatService.functions.onLoadDashboardPage()
    window.rocketcyber.deviceHeartbeatService.functions.onLoadDashboardFirewallAnalyzeConfig()
    window.rocketcyber.notifications.functions.onLoadDashboardPage()
  }
})
