import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'baseUrl',
    'companyId',
    'outputDisplay',
    'privateApiKey',
    'publicApiKey',
    'credentialId',
    'outputDisplay'
  ]

  call(event) {
    this.runTest(event);
    event.preventDefault();
  }

  runTest(event) {
    const accountId = event.target.dataset.account
    const search_params = this.getSearchParams()
    $.ajax({
      type: "post",
      url: `/accounts/${accountId}/credentials/test`,
      data: new URLSearchParams(search_params).toString(),
      success: (data) => { this.update(data); }
    })
  }

  update(data) {
    const customAlert = this.getAlert(this.getMessage(data), data.error);
    this.outputDisplayTarget.innerHTML = customAlert

    Array.from([
      this.companyIdTarget, this.publicApiKeyTarget, this.privateApiKeyTarget, this.baseUrlTarget
    ]).forEach(field => {
      data.error ? field.parentElement.classList.add('has-error') : field.parentElement.classList.remove('has-error')
    })


    setTimeout(function(elem) {
      elem.parentElement.removeChild(elem);
    }, 5000, customAlert);
  }

  getMessage(data) {
    let message = null;

    if(data.error) {
      message = data.message ? data.message : "credential authentication failed.";
      message = "Error " + data.code + ": " + message;
    } else {
      message = "Success " + data.code + ": valid credential detected.";
    }
    return message;
  }

  getAlert(message, error) {
    const alert = `<div class="alert ${error ? 'alert-warning' : 'alert-success' } --aligned-center">
      <div class="alert-content">
        <div class="alert-icon">
          <i class="fa-regular ${error ? 'fa-triangle-exclamation' : 'fa-circle-check'}"></i>
        </div>
        <div class="alert-body">
          <div class="alert-subtitle size-14 --inline">
            ${message}
          </div>
        </div>
      </div>
      <div aria-label="Close" class="button close" data-dismiss="alert">
        <div aria-hidden="true" class="span">×
      </div>
    </div>
    </div>`

    return alert;
  }

  getSearchParams() {
    return {
      app: "Connectwise",
      connectwise_company_id:          this.companyIdTarget.value,
      connectwise_psa_public_api_key:  this.publicApiKeyTarget.value,
      connectwise_psa_private_api_key: this.privateApiKeyTarget.value,
      base_url:                        this.baseUrlTarget.value,
      credential_id:                   this.credentialIdTarget.value
    }
  }
}
