import { difference } from 'lodash';
import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = []

  connect() {
    this.customHtmlValidation();

    if (document.querySelector('.input-daterange')) {
      $('#start_date').datepicker().on('changeDate', this.validateStartDate.bind(this))
      $('#end_date').datepicker().on('changeDate', this.validateEndDate.bind(this))
    }
  }

  validateDateRange() {
    var startDate = $('#start_date').datepicker('getDate')
    var endDate = $('#end_date').datepicker('getDate')
    var maxRange = $('#max_days').val()

    var startDateField = document.getElementById('start_date')
    var endDateField = document.getElementById('end_date')

    var timeDifference = endDate.getTime() - startDate.getTime(); 
      
    // To calculate the no. of days between two dates 
    var days = timeDifference / (1000 * 3600 * 24); 

    if (days > Number(maxRange)) {
      startDateField.setCustomValidity(`Max Range is ${maxRange} days`)
      endDateField.classList.add('mt-5')
      document.getElementById('inputRangeArrow').classList.add('mt-15')
    } else {
      this.removeErrorMarkup(startDateField)
      endDateField.classList.remove('mt-5')
      document.getElementById('inputRangeArrow').classList.remove('mt-15')
      startDateField.setCustomValidity("");
    }

    startDateField.reportValidity();
  }

  validateStartDate() {
    var startDate = $('#start_date').datepicker('getDate')
    var startDateField = document.getElementById('start_date')
    var endDateField = document.getElementById('end_date')
    if (!!$('#end_date').val()) {
      if (startDate > $('#end_date').datepicker('getDate')) {
        startDateField.setCustomValidity("Start date must be before the end date");
        endDateField.classList.add('mt-5')
        document.getElementById('inputRangeArrow').classList.add('mt-15')
      } else {
        this.removeErrorMarkup(startDateField)
        this.removeErrorMarkup(endDateField)
        startDateField.setCustomValidity("");
        endDateField.setCustomValidity("");
        endDateField.classList.remove('mt-5')
        startDateField.classList.remove('mt-5')
        document.getElementById('inputRangeArrow').classList.remove('mt-15')

        this.validateDateRange();
      }

      endDateField.reportValidity();
      startDateField.reportValidity();
    }
  }

  validateEndDate() {
    var startDate = $('#start_date').datepicker('getDate')
    var startDateField = document.getElementById('start_date')
    var endDateField = document.getElementById('end_date')
    var endDate = $('#end_date').datepicker('getDate')
    if (!!$('#start_date').val()) {
      if (endDate < startDate) {
        endDateField.setCustomValidity("End date must be after the start date");
        document.getElementById('inputRangeArrow').classList.add('mt-15')
        startDateField.classList.add('mt-5')
      } else {
        this.removeErrorMarkup(startDateField)
        this.removeErrorMarkup(endDateField)
        startDateField.setCustomValidity("");
        endDateField.setCustomValidity("");
        document.getElementById('inputRangeArrow').classList.remove('mt-15')
        endDateField.classList.remove('mt-5')
        startDateField.classList.remove('mt-5')

        this.validateDateRange();
      }

      startDateField.reportValidity();
      endDateField.reportValidity();
    }
  }

  updateValidFields(event) {
    var field = event.target

    if (field.value.length > 50) {
      field.setCustomValidity("cannot be more than 50 characters");
    } else {
      field.setCustomValidity('')
    }

    if (field.checkValidity()) {
      this.removeErrorMarkup(field)
    }
  }

  removeErrorMarkup(field) {
    let parentElement = field.parentElement;

    parentElement.querySelector('.help-block')?.classList?.add('d-none')
    parentElement.classList.remove('has-error')
    field.classList.remove('error')
  }

  customHtmlValidation() {
    Array.from(document.querySelectorAll(`input[required]`)).forEach(field => {
      field.addEventListener('keydown', function(event) {
        event.target.value = event.target.value.trimStart();
      })
      field.addEventListener('keyup', this.updateValidFields.bind(this))

      field.addEventListener('invalid', function(event) {
        if (['text', 'email'].includes(field.type)) {
          event.preventDefault(); // Prevent HTML5 default tooltip to show
          let parentElement = field.parentElement;
  
          parentElement.classList.add('has-error')
          field.classList.add('error')
  
          var errorSpan = parentElement.querySelector('.help-block')
          var errorMsg = "Please enter a value"
  
          if (!field.validity.valueMissing) {
            if (field.validity.tooShort) {
              errorMsg = `Must contain more than ${field.minLength} characters`
            } else if (field.validity.tooLong) {
              errorMsg = `Must contain not more than ${field.maxLength} characters`
            } else {
              errorMsg = field.validationMessage;
            }
          }
          var addWrapFields = ['name', 'recipient_email']
          var wrapClass = addWrapFields.includes(field.name) ? 'no-wrap' : 'wrap';

          if (!!errorSpan) {
            errorSpan.classList.remove('d-none')
            errorSpan.innerHTML = errorMsg;
          } else {
            parentElement.append(
              Object.assign(
                document.createElement('span'),
                { innerHTML: errorMsg, classList: `help-block error ml-10 ${wrapClass}` }
              )
            )
          }
        }
      })
    })
  }

  validateForm(event) {
    if (document.getElementById('children').checked) {
      var childrenCheckboxes = Array.from($('#childrenAccountsSelection input[type="checkbox"]'))
      var hasCheckedChildren = childrenCheckboxes.some(checkbox => checkbox.checked)
      var childrenSelectionError = document.getElementById("childrenSelectionError")

      if (hasCheckedChildren) {
        childrenSelectionError.classList.add('d-none')
      } else {
        event.preventDefault();
        childrenSelectionError.classList.remove('d-none')
      }
    }
  }

  toggleReportingKind(event) {
    var radio = event.target
    var onDemandContainer = document.getElementById('onDemandFields')
    var scheduledContainer = document.getElementById('scheduledFields')

    if (radio.value == 'scheduled') {
      scheduledContainer.classList.remove('d-none')
      onDemandContainer.classList.add('d-none')

      Array.from($('#scheduledFields select')).forEach(select => { select.disabled = false })
      Array.from($('#scheduledFields input')).forEach(input => { input.disabled = false })
      Array.from($('#onDemandFields input')).forEach(input => { input.disabled = true })
    } else {
      scheduledContainer.classList.add('d-none')
      onDemandContainer.classList.remove('d-none')

      Array.from($('#scheduledFields select')).forEach(select => { select.disabled = true })
      Array.from($('#scheduledFields input')).forEach(input => { input.disabled = true })
      Array.from($('#onDemandFields input')).forEach(input => { input.disabled = false })
    }
  }

  changeDeliveryDayOptions(event) {
    let selectedOption = event.target.selectedOptions[0].innerHTML.toLowerCase()
    let deliveryDayTypeField = document.getElementById('delivery_day_type_id')
    Array.from(document.querySelectorAll('select[name="delivery_day"]')).forEach(deliverySelect => {
      let parentDiv = deliverySelect.parentElement.parentElement
      if (parentDiv.id == selectedOption) {
        parentDiv.classList.remove('d-none')
        deliverySelect.disabled = false;
        deliveryDayTypeField.value = deliverySelect.dataset.deliveryDayTypeId
      } else {
        parentDiv.classList.add('d-none')
        deliverySelect.disabled = true
      }
    })
  }

  changeRecipientEmailSelection(event) {
    var checkbox = event.target
    var childrenAccountSelectionContainer = document.getElementById('childrenAccountsSelection')
    var childrenAccountCheckboxes = Array.from(document.querySelectorAll('input[name="children_accounts[]"]'))
    var providerEmailRecipientField = document.getElementById('email_recipient')

    if (checkbox.checked) {
      providerEmailRecipientField.disabled = true;
      providerEmailRecipientField.value = ''
      this.removeErrorMarkup(providerEmailRecipientField)

      childrenAccountSelectionContainer.classList.remove('d-none')
      childrenAccountCheckboxes.forEach(childrenAccountCheckbox => {
        childrenAccountCheckbox.disabled = false;
        childrenAccountCheckbox.parentElement.parentElement.classList.remove('disabled')
      })
    } else {
      document.getElementById("childrenSelectionError").classList.add('d-none')

      providerEmailRecipientField.disabled = false;

      childrenAccountSelectionContainer.classList.add('d-none')
      childrenAccountCheckboxes.forEach(childrenAccountCheckbox => {
        childrenAccountCheckbox.disabled = true;
      })
    }
  }

  toggleChildrenAccountEmailField(event) {
    var checkbox = event.target
    var emailField = document.getElementById(`children_accounts_email_${checkbox.value}`)
    if (checkbox.checked) {
      emailField.parentElement.classList.remove('d-none')
      emailField.classList.remove('d-none')
      emailField.disabled = false
    } else {
      emailField.parentElement.classList.add('d-none')
      emailField.classList.add('d-none')
      emailField.value = '';
      this.removeErrorMarkup(emailField)
      emailField.disabled = true;
    }
  }
}
